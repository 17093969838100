import { replaceOldest } from '../../helpers';
import { CodeActions, CodeReducerAction, CodeReducerResponse } from '../../typings';

const initialState: CodeReducerResponse = {
  loading: false,
  loaded: false,
  error: null,
  code: null,
  latest: []
};

export default function code(
  state: CodeReducerResponse = initialState,
  action: CodeReducerAction
): CodeReducerResponse {
  switch (action.type) {
    case CodeActions.REQUEST_FAILURE:
    case CodeActions.RETURN_FAILURE:
    case CodeActions.LATEST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error || new Error('Unknwon Error')
      };

    case CodeActions.REQUEST_REQUEST:
    case CodeActions.RETURN_REQUEST:
    case CodeActions.LATEST_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case CodeActions.CLEAR_CODE_REQUEST:
      return {
        ...state,
        code: null
      };

    case CodeActions.REQUEST_SUCCESS:
      if (Array.isArray(action.payload) || !action.payload?.code) return state;

      return {
        ...state,
        loading: false,
        loaded: true,
        code: action.payload
      };

    case CodeActions.LATEST_SUCCESS:
      if (!Array.isArray(action.payload)) return state;

      return {
        ...state,
        loading: false,
        loaded: true,
        latest: action.payload
      };

    case CodeActions.MSG_NEW_LATEST:
      if (!Array.isArray(action.payload)) return state;

      return {
        ...state,
        loading: false,
        loaded: true,
        latest: replaceOldest(state.latest as any, action.payload as any)
      };

    default:
      return state;
  }
}
