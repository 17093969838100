import { APIResponse } from '../../typings';

import { apiGateway } from '../../config';
import { http } from '../../helpers';

export const statsService = {
  codesAvailable,
  codesRequestToday,
  codesRequestTotal,
  codesRequestByDay,
  codesDonateToday,
  codesDonateTotal,
  codesDonateByDay
};

function codesAvailable(): Promise<APIResponse<number>> {
  return http.get(`${apiGateway}/stats/codes/available`).then(handleResponse);
}

function codesRequestToday(): Promise<APIResponse<number>> {
  return http.get(`${apiGateway}/stats/codes/request/today`).then(handleResponse);
}

function codesRequestTotal(): Promise<APIResponse<number>> {
  return http.get(`${apiGateway}/stats/codes/request/total`).then(handleResponse);
}

function codesRequestByDay(): Promise<APIResponse<{ [key: string]: number }>> {
  return http.get(`${apiGateway}/stats/codes/request/byDay`).then(handleResponse);
}

function codesDonateToday(): Promise<APIResponse<number>> {
  return http.get(`${apiGateway}/stats/codes/donate/today`).then(handleResponse);
}

function codesDonateTotal(): Promise<APIResponse<number>> {
  return http.get(`${apiGateway}/stats/codes/donate/total`).then(handleResponse);
}

function codesDonateByDay(): Promise<APIResponse<{ [key: string]: number }>> {
  return http.get(`${apiGateway}/stats/codes/donate/byDay`).then(handleResponse);
}

async function handleResponse(response) {
  if (response.status !== 200) {
    const body = await response.json();
    return new Error(body.message);
  }

  return response.json();
}
