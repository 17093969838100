import { APIResponse, StatsActions, StatsNumberDispatch, StatsDateRangeDispatch } from '../../typings';

import { Dispatch, Action } from 'redux';
import { statsService } from '../services/stats.services';

export const statsActions = {
  codesAvailable,
  codesRequestTotal,
  codesRequestToday,
  codesRequestByDay,
  codesDonateTotal,
  codesDonateToday,
  codesDonateByDay
};

function codesAvailable() {
  return _getStats<StatsNumberDispatch, number>('codesAvailable', 'CODES_AVAILABLE');
}

function codesRequestTotal() {
  return _getStats<StatsNumberDispatch, number>('codesRequestTotal', 'CODES_REQUEST_TOTAL');
}

function codesRequestToday() {
  return _getStats<StatsNumberDispatch, number>('codesRequestToday', 'CODES_REQUEST_TODAY');
}

function codesRequestByDay() {
  return _getStats<StatsDateRangeDispatch, { [key: string]: number }>('codesRequestByDay', 'CODES_REQUEST_BY_DAY');
}

function codesDonateTotal() {
  return _getStats<StatsNumberDispatch, number>('codesDonateTotal', 'CODES_DONATE_TOTAL');
}

function codesDonateToday() {
  return _getStats<StatsNumberDispatch, number>('codesDonateToday', 'CODES_DONATE_TODAY');
}

function codesDonateByDay() {
  return _getStats<StatsDateRangeDispatch, { [key: string]: number }>('codesDonateByDay', 'CODES_DONATE_BY_DAY');
}

function _getStats<D extends Action<any>, R = {}>(method: string, type: string): (dispatch: Dispatch<D>) => void {
  return (dispatch) => {
    dispatch(request());

    statsService[method]().then(
      (res: APIResponse<R>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): any {
    return {
      type: StatsActions[`${type}_REQUEST`]
    };
  }

  function success({ data }: APIResponse<R>): any {
    return {
      type: StatsActions[`${type}_SUCCESS`],
      payload: data
    };
  }

  function failure(error: Error | string | null): any {
    return { type: StatsActions[`${type}_FAILURE`], error: (error as any)?.message || error };
  }
}
