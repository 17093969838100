import { useState, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';
import { submitActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { rootState } from '../../../store/reducers';
import { SubmissionObject } from '../../../typings';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { formatCode } from '../../../helpers';
import { maxCodeSubmission } from '../../../config';

interface OwnProps {}

interface StateProps {
  loading: boolean;
  loaded: boolean;
  codes: SubmissionObject;
  error: Error | null;
}

interface DispatchActions {
  submitCodes: (codes: string, token: string) => void;
}

type InputTextProps = DispatchActions & StateProps & OwnProps;

function InputText({ submitCodes }: InputTextProps): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const contentRef = useRef<HTMLButtonElement>(null);

  const [codes, setCodes] = useState<string>('');
  const [lineCount, setLineCount] = useState<number>(0);
  const [codesFormatted, setCodesFormatted] = useState<string>('');
  const [buttonMessage, setButtonMessage] = useState<string>(`Submit (${lineCount} of ${maxCodeSubmission})`);

  const onChange = (e: ContentEditableEvent) => {
    const target = e.currentTarget as HTMLDivElement;
    const rawInput = (target?.innerText || '').replace(/[^A-Z0-9-\n\r]+/gim, '');
    const placeholderEl = '<span class="text-placeholder"></span>';

    const result = rawInput.split(/[\r|\n|\r\n]{1,}/).reduce((res: string[], line: string, idx: number) => {
      if (line === '' && res?.[idx - 1] === placeholderEl) return res;
      else if (idx + 1 >= maxCodeSubmission) return res;
      else if (line === '') {
        res.push(placeholderEl);
        return res;
      }

      if (line.replace(/-/g, '').length === 13) line = formatCode(line.replace(/-/g, ''));

      res.push(`<span class="text-${line.length === 16 ? 'success' : 'danger'}">${line.toUpperCase()}</span>`);
      return res;
    }, []);

    setCodes(rawInput.trim());
    setLineCount(result.length);
    setCodesFormatted(result.join('<br />'));
    setButtonMessage(`Submit (${lineCount} of ${maxCodeSubmission})`);
  };

  const submit = async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha('CodeSubmit');

    submitCodes(codes, token);

    setCodes('');
    setLineCount(0);
    setCodesFormatted('');
    setButtonMessage('Submitted');
  };

  return (
    <Card.Body className="bulk-code-container">
      <ContentEditable
        innerRef={contentRef}
        html={codesFormatted}
        disabled={false}
        onChange={onChange}
        className="bulk-code-editor"
        placeholder="ENTER CODES HERE, ONE PER LINE..."
      />
      <Button onClick={submit} className="action-btn">
        {buttonMessage}
      </Button>
    </Card.Body>
  );
}

function mapState({ submit }: rootState): StateProps {
  return {
    loading: submit.loading,
    loaded: submit.loaded,
    codes: submit.codes,
    error: submit.error || null
  };
}

const actionCreators: DispatchActions = {
  submitCodes: submitActions.submitCodeBulk
};

const connectedInputText = connect(mapState, actionCreators)(InputText);
export { connectedInputText as InputText };
