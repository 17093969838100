import { APIResponse, TopActions, TopData, TopDonorTotalDispatch } from '../../typings';

import { Dispatch } from 'redux';
import { topService } from '../services/top.services';

export const topActions = {
  donorsTotal
};

function donorsTotal(): (dispatch: Dispatch<TopDonorTotalDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    topService.donorsTotal().then(
      (res: APIResponse<TopData[]>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): TopDonorTotalDispatch {
    return {
      type: TopActions.DONOR_TOTAL_REQUEST
    };
  }

  function success({ data }: APIResponse<TopData[]>): TopDonorTotalDispatch {
    return {
      type: TopActions.DONOR_TOTAL_SUCCESS,
      payload: data
    };
  }

  function failure(error: Error | string | null): TopDonorTotalDispatch {
    return { type: TopActions.DONOR_TOTAL_FAILURE, error: (error as any)?.message || error };
  }
}
