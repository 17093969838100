import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useContext } from 'react';
import { CookieContext } from './CookieContext';

interface RecaptchaProviderProps {
  children?: React.ReactNode;
}

export function RecaptchaProvider({ children }: RecaptchaProviderProps): JSX.Element {
  const { consent } = useContext(CookieContext);

  if (!consent) return <>{children}</>;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY || ''}>{children}</GoogleReCaptchaProvider>
  );
}
