import { Modal } from 'react-bootstrap';
import { AccountData, CodeObject } from '../../../typings';
import { expansionImage, formatCode } from '../../../helpers';
import { CodeType, ExpansionObject } from '../../../typings/code.typings';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CodeSocialShare } from './CodeSocialShare';
import { UserProfile, CodeCard } from '../../../components';

interface CodeResponseProps {
  code: CodeObject | null;
  onClose: () => void;
}

export function CodeResponse({ code, onClose }: CodeResponseProps): JSX.Element {
  const formattedCode = formatCode(code?.code);
  const [copied, setCopied] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);

  if (!code) return <></>;

  const expansion = code.expansion as unknown as ExpansionObject;
  const donor = code.donor as unknown as AccountData;

  const closeCode = () => {
    setShowModal(false);
    onClose();
  };

  return (
    <Modal
      show={showModal}
      centered={true}
      size="lg"
      backdrop={true}
      dialogClassName="code-modal"
      backdropClassName="code-modal-backdrop"
      animation={true}
      onEscapeKeyDown={closeCode}
      onHide={closeCode}
    >
      <CodeCard
        id={expansion?.id}
        coupon={code?.coupon || `${expansion.series} - ${expansion.name}`}
        type={code.type}
        code={formattedCode}
        size="lg"
      >
        <CopyToClipboard className="code-card-copy" text={formattedCode} onCopy={() => setCopied(true)}>
          <FontAwesomeIcon icon={['fas', copied ? 'check' : 'clipboard']} />
        </CopyToClipboard>
        <div className="code-card-footer">
          <CodeSocialShare />
          <UserProfile user={donor} />
        </div>
      </CodeCard>

      <div
        className={`code-image code-image-${CodeType[code?.type || 99].toLowerCase()}`}
        style={{
          backgroundImage: `url('${expansionImage(
            code.type,
            expansion,
            expansion?.seed
          )}'), url('/assets/missing.${CodeType[code?.type || 99].toLowerCase()}.png')`
        }}
      >
        <div className="code-image-coupon">{code.coupon}</div>
      </div>
    </Modal>
  );
}
