import moment from 'moment';

export function dateRange(range: [number, string], until: Date = new Date()): string[] {
  const from = moment().subtract(...(range as any));
  const to = moment(until);
  const arr: string[] = [];

  for (let f = from; f.diff(to, 'days') <= 0; f.add(1, 'days')) {
    arr.push(f.format('YYYY-MM-DD'));
  }

  return arr;
}
