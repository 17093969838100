import {
  NotificationActions,
  NotificationHideDispatch,
  NotificationObject,
  NotificationShowDispatch
} from '../../typings';

import { Dispatch } from 'redux';

export const notificationActions = {
  showNotification,
  hideNotification
};

function showNotification(notification: NotificationObject): (dispatch: Dispatch<NotificationShowDispatch>) => void {
  return (dispatch) => {
    dispatch(request(notification));

    function request(notification: NotificationObject): NotificationShowDispatch {
      if (notification.duration) {
        setTimeout(() => hideNotification(notification)(dispatch), notification.duration);
      }

      return {
        type: NotificationActions.SHOW_REQUEST,
        payload: notification
      };
    }
  };
}

function hideNotification(notification: NotificationObject): (dispatch: Dispatch<NotificationHideDispatch>) => void {
  return (dispatch) => {
    dispatch(request(notification));

    function request(notification: NotificationObject): NotificationHideDispatch {
      return {
        type: NotificationActions.HIDE_REQUEST,
        payload: notification
      };
    }
  };
}
