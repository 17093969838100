import { NotificationObject } from '../typings';
import { connect } from 'react-redux';
import { rootState } from '../store/reducers';
import { notificationActions } from '../store/actions';
import { ToastContainer, Toast } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ReactNode, useCallback } from 'react';

interface OwnProps {}

interface StateProps {
  notifications: NotificationObject[];
}

interface DispatchActions {
  hideNotification: (notification: NotificationObject) => void;
}

type AvailableProps = DispatchActions & StateProps & OwnProps;

function Notifications({ notifications, hideNotification }: AvailableProps): JSX.Element {
  const linkWrapper = useCallback((notification: NotificationObject, children: ReactNode) => {
    if (!notification.link) return children;
    return <Link to={notification.link}>{children}</Link>;
  }, []);

  return (
    <ToastContainer className="notification-container">
      {notifications.map((notif, idx) =>
        linkWrapper(
          notif,
          <Toast
            key={`notification-${idx}`}
            bg={notif.variant || 'light'}
            className="notification"
            onClose={() => hideNotification(notif)}
          >
            <Toast.Header>
              <strong className="me-auto">{notif.title}</strong>
              {notif.date ? <small className="text-muted">{moment(notif.date).fromNow()}</small> : null}
            </Toast.Header>
            {notif.description ? <Toast.Body>{notif.description}</Toast.Body> : null}
          </Toast>
        )
      )}
    </ToastContainer>
  );
}

function mapState({ notifications }: rootState): StateProps {
  return {
    notifications: notifications?.items || []
  };
}

const actionCreators: DispatchActions = {
  hideNotification: notificationActions.hideNotification
};

const connectedNotifications = connect(mapState, actionCreators)(Notifications);
export { connectedNotifications as Notifications };
