import { Alert, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { StockLevelObject } from '../../typings';
import { StockList } from './components/StockList';
import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { stockActions } from '../../store/actions';
import { useEffect } from 'react';
import { changePageDetails } from '../../helpers';

interface OwnProps {}

interface StateProps {
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  available?: StockLevelObject[];
}

interface DispatchActions {
  getAvailable: () => void;
}

type AvailableProps = DispatchActions & StateProps & OwnProps;

function Available({ available, getAvailable }: AvailableProps): JSX.Element {
  useEffect(getAvailable, [getAvailable]);

  useEffect(() => {
    changePageDetails('Available', 'available');
  }, []);

  return (
    <div className="page-base">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="text-intro mb-5">Available</h1>
          </Col>
        </Row>
        {!available || available.length <= 0 ? (
          <Row>
            <Col md={12}>
              <Alert variant="info">
                No codes available, <Link to="/share">share some</Link>?
              </Alert>
            </Col>
          </Row>
        ) : (
          <StockList available={available} />
        )}
      </Container>
    </div>
  );
}

function mapState({ stock }: rootState): StateProps {
  return {
    loading: stock.loading,
    loaded: stock.loaded,
    available: stock.available,
    error: stock.error
  };
}

const actionCreators: DispatchActions = {
  getAvailable: stockActions.availableStock
};

const connectedAvailable = connect(mapState, actionCreators)(Available);
export { connectedAvailable as Available };
