import './store/messages';
import './assets/scss/App.scss';
import './icons';

import { AccountProvider, CookieContext, CookieProvider } from './components';
import { Provider } from 'react-redux';
import React, { useEffect, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './store';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecaptchaProvider } from './components/RecaptchaProvider';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function Bootstrap(): JSX.Element {
  const { consent, setConsent } = useContext(CookieContext);

  const handleAcceptCookie = useCallback(() => {
    if (consent !== true) setConsent(true);

    if (!process.env.REACT_APP_GA_TRACKING_ID) return;

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '', {
      testMode: process.env.NODE_ENV === 'development'
    });
  }, [consent, setConsent]);

  const handleDeclineCookie = useCallback(() => {
    if (consent !== false) setConsent(false);

    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
    Cookies.remove('AccessToken');
    Cookies.remove('connect.sid');
  }, [consent, setConsent]);

  useEffect(() => {
    if (consent) handleAcceptCookie();
  }, [consent, handleAcceptCookie]);

  return (
    <Provider store={store}>
      <RecaptchaProvider>
        <AccountProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AccountProvider>
      </RecaptchaProvider>
      <CookieConsent
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        setDeclineCookie={false}
        disableButtonStyles={true}
        overlay={true}
        overlayClasses={'CookieOverlay'}
        contentClasses={'content-wrapper'}
        buttonWrapperClasses={'btn-wrapper'}
        buttonClasses={'action-btn'}
      >
        This website relies on cookies to function, please consent before continuing.
      </CookieConsent>
    </Provider>
  );
}

root.render(
  <React.StrictMode>
    <CookieProvider>
      <Bootstrap />
    </CookieProvider>
  </React.StrictMode>
);
