import { TopData } from '../../../typings';
import { Card, Col } from 'react-bootstrap';
import { UserProfile } from '../../../components';
import { useCallback } from 'react';

export interface DonorRowProps {
  data: TopData;
  rank: number;
  size?: number;
}

export function DonorRow({ data, rank, size = 6 }: DonorRowProps): JSX.Element {
  const formatNumber = useCallback((value: number) => {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  }, []);

  return (
    <Col lg={size} className="top-donor-row">
      <Card>
        <Card.Body className="text-secondary">
          <div className="rank">#{rank}</div>
          <UserProfile user={data as any} size="lg" showAvatar={true} showUrl={true} />
          <ul className="stats-row">
            <li className="stats-row-total" style={{ backgroundImage: `url("/assets/icon.type.0.png")` }}>
              {formatNumber(data.stats.donated || 0)}
            </li>
          </ul>
        </Card.Body>
      </Card>
    </Col>
  );
}
