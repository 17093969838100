import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface FooterProps {
  isHome?: boolean;
}

export function Footer({ isHome }: FooterProps): JSX.Element {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={12}>
            <p>
              tcg.codes &copy; {new Date().getFullYear()}.
              <Link to="/terms" className="ms-2">
                Terms of Service
              </Link>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
