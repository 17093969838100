import {
  APIResponse,
  SubmitActions,
  SubmitSingleDispatch,
  SubmitBulkDispatch,
  SubmitClearDispatch,
  SubmissionObject
} from '../../typings';

import { Dispatch } from 'redux';
import { submitService } from '../services/submit.services';
import { notificationActions } from './notification.actions';

export const submitActions = {
  submitCodeSingle,
  submitCodeBulk,
  submitCodeClear
};

function submitCodeClear(): (dispatch: Dispatch<SubmitClearDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    function request(): SubmitClearDispatch {
      return {
        type: SubmitActions.CLEAR_CODES
      };
    }
  };
}

function submitCodeSingle(
  code: string,
  token: string,
  cb?: (codes: SubmissionObject) => void
): (dispatch: Dispatch<SubmitSingleDispatch>) => void {
  return (dispatch) => {
    dispatch(request(code));

    submitService.submitCodeSingle(code, token).then((res: APIResponse<SubmissionObject>) => {
      if (!res.status) return dispatch(failure(res));

      const [code] = Object.keys(res.data);

      dispatch(success(res));

      notificationActions.showNotification({
        id: `code-${code}`,
        title: 'Submission successful',
        description: `Code ${code} was accepted`,
        duration: 5000
      })(dispatch);
    });
  };

  function request(code: string): SubmitSingleDispatch {
    return {
      type: SubmitActions.SINGLE_REQUEST,
      payload: { [code]: null }
    };
  }

  function success({ data }: APIResponse<SubmissionObject>): SubmitSingleDispatch {
    if (cb) cb(data);

    return {
      type: SubmitActions.SINGLE_SUCCESS,
      payload: data
    };
  }

  function failure({ data, message }: APIResponse<SubmissionObject>): SubmitSingleDispatch {
    return { type: SubmitActions.SINGLE_FAILURE, payload: data, error: (message as any)?.message || message };
  }
}

function submitCodeBulk(codes: string, token: string): (dispatch: Dispatch<SubmitBulkDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    submitService
      .submitCodeBulk(codes, token)
      .then((res: APIResponse<SubmissionObject>) => dispatch(res.status ? success(res) : failure(res)));
  };

  function request(): SubmitBulkDispatch {
    return {
      type: SubmitActions.BULK_REQUEST
    };
  }

  function success({ data }: APIResponse<SubmissionObject>): SubmitBulkDispatch {
    return {
      type: SubmitActions.BULK_SUCCESS,
      payload: data
    };
  }

  function failure({ data, message }: APIResponse<SubmissionObject>): SubmitSingleDispatch {
    return { type: SubmitActions.BULK_FAILURE, payload: data, error: (message as any)?.message || message };
  }
}
