import { reloadToken } from '../../helpers';
import { TokenActions, TokenReducerResponse, TokenReducerAction } from '../../typings';
import Cookies from 'universal-cookie';

const initialState = reloadToken({
  loading: false,
  loaded: false,
  account: null,
  accessToken: null,
  refreshToken: null,
  error: null
}) as TokenReducerResponse;

export default function token(
  state: TokenReducerResponse = initialState,
  action: TokenReducerAction
): TokenReducerResponse {
  switch (action.type) {
    case TokenActions.LOGIN_REQUEST:
    case TokenActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case TokenActions.LOGIN_FAILURE:
      return {
        loading: false,
        loaded: true,
        error: action.error || null
      };

    case TokenActions.LOGIN_SUCCESS:
      return {
        loading: false,
        loaded: true,
        account: action.account,
        error: null,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken
      };

    case TokenActions.LOGOUT_SUCCESS:
      const cookies = new Cookies();
      cookies.remove(TokenActions.STORAGE_NAME);
      window.location.reload();

      return {
        loading: false,
        loaded: true,
        account: null,
        error: null
      };

    default:
      return state;
  }
}
