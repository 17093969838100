export const apiGateway = process.env.REACT_APP_API_GATEWAY;

export const maxCodeSubmission = Number.parseInt(process.env.REACT_APP_CODE_SUBMIT_LIMIT || '50', 10);
export const maxCodesInPeriod = Number.parseInt(process.env.REACT_APP_CODE_REQUEST_LIMIT || '1', 10);

export const codeWaitTime = [process.env.REACT_APP_CODE_TIME_LIMIT, 'seconds'];
export const codeInterval = 1000;

export const codeLatestCount = Number.parseInt(process.env.REACT_APP_LATEST_LIMIT || '15', 10);

export const pageTitle = 'tcg.codes • %s';

export const twitterAccount = 'tcg_codes';
