import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useCallback } from 'react';

interface StatsCardProps {
  title: string;
  value?: number;
  loading?: boolean;
  icon?: IconName;
  variant?: string;
}

export function StatsCard({
  title,
  loading,
  value = 0,
  variant = 'primary',
  icon = 'question'
}: StatsCardProps): JSX.Element {
  const formatNumber = useCallback((value: number) => {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  }, []);
  return (
    <Card className="stats-card">
      <Card.Body>
        <Row>
          <Col>
            <Card.Title as={'h5'} className="text-uppercase">
              {title}
            </Card.Title>
            <CountUp
              className="h2"
              start={0}
              end={value}
              duration={1}
              useEasing={true}
              redraw={false}
              preserveValue={true}
              formattingFn={formatNumber}
            />
          </Col>
          <div className="col-auto">
            <div className={`icon icon-shape bg-${variant} text-white rounded-circle shadow`}>
              <FontAwesomeIcon icon={['fas', icon]} />
            </div>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}
