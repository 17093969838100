import { getToken } from './getToken.helpers';

export function post(url: string, body: { [key: string]: string }): Promise<Response> {
  const token = getToken();
  const headers: HeadersInit = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: new URLSearchParams(body)
  });
}

export function get(url: string, query?: { [key: string]: string }): Promise<Response> {
  const token = getToken();
  const headers: HeadersInit = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(url + (query ? `?${new URLSearchParams(query)}` : ''), {
    method: 'GET',
    mode: 'cors',
    headers
  });
}

export async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    return Promise.reject(await response.json());
  }

  return Promise.resolve(await response.json());
}
