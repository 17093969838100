import { CodeType, ExpansionObject } from './code.typings';

export enum StockActions {
  AVAILABLE_REQUEST = 'STOCK_AVAILABLE_REQUEST',
  AVAILABLE_SUCCESS = 'STOCK_AVAILABLE_SUCCESS',
  AVAILABLE_FAILURE = 'STOCK_AVAILABLE_FAILURE'
}

export interface StockCount {
  type: CodeType;
  count: number;
}

export interface StockLevelObject extends ExpansionObject {
  count: number;
  types: StockCount[];
}

export interface StockReducerAction {
  type: StockActions;
  code: string;
  payload: StockLevelObject[];
  error?: Error | null;
}

export interface StockReducerResponse {
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  available: StockLevelObject[];
}

export interface StockAvailabilityDispatch {
  type: StockActions;
  payload?: StockLevelObject[];
  error?: string | null;
}
