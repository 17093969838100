export enum CodeActions {
  REQUEST_REQUEST = 'CODE_REQUEST_REQUEST',
  REQUEST_SUCCESS = 'CODE_REQUEST_SUCCESS',
  REQUEST_FAILURE = 'CODE_REQUEST_FAILURE',

  RETURN_REQUEST = 'CODE_RETURN_REQUEST',
  RETURN_SUCCESS = 'CODE_RETURN_SUCCESS',
  RETURN_FAILURE = 'CODE_RETURN_FAILURE',

  LATEST_REQUEST = 'CODE_LATEST_REQUEST',
  LATEST_SUCCESS = 'CODE_LATEST_SUCCESS',
  LATEST_FAILURE = 'CODE_LATEST_FAILURE',

  CLEAR_CODE_REQUEST = 'CODE_CLEAR_REQUEST',

  MSG_NEW_LATEST = 'CODE_MSG_NEW_LATEST'
}

export enum CodeType {
  'Booster' = 1,
  'ETB' = 2,
  'Promo' = 3,
  'Unknown' = 99
}

export interface CodeReducerResponse {
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  code: CodeObject | null;
  latest: ExpansionObject[];
}

export interface CodeReducerAction {
  type: CodeActions;
  code: string;
  payload: CodeObject | ExpansionObject[];
  error?: Error | null;
}

export interface CodeObject {
  _id: string;
  code: string;
  processed: boolean;
  available: boolean | null;
  used: boolean | null;
  wasValid: boolean | null;
  isValid: boolean | null;
  expansion: string | null;
  type: CodeType;
  coupon?: string;
  promo?: string;
  donor: string;
  recipient: string | null;
  createdAt: string;
  updatedAt: string;
  requestedAt: string;
}

export interface ExpansionObject {
  _id: string;
  id: string;
  name: string;
  series: string;
  total: number;
  printedTotal: number;
  packImageCount: number;
  releaseDate: string;
  seed?: string;
}

export interface CodeClearDispatch {
  type: CodeActions;
}

export interface CodeRequestDispatch {
  type: CodeActions;
  payload?: CodeObject;
  error?: string | null;
}

export interface CodeReturnDispatch {
  type: CodeActions;
  error?: string | null;
}

export interface CodeLatestDispatch {
  type: CodeActions;
  payload?: ExpansionObject[];
  error?: string | null;
}

export interface CodeNewLatestMessage {
  type: CodeActions;
  payload?: ExpansionObject[];
  error?: string | null;
}
