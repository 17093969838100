import { getToken } from './getToken.helpers';
import socketIOClient from 'socket.io-client';

export const socket = socketIOClient(process.env.REACT_APP_API_GATEWAY || '/', {
  path: `${process.env.REACT_APP_API_PATH || ''}/socket.io`,
  transports: ['websocket'],
  upgrade: false,
  auth: {
    token: getToken()
  }
});
