import { applyMiddleware, createStore } from 'redux';

import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';

const middleware = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger());
}

export const store = createStore(rootReducer, applyMiddleware(...middleware));
