import { TopActions, TopReducerAction, TopReducerResponse } from '../../typings';

const initialState: TopReducerResponse = {
  loading: false,
  loaded: false,
  error: null,
  data: undefined
};

export default function top(state: TopReducerResponse = initialState, action: TopReducerAction): TopReducerResponse {
  switch (action.type) {
    case TopActions.DONOR_TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error || new Error('Unknwon Error')
      };

    case TopActions.DONOR_TOTAL_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case TopActions.DONOR_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload || []
      };

    default:
      return state;
  }
}
