import { SubmissionObject } from '../../../typings';
import { QrReader } from './QrReader';
import { submitActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Result } from '@zxing/library';

interface InputQRProps {
  submitCode: (code: string, token: string, cb?: (res: SubmissionObject) => void) => void;
}

let scanned: string[] = [];

function InputQR({ submitCode }: InputQRProps): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    return () => {
      scanned = [];
    };
  }, []);

  const submit = useCallback(
    async (result: Result, err?: Error) => {
      if (!result || err || !executeRecaptcha) return;

      const code = result.getText().replace(/-/g, '').toUpperCase();

      if (code === '' || (scanned && scanned.includes(code))) return;

      scanned.push(code);

      const token = await executeRecaptcha('CodeSubmit');

      submitCode(code, token);
    },
    [submitCode, executeRecaptcha]
  );

  return (
    <Card.Body className="single-code-container">
      <QrReader scanDelay={250} onResult={submit} />
    </Card.Body>
  );
}

const actionCreators = {
  submitCode: submitActions.submitCodeSingle
};

const connectedInputQR = connect(undefined, actionCreators)(InputQR);
export { connectedInputQR as InputQR };
