import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { apiGateway } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';
import { useCallback, useContext, useEffect } from 'react';
import { AccountContext, CookieContext } from '../../components';
import { Modal } from 'react-bootstrap';
import { changePageDetails } from '../../helpers';
import { messages } from './messages';

function Login(): JSX.Element {
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const { consent } = useContext(CookieContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    changePageDetails('Login', 'login');
  }, []);

  const trackAction = useCallback(() => {
    if (consent) ReactGA.event({ category: 'User', action: 'Login' });
  }, [consent]);

  const close = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const redirectLocation = function () {
    return searchParams.get('returnTo') || '/';
  };

  if (account) {
    return <Navigate to={redirectLocation()} />;
  }

  if (!consent) {
    return (
      <Modal show className="page-login" animation={true} onEscapeKeyDown={close} onHide={close}>
        <Modal.Header>
          <h1 className="text-intro">Login</h1>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">Please refresh and consent to cookies before attempting to login.</p>
          <p className="mb-5">Without them enabled we cannot store the required data to ensure you remain logged in.</p>
        </Modal.Body>
      </Modal>
    );
  }

  if (searchParams.get('p') && searchParams.get('e')) {
    return (
      <Modal show className="page-login" animation={true} onEscapeKeyDown={close} onHide={close}>
        <Modal.Header>
          <h1 className="text-intro text-danger">ERROR</h1>
        </Modal.Header>
        <Modal.Body>
          <p className="text-secondary mb-3">
            {messages?.[searchParams.get('e') || 'unknown']?.replace('%s', searchParams.get('p')) || 'Unknown Error'}
          </p>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show className="page-login" animation={true} onEscapeKeyDown={close} onHide={close}>
      <Modal.Body>
        <ul className="action-btn-list">
          <li>
            <a href={`${apiGateway}/auth/reddit?returnTo=${redirectLocation()}`} onClick={trackAction}>
              <button className="action-btn action-btn-reddit">
                <FontAwesomeIcon icon={['fab', 'reddit-alien']} className="fa-xl me-1" /> Login with Reddit
              </button>
            </a>
          </li>
          {/*<li>
            <a
              href={`${apiGateway}/auth/twitter?returnTo=${redirectLocation()}`}
              onClick={trackAction}
              className="mb-3"
            >
              <button className="action-btn action-btn-twitter">
                <FontAwesomeIcon icon={['fab', 'twitter']} className="fa-xl me-1" /> Login with Twitter
              </button>
            </a>
  </li>*/}
          <li className="message">
            <p className="text-center fs-6">
              An account is required to proceed, this helps us ensure codes are distributed fairly to all users.
            </p>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}

function mapState({ token }: rootState) {
  return {
    loading: token.loading,
    loaded: token.loaded,
    error: token.error
  };
}

const connectedLogin = connect(mapState)(Login);
export { connectedLogin as Login };
