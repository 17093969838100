import { Overlay, Popover } from 'react-bootstrap';
import { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AccountContext } from '../../../components';

interface ActionRequestProps {
  disabled: boolean;
  max: number;
  used: number;
  onRequest: (token: string) => void;
  loading: boolean;
  error?: Error | null;
}

export function ActionRequest({ disabled, max, used, onRequest, loading, error }: ActionRequestProps): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { account } = useContext(AccountContext);
  const [hasClicked, setHasClicked] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLButtonElement>(null);
  const errorTimerRef = useRef<ReturnType<typeof setTimeout>>();

  const onClick = () => {
    if (!executeRecaptcha) return;
    setHasClicked(true);
    executeRecaptcha('CodeRequested').then((token) => onRequest(token));
  };

  const clearError = useCallback(() => {
    setHasClicked(false);
    return clearTimeout(errorTimerRef.current);
  }, [setHasClicked]);

  useEffect(() => {
    if (error && hasClicked) errorTimerRef.current = setTimeout(clearError, 5000);
    return () => clearTimeout(errorTimerRef.current);
  }, [hasClicked, error, clearError]);

  const shouldDisable = disabled || loading || (!!error && hasClicked) || process.env.REACT_APP_CODE_ENABLED !== 'true';

  return (
    <div ref={containerRef} className="action-btn-container">
      <Overlay
        show={!!error && hasClicked}
        target={targetRef}
        placement="top"
        container={containerRef}
        containerPadding={20}
      >
        <Popover>
          <Popover.Body>{error ? error.toString() : 'Internal Server Error'}</Popover.Body>
        </Popover>
      </Overlay>
      <button
        className={`${!shouldDisable ? 'btn-fizzy ' : ''}action-btn trigger text-intro animated-middle fadeInUp me-3`}
        onClick={onClick}
        disabled={shouldDisable}
        ref={targetRef}
      >
        <div className="button-inner">
          <span className="t">
            {process.env.REACT_APP_CODE_ENABLED !== 'true'
              ? 'Come back soon 😊'
              : `Claim a code${account ? `, ${max - used} available` : ''}`}
          </span>
          <div className="button-layout-quad">
            {[...Array(52)].map((_v, idx: number) => (
              <div key={`button-spots-${idx}`} className="button-spots"></div>
            ))}
          </div>
        </div>
      </button>
    </div>
  );
}
