import { ReactNode } from 'react';
import QRCode from 'react-qr-code';
import { CodeType } from '../typings';

interface CodeCardProps {
  id: string;
  code: string;
  type: CodeType;
  coupon: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  children?: ReactNode;
}

export function CodeCard({ id, code, type, coupon, size, children }: CodeCardProps): JSX.Element {
  return (
    <div className={`code-card code-card-${CodeType[type].toLowerCase()}${size ? ` code-card-${size}` : ''}`}>
      <QRCode className="code-card-qr" value={code} size={300} level="L" viewBox="0 0 300 300" />
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1110" height="800" viewBox="0 0 1110 800">
        <g id="Background">
          <g>
            <path
              style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
              d="M0,54.66C0,9.27,9.31,0,54.9,0h1000.2c45.59,0,54.9,9.27,54.9,54.66v690.68c0,45.39-9.31,54.66-54.9,54.66
			H54.9C9.31,800,0,790.73,0,745.34V54.66z"
            />
          </g>
          <g>
            <path
              style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#fff' }}
              d="M25,74.5C25,32.73,31.42,25,71.58,25h966.84c40.16,0,46.58,7.73,46.58,49.5v651c0,41.77-6.42,49.5-46.58,49.5
			H71.58C31.42,775,25,767.27,25,725.5V74.5z"
            />
          </g>
        </g>
        <g id="Details">
          <rect
            x="80"
            y="400"
            width="300"
            height="300"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#fff' }}
          ></rect>
          <g id="ID">
            <rect x="690" y="420" width="218" height="46.8" />
            <text x="690" y="454" width="218" height="46.8">
              EN{id?.toUpperCase()}
              {type === CodeType.Booster ? 'BST2' : CodeType[type].toUpperCase()}
            </text>
          </g>
          <g id="Code">
            <rect x="410" y="530" width="498" height="76.8"></rect>
            <text x="410" y="585" width="498" height="76.8">
              {code}
            </text>
          </g>
          <g id="Coupon">
            <rect x="410" y="661" width="408" height="46.8" />
            <text x="410" y="694" width="408" height="46.8">
              {coupon}
            </text>
          </g>
        </g>
        <g id="Header">
          <linearGradient
            id="Header_Background_00000102508536374947421840000003317703586020433798_"
            gradientUnits="userSpaceOnUse"
            x1="25"
            y1="175"
            x2="1085"
            y2="175"
          >
            <stop offset="0" style={{ stopColor: '#A0A9AE' }} />
            <stop offset="0.2524" style={{ stopColor: '#A0A9AE' }} />
            <stop offset="0.3524" style={{ stopColor: '#B7BFC5' }} />
            <stop offset="0.7476" style={{ stopColor: '#D7E1E0' }} />
            <stop offset="1" style={{ stopColor: '#A0A9AE' }} />
          </linearGradient>

          <path
            id="Header_Background"
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              fill: 'url(#Header_Background_00000102508536374947421840000003317703586020433798_)'
            }}
            d="
		M25,74.5V325h1060V74.5c0-41.77-6.42-49.5-46.58-49.5H71.58C31.42,25,25,32.73,25,74.5z"
          />
          <g id="Content">
            <g>
              <path
                d="M237.23,52.11v16.87c0,4.14,1.29,5.47,2.93,5.47c1.75,0,2.93-1.29,2.93-5.47V52.11h4.06v16.19
				c0,6.61-2.58,9.73-7.03,9.73c-4.67,0-6.95-3.15-6.95-9.54V52.11H237.23z"
              />
              <path
                d="M251.44,73.88c0.68,0.46,1.98,0.99,3.19,0.99c1.44,0,2.32-0.8,2.32-2.13c0-1.14-0.53-1.9-2.24-3
				c-2.74-1.63-3.76-3.42-3.76-5.28c0-3.19,2.36-5.55,5.66-5.55c1.52,0,2.66,0.42,3.57,0.91l-0.84,3c-0.72-0.46-1.56-0.8-2.55-0.8
				c-1.41,0-2.09,0.91-2.09,1.94c0,0.99,0.46,1.56,2.24,2.7c2.47,1.48,3.76,3.31,3.76,5.58c0,3.76-2.62,5.7-6.12,5.7
				c-1.44,0-3.12-0.42-3.95-1.06L251.44,73.88z"
              />
              <path
                d="M267.62,69.55c0.04,4.14,1.98,5.28,4.14,5.28c1.33,0,2.47-0.3,3.31-0.68l0.57,2.93c-1.14,0.53-2.93,0.87-4.64,0.87
				c-4.75,0-7.33-3.46-7.33-9.19c0-6.12,2.96-9.84,6.92-9.84c3.88,0,5.85,3.53,5.85,8.47c0,1.06-0.08,1.67-0.11,2.17H267.62z
				 M272.68,66.66c0.04-2.96-0.95-4.71-2.39-4.71c-1.75,0-2.55,2.55-2.62,4.71H272.68z"
              />
              <path
                d="M292.13,54.61v4.6h3.57v2.96h-3.57v9.57c0,2.28,0.87,2.85,1.9,2.85c0.46,0,0.84-0.04,1.25-0.11l0.08,3.15
				c-0.61,0.19-1.52,0.3-2.66,0.3c-1.29,0-2.47-0.34-3.27-1.14c-0.91-0.91-1.37-2.28-1.37-5.02v-9.61h-2.2v-2.96h2.2v-3.19
				L292.13,54.61z"
              />
              <path
                d="M299.09,51.42h4.07v9.76h0.08c0.42-0.65,0.99-1.22,1.6-1.6c0.68-0.42,1.48-0.68,2.47-0.68c2.93,0,4.56,2.43,4.56,7.1
				v11.7h-4.07V66.16c0-2.01-0.42-3.84-2.13-3.84c-1.06,0-1.94,0.72-2.36,2.28c-0.08,0.34-0.15,0.95-0.15,1.41v11.7h-4.07V51.42z"
              />
              <path
                d="M320.71,54.39c0.04,1.37-0.91,2.32-2.24,2.32c-1.18,0-2.13-0.95-2.09-2.32c-0.04-1.44,0.95-2.36,2.17-2.36
				C319.8,52.03,320.71,52.94,320.71,54.39z M316.53,77.71v-18.5h4.07v18.5H316.53z"
              />
              <path
                d="M324.97,73.88c0.68,0.46,1.98,0.99,3.19,0.99c1.44,0,2.32-0.8,2.32-2.13c0-1.14-0.53-1.9-2.24-3
				c-2.74-1.63-3.76-3.42-3.76-5.28c0-3.19,2.36-5.55,5.66-5.55c1.52,0,2.66,0.42,3.57,0.91l-0.84,3c-0.72-0.46-1.56-0.8-2.55-0.8
				c-1.41,0-2.09,0.91-2.09,1.94c0,0.99,0.46,1.56,2.24,2.7c2.47,1.48,3.76,3.31,3.76,5.58c0,3.76-2.62,5.7-6.12,5.7
				c-1.44,0-3.12-0.42-3.95-1.06L324.97,73.88z"
              />
              <path
                d="M354.6,77.33c-0.57,0.3-1.75,0.61-3.12,0.61c-4.41,0-7.33-3.27-7.33-9.35c0-5.51,3.08-9.65,7.98-9.65
				c1.1,0,2.17,0.27,2.66,0.53l-0.61,3.23c-0.42-0.15-1.06-0.42-1.98-0.42c-2.62,0-3.99,2.85-3.99,6.23c0,3.8,1.52,6.08,3.99,6.08
				c0.76,0,1.41-0.11,1.98-0.42L354.6,77.33z"
              />
              <path
                d="M370.11,68.41c0,7.48-3.84,9.61-6.65,9.61c-3.91,0-6.61-3.27-6.61-9.5c0-6.69,3.31-9.61,6.76-9.61
				C367.49,58.91,370.11,62.33,370.11,68.41z M360.91,68.44c0,3.12,0.53,6.46,2.7,6.46c2.01,0,2.47-3.69,2.47-6.46
				c0-2.7-0.46-6.38-2.55-6.38C361.33,62.06,360.91,65.75,360.91,68.44z"
              />
              <path
                d="M386.52,51.42V72.7c0,1.52,0.08,3.61,0.15,5.02h-3.5l-0.23-2.2h-0.11c-0.65,1.33-2.05,2.51-3.84,2.51
				c-3.61,0-5.62-3.91-5.62-9.5c0-6.5,2.81-9.61,5.81-9.61c1.52,0,2.62,0.72,3.23,2.01h0.08v-9.5H386.52z M382.5,66.28
				c0-0.34,0-0.76-0.08-1.14c-0.15-1.48-0.99-2.77-2.2-2.77c-2.05,0-2.77,2.89-2.77,6.19c0,3.72,0.99,6.12,2.7,6.12
				c0.76,0,1.71-0.42,2.2-2.28c0.11-0.34,0.15-0.76,0.15-1.22V66.28z"
              />
              <path
                d="M394.43,69.55c0.04,4.14,1.98,5.28,4.14,5.28c1.33,0,2.47-0.3,3.31-0.68l0.57,2.93c-1.14,0.53-2.93,0.87-4.64,0.87
				c-4.75,0-7.33-3.46-7.33-9.19c0-6.12,2.96-9.84,6.92-9.84c3.88,0,5.85,3.53,5.85,8.47c0,1.06-0.08,1.67-0.11,2.17H394.43z
				 M399.48,66.66c0.04-2.96-0.95-4.71-2.39-4.71c-1.75,0-2.55,2.55-2.62,4.71H399.48z"
              />
              <path
                d="M418.94,54.61v4.6h3.57v2.96h-3.57v9.57c0,2.28,0.87,2.85,1.9,2.85c0.46,0,0.84-0.04,1.25-0.11l0.08,3.15
				c-0.61,0.19-1.52,0.3-2.66,0.3c-1.29,0-2.47-0.34-3.27-1.14c-0.91-0.91-1.37-2.28-1.37-5.02v-9.61h-2.2v-2.96h2.2v-3.19
				L418.94,54.61z"
              />
              <path
                d="M438.16,68.41c0,7.48-3.84,9.61-6.65,9.61c-3.91,0-6.61-3.27-6.61-9.5c0-6.69,3.31-9.61,6.76-9.61
				C435.54,58.91,438.16,62.33,438.16,68.41z M428.97,68.44c0,3.12,0.53,6.46,2.7,6.46c2.01,0,2.47-3.69,2.47-6.46
				c0-2.7-0.46-6.38-2.55-6.38C429.39,62.06,428.97,65.75,428.97,68.44z"
              />
              <path
                d="M461.57,72.36c0,1.98,0.08,3.8,0.15,5.36h-3.5l-0.27-1.94h-0.08c-0.72,1.1-2.17,2.24-4.14,2.24
				c-3.38,0-4.71-2.55-4.71-7.37V59.21h4.03v10.94c0,2.62,0.49,4.48,2.17,4.48c1.22,0,1.86-1.1,2.05-1.82
				c0.15-0.42,0.23-0.99,0.23-1.6V59.21h4.07V72.36z"
              />
              <path
                d="M466.32,64.42c0-2.09-0.08-3.57-0.15-5.21h3.53l0.19,2.17h0.08c0.87-1.48,2.43-2.47,4.37-2.47
				c3.12,0,4.75,2.39,4.75,6.69v12.12h-4.07V65.97c0-1.82-0.38-3.65-2.17-3.65c-0.99,0-1.9,0.76-2.28,2.28
				c-0.11,0.38-0.19,0.99-0.19,1.67v11.44h-4.07V64.42z"
              />
              <path d="M483.76,51.42h4.07v26.29h-4.07V51.42z" />
              <path
                d="M505.23,68.41c0,7.48-3.84,9.61-6.65,9.61c-3.91,0-6.61-3.27-6.61-9.5c0-6.69,3.31-9.61,6.76-9.61
				C502.61,58.91,505.23,62.33,505.23,68.41z M496.04,68.44c0,3.12,0.53,6.46,2.7,6.46c2.01,0,2.47-3.69,2.47-6.46
				c0-2.7-0.46-6.38-2.55-6.38C496.46,62.06,496.04,65.75,496.04,68.44z"
              />
              <path
                d="M518.95,77.33c-0.57,0.3-1.75,0.61-3.12,0.61c-4.41,0-7.33-3.27-7.33-9.35c0-5.51,3.08-9.65,7.98-9.65
				c1.1,0,2.17,0.27,2.66,0.53l-0.61,3.23c-0.42-0.15-1.06-0.42-1.98-0.42c-2.62,0-3.99,2.85-3.99,6.23c0,3.8,1.52,6.08,3.99,6.08
				c0.76,0,1.41-0.11,1.98-0.42L518.95,77.33z"
              />
              <path
                d="M526.09,67.53h0.08c0.34-0.91,0.65-1.63,0.95-2.36l2.96-5.97h4.41l-4.67,7.75l4.98,10.75h-4.52l-3.23-8.09l-0.95,1.63
				v6.46h-4.03V51.42h4.03V67.53z"
              />
              <path
                d="M547.41,59.21l2.13,10.07c0.27,1.25,0.42,2.36,0.61,3.42h0.08c0.15-0.99,0.38-2.2,0.61-3.34l1.82-10.14h3.99l-3.46,13.22
				c-0.99,3.99-2.24,7.98-4.33,10.52c-1.44,1.79-3.08,2.62-3.8,2.89l-1.29-3.5c0.84-0.38,1.71-1.03,2.51-1.9
				c0.72-0.76,1.25-1.75,1.6-2.58c0.15-0.38,0.23-0.61,0.23-0.87c0-0.23-0.04-0.46-0.11-0.68l-4.9-17.1H547.41z"
              />
              <path
                d="M571.66,68.41c0,7.48-3.84,9.61-6.65,9.61c-3.91,0-6.61-3.27-6.61-9.5c0-6.69,3.31-9.61,6.76-9.61
				C569.03,58.91,571.66,62.33,571.66,68.41z M562.46,68.44c0,3.12,0.53,6.46,2.7,6.46c2.01,0,2.47-3.69,2.47-6.46
				c0-2.7-0.46-6.38-2.54-6.38C562.88,62.06,562.46,65.75,562.46,68.44z"
              />
              <path
                d="M588.11,72.36c0,1.98,0.08,3.8,0.15,5.36h-3.5l-0.27-1.94h-0.08c-0.72,1.1-2.17,2.24-4.14,2.24
				c-3.38,0-4.71-2.55-4.71-7.37V59.21h4.03v10.94c0,2.62,0.49,4.48,2.17,4.48c1.22,0,1.86-1.1,2.05-1.82
				c0.15-0.42,0.23-0.99,0.23-1.6V59.21h4.07V72.36z"
              />
              <path
                d="M592.86,64.42c0-1.71-0.11-3.61-0.15-5.21h3.53l0.23,2.81h0.11c0.72-1.9,2.24-3.12,3.76-3.12c0.38,0,0.61,0.04,0.84,0.08
				v4.07c-0.23-0.08-0.49-0.08-0.84-0.08c-1.41,0-2.85,1.18-3.27,3.19c-0.11,0.46-0.15,1.06-0.15,1.79v9.76h-4.07V64.42z"
              />
              <path
                d="M623.68,68.41c0,7.48-3.84,9.61-6.65,9.61c-3.91,0-6.61-3.27-6.61-9.5c0-6.69,3.3-9.61,6.76-9.61
				C621.05,58.91,623.68,62.33,623.68,68.41z M614.48,68.44c0,3.12,0.53,6.46,2.7,6.46c2.01,0,2.47-3.69,2.47-6.46
				c0-2.7-0.46-6.38-2.55-6.38C614.9,62.06,614.48,65.75,614.48,68.44z"
              />
              <path
                d="M627.67,64.42c0-2.09-0.08-3.57-0.15-5.21h3.53l0.19,2.17h0.08c0.87-1.48,2.43-2.47,4.37-2.47
				c3.12,0,4.75,2.39,4.75,6.69v12.12h-4.07V65.97c0-1.82-0.38-3.65-2.17-3.65c-0.99,0-1.9,0.76-2.28,2.28
				c-0.11,0.38-0.19,0.99-0.19,1.67v11.44h-4.07V64.42z"
              />
              <path d="M645.11,51.42h4.07v26.29h-4.07V51.42z" />
              <path
                d="M658.22,54.39c0.04,1.37-0.91,2.32-2.24,2.32c-1.18,0-2.13-0.95-2.09-2.32c-0.04-1.44,0.95-2.36,2.17-2.36
				C657.31,52.03,658.22,52.94,658.22,54.39z M654.04,77.71v-18.5h4.07v18.5H654.04z"
              />
              <path
                d="M662.78,64.42c0-2.09-0.08-3.57-0.15-5.21h3.53l0.19,2.17h0.08c0.87-1.48,2.43-2.47,4.37-2.47
				c3.12,0,4.75,2.39,4.75,6.69v12.12h-4.07V65.97c0-1.82-0.38-3.65-2.17-3.65c-0.99,0-1.9,0.76-2.28,2.28
				c-0.11,0.38-0.19,0.99-0.19,1.67v11.44h-4.07V64.42z"
              />
              <path
                d="M683.45,69.55c0.04,4.14,1.98,5.28,4.14,5.28c1.33,0,2.47-0.3,3.31-0.68l0.57,2.93c-1.14,0.53-2.93,0.87-4.64,0.87
				c-4.75,0-7.33-3.46-7.33-9.19c0-6.12,2.96-9.84,6.92-9.84c3.88,0,5.85,3.53,5.85,8.47c0,1.06-0.08,1.67-0.11,2.17H683.45z
				 M688.5,66.66c0.04-2.96-0.95-4.71-2.39-4.71c-1.75,0-2.55,2.55-2.62,4.71H688.5z"
              />
              <path
                d="M703.09,64.42c0-1.71-0.11-3.61-0.15-5.21h3.53l0.23,2.81h0.11c0.72-1.9,2.24-3.12,3.76-3.12c0.38,0,0.61,0.04,0.84,0.08
				v4.07c-0.23-0.08-0.49-0.08-0.84-0.08c-1.41,0-2.85,1.18-3.27,3.19c-0.11,0.46-0.15,1.06-0.15,1.79v9.76h-4.07V64.42z"
              />
              <path
                d="M717.31,69.55c0.04,4.14,1.98,5.28,4.14,5.28c1.33,0,2.47-0.3,3.31-0.68l0.57,2.93c-1.14,0.53-2.92,0.87-4.63,0.87
				c-4.75,0-7.33-3.46-7.33-9.19c0-6.12,2.96-9.84,6.92-9.84c3.88,0,5.85,3.53,5.85,8.47c0,1.06-0.08,1.67-0.11,2.17H717.31z
				 M722.36,66.66c0.04-2.96-0.95-4.71-2.39-4.71c-1.75,0-2.55,2.55-2.62,4.71H722.36z"
              />
              <path
                d="M732.58,59.21l1.33,8.47c0.3,1.98,0.61,3.91,0.8,5.74h0.15c0.27-1.82,0.68-3.91,1.06-5.66l1.86-8.55h3.12l1.79,8.32
				c0.42,2.09,0.8,3.95,1.06,5.89h0.11c0.15-1.86,0.46-3.76,0.8-5.81l1.41-8.4h4.03l-4.71,18.5h-3.42l-1.71-7.56
				c-0.42-1.79-0.72-3.57-0.99-5.7h-0.08c-0.27,2.17-0.65,3.99-1.06,5.74l-1.86,7.52h-3.46l-4.56-18.5H732.58z"
              />
              <path
                d="M764.27,73.5c0,1.44,0.04,3,0.23,4.22h-3.65l-0.23-1.9h-0.08c-0.95,1.44-2.32,2.2-3.91,2.2c-2.66,0-4.67-2.24-4.67-5.47
				c0-4.75,4.03-6.76,8.21-6.8v-0.49c0-1.9-0.76-3.27-2.77-3.27c-1.33,0-2.43,0.38-3.46,1.03l-0.76-2.77
				c0.91-0.57,2.85-1.33,5.09-1.33c4.79,0,6,3.27,6,7.22V73.5z M760.29,68.44c-1.6,0.04-4.45,0.46-4.45,3.69
				c0,2.09,1.1,2.85,2.01,2.85c0.99,0,1.98-0.68,2.32-2.13c0.08-0.27,0.11-0.61,0.11-0.95V68.44z"
              />
              <path
                d="M768.99,64.42c0-1.71-0.11-3.61-0.15-5.21h3.53l0.23,2.81h0.11c0.72-1.9,2.24-3.12,3.76-3.12c0.38,0,0.61,0.04,0.84,0.08
				v4.07c-0.23-0.08-0.49-0.08-0.84-0.08c-1.41,0-2.85,1.18-3.27,3.19c-0.11,0.46-0.15,1.06-0.15,1.79v9.76h-4.07V64.42z"
              />
              <path
                d="M792.39,51.42V72.7c0,1.52,0.08,3.61,0.15,5.02h-3.5l-0.23-2.2h-0.11c-0.65,1.33-2.05,2.51-3.84,2.51
				c-3.61,0-5.62-3.91-5.62-9.5c0-6.5,2.81-9.61,5.81-9.61c1.52,0,2.62,0.72,3.23,2.01h0.08v-9.5H792.39z M788.37,66.28
				c0-0.34,0-0.76-0.08-1.14c-0.15-1.48-0.99-2.77-2.2-2.77c-2.05,0-2.77,2.89-2.77,6.19c0,3.72,0.99,6.12,2.7,6.12
				c0.76,0,1.71-0.42,2.2-2.28c0.11-0.34,0.15-0.76,0.15-1.22V66.28z"
              />
              <path
                d="M799.01,78.02c-1.29,0-2.39-1.1-2.39-2.81c0-1.63,1.14-2.81,2.47-2.81c1.41,0,2.47,1.06,2.47,2.81
				c0,1.71-0.99,2.81-2.51,2.81H799.01z"
              />
              <path
                d="M816.11,77.71l-5.77-25.61h4.45l2.13,11.47c0.49,2.81,1.14,6.19,1.52,9.31h0.11c0.34-3.15,0.91-6.42,1.41-9.38l2.09-11.4
				h4.41l-5.93,25.61H816.11z"
              />
              <path
                d="M833.05,54.39c0.04,1.37-0.91,2.32-2.24,2.32c-1.18,0-2.13-0.95-2.09-2.32c-0.04-1.44,0.95-2.36,2.17-2.36
				C832.14,52.03,833.05,52.94,833.05,54.39z M828.88,77.71v-18.5h4.07v18.5H828.88z"
              />
              <path
                d="M837.31,73.88c0.68,0.46,1.98,0.99,3.19,0.99c1.44,0,2.32-0.8,2.32-2.13c0-1.14-0.53-1.9-2.24-3
				c-2.74-1.63-3.76-3.42-3.76-5.28c0-3.19,2.36-5.55,5.66-5.55c1.52,0,2.66,0.42,3.57,0.91l-0.83,3c-0.72-0.46-1.56-0.8-2.55-0.8
				c-1.41,0-2.09,0.91-2.09,1.94c0,0.99,0.46,1.56,2.24,2.7c2.47,1.48,3.76,3.31,3.76,5.58c0,3.76-2.62,5.7-6.12,5.7
				c-1.44,0-3.12-0.42-3.95-1.06L837.31,73.88z"
              />
              <path
                d="M854.45,54.39c0.04,1.37-0.91,2.32-2.24,2.32c-1.18,0-2.13-0.95-2.09-2.32c-0.04-1.44,0.95-2.36,2.17-2.36
				C853.54,52.03,854.45,52.94,854.45,54.39z M850.27,77.71v-18.5h4.07v18.5H850.27z"
              />
              <path
                d="M863.87,54.61v4.6h3.57v2.96h-3.57v9.57c0,2.28,0.87,2.85,1.9,2.85c0.46,0,0.84-0.04,1.25-0.11l0.08,3.15
				c-0.61,0.19-1.52,0.3-2.66,0.3c-1.29,0-2.47-0.34-3.27-1.14c-0.91-0.91-1.37-2.28-1.37-5.02v-9.61h-2.2v-2.96h2.2v-3.19
				L863.87,54.61z"
              />
              <path
                d="M873.22,64.91c-1.29,0-2.32-1.14-2.32-2.7c0-1.56,1.06-2.74,2.39-2.74c1.41,0,2.39,1.06,2.39,2.74
				c0,1.56-0.91,2.7-2.43,2.7H873.22z M873.22,78.02c-1.29,0-2.32-1.1-2.32-2.7c0-1.52,1.06-2.7,2.39-2.7c1.41,0,2.39,1.06,2.39,2.7
				c0,1.6-0.91,2.7-2.43,2.7H873.22z"
              />
            </g>
            <a href="https://pokemon.com/redeem" target="_blank" rel="noreferrer">
              <g>
                <path
                  d="M241.78,142.89v-35h27.75l6.5,6.5v13.25l-6.5,6.5h-16.75v8.75H241.78z M252.78,125.14h10.75l1.5-1.5v-5.25l-1.5-1.5
				h-10.75V125.14z"
                />
                <path
                  d="M286.03,142.89l-6-6v-13.5l6-6h16.75l6,6v13.5l-6,6H286.03z M291.53,134.89h5.75l1.25-1.25v-7l-1.25-1.25h-5.75
				l-1.25,1.25v7L291.53,134.89z"
                />
                <path d="M314.78,142.89v-35h10.25v18h2.95l6.5-8.5h9.65v2.5l-7.55,9.5l8.55,11v2.5h-10.3l-6.95-9h-2.85v9H314.78z" />
                <path
                  d="M353.88,142.89l-6-6v-13.5l6-6h16.75l6,6v9.5h-18.5v1.25l1.25,1.25h15.25v7.5H353.88z M358.13,127.64h8.25v-2l-1.25-1.25
				h-5.75l-1.25,1.25V127.64z"
                />
                <path
                  d="M382.13,142.89v-25.5h8.25v2h1l2.25-2h9.5l2.75,2.75h1.15l3.1-2.75h9.5l5.75,5.75v19.75h-10.25v-16.5l-1-1h-3.75
				l-1.5,1.25v16.25h-10.25v-16.5l-1-1h-3.75l-1.5,1.25v16.25H382.13z"
                />
                <path
                  d="M436.88,142.89l-6-6v-13.5l6-6h16.75l6,6v13.5l-6,6H436.88z M442.38,134.89h5.75l1.25-1.25v-7l-1.25-1.25h-5.75
				l-1.25,1.25v7L442.38,134.89z"
                />
                <path d="M465.38,142.89v-25.5h8.25v2h1l2.25-2h11l5.75,5.75v19.75h-10.25v-16.5l-1-1h-5.25l-1.5,1.25v16.25H465.38z" />
                <path d="M500.13,142.89v-8.5h10v8.5H500.13z" />
                <path d="M522.38,142.89l-6-6v-13.5l6-6h18.5v8h-13l-1.25,1.25v7l1.25,1.25h13v8H522.38z" />
                <path
                  d="M550.88,142.89l-6-6v-13.5l6-6h16.75l6,6v13.5l-6,6H550.88z M556.38,134.89h5.75l1.25-1.25v-7l-1.25-1.25h-5.75
				l-1.25,1.25v7L556.38,134.89z"
                />
                <path
                  d="M579.38,142.89v-25.5h8.25v2h1l2.25-2h9.5l2.75,2.75h1.15l3.1-2.75h9.5l5.75,5.75v19.75h-10.25v-16.5l-1-1h-3.75
				l-1.5,1.25v16.25h-10.25v-16.5l-1-1h-3.75l-1.5,1.25v16.25H579.38z"
                />
                <path d="M626.13,152.89v-3l14-43.5h7v3l-14,43.5H626.13z" />
                <path
                  d="M652.13,142.89v-35h28.25l6.5,6.5v11.5l-5.35,5.35l6.35,8.65v3h-10.8l-7.05-10h-6.9v10H652.13z M663.13,124.14h11.25
				l1.5-1.5v-4.5l-1.5-1.5h-11.25V124.14z"
                />
                <path
                  d="M699.38,142.89l-6-6v-13.5l6-6h16.75l6,6v9.5h-18.5v1.25l1.25,1.25h15.25v7.5H699.38z M703.63,127.64h8.25v-2l-1.25-1.25
				h-5.75l-1.25,1.25V127.64z"
                />
                <path
                  d="M733.13,142.89l-6-6v-13.5l6-6h12v-9.5h10.25v35h-8.25v-2h-1.1l-2.4,2H733.13z M738.63,134.89h4.75l1.75-1.25v-8.25h-6.5
				l-1.25,1.25v7L738.63,134.89z"
                />
                <path
                  d="M767.38,142.89l-6-6v-13.5l6-6h16.75l6,6v9.5h-18.5v1.25l1.25,1.25h15.25v7.5H767.38z M771.63,127.64h8.25v-2l-1.25-1.25
				h-5.75l-1.25,1.25V127.64z"
                />
                <path
                  d="M801.13,142.89l-6-6v-13.5l6-6h16.75l6,6v9.5h-18.5v1.25l1.25,1.25h15.25v7.5H801.13z M805.38,127.64h8.25v-2l-1.25-1.25
				h-5.75l-1.25,1.25V127.64z"
                />
                <path
                  d="M829.38,142.89v-25.5h8.25v2h1l2.25-2h9.5l2.75,2.75h1.15l3.1-2.75h9.5l5.75,5.75v19.75h-10.25v-16.5l-1-1h-3.75
				l-1.5,1.25v16.25h-10.25v-16.5l-1-1h-3.75l-1.5,1.25v16.25H829.38z"
                />
              </g>
            </a>
            <g>
              <path
                d="M246.27,178.58l-3.54-19.55h3.22l1.28,8.53c0.32,2.32,0.55,4.55,0.81,6.96h0.09c0.26-2.47,0.7-4.58,1.13-6.99l1.57-8.5
				h2.55l1.51,8.38c0.41,2.26,0.78,4.44,1.04,7.11h0.06c0.29-2.7,0.58-4.79,0.87-7.05l1.28-8.44h3.02l-3.6,19.55h-3.16l-1.48-7.8
				c-0.35-1.94-0.67-4.15-0.9-6.41h-0.06c-0.32,2.29-0.67,4.44-1.07,6.47l-1.6,7.75H246.27z"
              />
              <path
                d="M266.51,160.77c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C265.81,158.97,266.51,159.67,266.51,160.77z M263.32,178.58v-14.13h3.1v14.13H263.32z"
              />
              <path
                d="M273.7,160.95v3.51h2.73v2.26h-2.73v7.31c0,1.74,0.67,2.18,1.45,2.18c0.35,0,0.64-0.03,0.96-0.09l0.06,2.41
				c-0.46,0.15-1.16,0.23-2.03,0.23c-0.99,0-1.89-0.26-2.49-0.87c-0.7-0.7-1.04-1.74-1.04-3.83v-7.34h-1.68v-2.26h1.68v-2.44
				L273.7,160.95z"
              />
              <path
                d="M279.01,158.51h3.1v7.46h0.06c0.32-0.49,0.75-0.93,1.22-1.22c0.52-0.32,1.13-0.52,1.89-0.52c2.23,0,3.48,1.86,3.48,5.42
				v8.93h-3.1v-8.82c0-1.54-0.32-2.93-1.62-2.93c-0.81,0-1.48,0.55-1.8,1.74c-0.06,0.26-0.12,0.73-0.12,1.07v8.93h-3.1V158.51z"
              />
              <path
                d="M299.74,164.46l1.62,7.69c0.2,0.96,0.32,1.8,0.46,2.61h0.06c0.12-0.75,0.29-1.68,0.46-2.55l1.39-7.74h3.05l-2.64,10.09
				c-0.75,3.05-1.71,6.09-3.31,8.04c-1.1,1.36-2.35,2-2.9,2.2l-0.99-2.67c0.64-0.29,1.31-0.78,1.91-1.45
				c0.55-0.58,0.96-1.33,1.22-1.97c0.12-0.29,0.17-0.46,0.17-0.67c0-0.17-0.03-0.35-0.09-0.52l-3.74-13.05H299.74z"
              />
              <path
                d="M318.25,171.48c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.1,2.52-7.34,5.16-7.34
				C316.25,164.22,318.25,166.83,318.25,171.48z M311.23,171.5c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C311.55,166.63,311.23,169.45,311.23,171.5z"
              />
              <path
                d="M330.8,174.49c0,1.51,0.06,2.9,0.12,4.09h-2.67l-0.2-1.48h-0.06c-0.55,0.84-1.65,1.71-3.16,1.71
				c-2.58,0-3.6-1.94-3.6-5.63v-8.73h3.07v8.35c0,2,0.38,3.42,1.65,3.42c0.93,0,1.42-0.84,1.57-1.39c0.12-0.32,0.17-0.75,0.17-1.22
				v-9.17h3.1V174.49z"
              />
              <path
                d="M334.43,168.43c0-1.31-0.09-2.76-0.12-3.97h2.7l0.17,2.15h0.09c0.55-1.45,1.71-2.38,2.87-2.38
				c0.29,0,0.46,0.03,0.64,0.06v3.1c-0.17-0.06-0.38-0.06-0.64-0.06c-1.07,0-2.18,0.9-2.49,2.44c-0.09,0.35-0.12,0.81-0.12,1.36
				v7.46h-3.1V168.43z"
              />
              <path
                d="M348.38,168.89c0-1.89-0.09-3.25-0.12-4.44h2.7l0.17,1.71h0.06c0.67-1.31,1.74-1.94,2.96-1.94
				c2.81,0,4.24,3.05,4.24,7.28c0,5.11-2.18,7.31-4.47,7.31c-1.25,0-2-0.75-2.41-1.48h-0.06v6.82h-3.07V168.89z M351.45,173.42
				c0,0.44,0.06,0.87,0.15,1.19c0.38,1.36,1.1,1.65,1.6,1.65c1.57,0,2.12-2.23,2.12-4.73c0-2.78-0.67-4.67-2.09-4.67
				c-0.78,0-1.48,0.87-1.65,1.8c-0.09,0.32-0.12,0.67-0.12,1.04V173.42z"
              />
              <path
                d="M370.04,175.36c0,1.1,0.03,2.29,0.17,3.22h-2.78l-0.17-1.45h-0.06c-0.73,1.1-1.77,1.68-2.99,1.68
				c-2.03,0-3.57-1.71-3.57-4.18c0-3.63,3.07-5.16,6.27-5.19v-0.38c0-1.45-0.58-2.49-2.12-2.49c-1.02,0-1.86,0.29-2.64,0.78
				l-0.58-2.12c0.7-0.44,2.18-1.02,3.89-1.02c3.65,0,4.58,2.49,4.58,5.51V175.36z M366.99,171.5c-1.22,0.03-3.39,0.35-3.39,2.81
				c0,1.6,0.84,2.18,1.54,2.18c0.75,0,1.51-0.52,1.77-1.62c0.06-0.2,0.09-0.46,0.09-0.73V171.5z"
              />
              <path
                d="M373.63,168.43c0-1.31-0.09-2.76-0.12-3.97h2.7l0.17,2.15h0.09c0.55-1.45,1.71-2.38,2.87-2.38
				c0.29,0,0.46,0.03,0.64,0.06v3.1c-0.17-0.06-0.38-0.06-0.64-0.06c-1.07,0-2.18,0.9-2.49,2.44c-0.09,0.35-0.12,0.81-0.12,1.36
				v7.46h-3.1V168.43z"
              />
              <path
                d="M384.48,172.35c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H384.48z
				 M388.34,170.14c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H388.34z"
              />
              <path
                d="M394.17,168.43c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V168.43z"
              />
              <path
                d="M411.07,160.95v3.51h2.73v2.26h-2.73v7.31c0,1.74,0.67,2.18,1.45,2.18c0.35,0,0.64-0.03,0.96-0.09l0.06,2.41
				c-0.46,0.15-1.16,0.23-2.03,0.23c-0.99,0-1.89-0.26-2.49-0.87c-0.7-0.7-1.04-1.74-1.04-3.83v-7.34h-1.68v-2.26h1.68v-2.44
				L411.07,160.95z"
              />
              <path d="M420.41,158.74c-0.7,2.49-1.94,5.28-2.96,7.02l-1.68,0.23c0.73-2.09,1.51-4.79,1.83-6.96L420.41,158.74z" />
              <path
                d="M420.53,175.65c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L420.53,175.65z"
              />
              <path
                d="M435.72,168.89c0-1.89-0.09-3.25-0.12-4.44h2.7l0.17,1.71h0.06c0.67-1.31,1.74-1.94,2.96-1.94
				c2.81,0,4.23,3.05,4.23,7.28c0,5.11-2.18,7.31-4.47,7.31c-1.25,0-2-0.75-2.41-1.48h-0.06v6.82h-3.07V168.89z M438.8,173.42
				c0,0.44,0.06,0.87,0.15,1.19c0.38,1.36,1.1,1.65,1.6,1.65c1.57,0,2.12-2.23,2.12-4.73c0-2.78-0.67-4.67-2.09-4.67
				c-0.78,0-1.48,0.87-1.65,1.8c-0.09,0.32-0.12,0.67-0.12,1.04V173.42z"
              />
              <path
                d="M451.24,172.35c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H451.24z
				 M455.09,170.14c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H455.09z"
              />
              <path
                d="M460.92,168.43c0-1.31-0.09-2.76-0.12-3.97h2.7l0.17,2.15h0.09c0.55-1.45,1.71-2.38,2.87-2.38
				c0.29,0,0.46,0.03,0.64,0.06v3.1c-0.17-0.06-0.38-0.06-0.64-0.06c-1.07,0-2.18,0.9-2.49,2.44c-0.09,0.35-0.12,0.81-0.12,1.36
				v7.46h-3.1V168.43z"
              />
              <path
                d="M469.56,168.43c0-1.6-0.06-2.73-0.12-3.97h2.7l0.17,1.68h0.06c0.67-1.07,1.8-1.91,3.28-1.91c1.45,0,2.52,0.7,3.02,2.03
				h0.06c0.38-0.61,0.87-1.16,1.42-1.51c0.58-0.35,1.25-0.52,1.97-0.52c2.23,0,3.54,1.74,3.54,5.22v9.14h-3.08v-8.56
				c0-1.71-0.23-3.22-1.62-3.22c-0.78,0-1.42,0.61-1.71,1.65c-0.06,0.29-0.09,0.7-0.09,1.13v8.99h-3.1v-9.02
				c0-1.28-0.17-2.76-1.57-2.76c-0.73,0-1.45,0.58-1.71,1.74c-0.09,0.29-0.15,0.7-0.15,1.1v8.93h-3.07V168.43z"
              />
              <path
                d="M492.42,160.77c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C491.72,158.97,492.42,159.67,492.42,160.77z M489.23,178.58v-14.13h3.1v14.13H489.23z"
              />
              <path
                d="M495.66,175.65c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L495.66,175.65z"
              />
              <path
                d="M505.32,175.65c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L505.32,175.65z"
              />
              <path
                d="M518.4,160.77c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C517.7,158.97,518.4,159.67,518.4,160.77z M515.21,178.58v-14.13h3.1v14.13H515.21z"
              />
              <path
                d="M531.45,171.48c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.1,2.52-7.34,5.16-7.34
				C529.45,164.22,531.45,166.83,531.45,171.48z M524.43,171.5c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C524.75,166.63,524.43,169.45,524.43,171.5z"
              />
              <path
                d="M534.49,168.43c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V168.43z"
              />
              <path d="M546.76,182.21c0.75-2.06,1.54-5.1,1.83-7.28l3.28-0.29c-0.78,2.49-2,5.63-3.07,7.37L546.76,182.21z" />
              <path d="M560.1,158.51h3.1v20.07h-3.1V158.51z" />
              <path
                d="M576.49,171.48c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.1,2.52-7.34,5.16-7.34
				C574.49,164.22,576.49,166.83,576.49,171.48z M569.47,171.5c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C569.79,166.63,569.47,169.45,569.47,171.5z"
              />
              <path
                d="M589.04,176.9c0,3.34-0.52,4.87-1.57,5.95c-0.96,0.99-2.38,1.54-4.21,1.54c-1.28,0-2.47-0.26-3.37-0.67l0.64-2.41
				c0.64,0.32,1.54,0.61,2.67,0.61c1.86,0,2.76-1.19,2.76-3.83v-1.1h-0.06c-0.49,0.99-1.51,1.54-2.61,1.54
				c-2.87,0-4.29-3.19-4.29-6.9c0-4.93,2.23-7.4,4.61-7.4c1.28,0,2.12,0.78,2.61,1.74h0.06l0.17-1.51h2.73
				c-0.09,1.16-0.14,2.55-0.14,4.44V176.9z M585.97,169.47c0-0.35-0.06-0.73-0.14-1.02c-0.2-0.78-0.64-1.6-1.57-1.6
				c-1.48,0-2.18,1.97-2.18,4.67c0,3.05,0.93,4.52,2.12,4.52c0.58,0,1.25-0.29,1.6-1.48c0.14-0.38,0.17-0.78,0.17-1.16V169.47z"
              />
              <path
                d="M607.46,171.48c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.1,2.52-7.34,5.16-7.34
				C605.46,164.22,607.46,166.83,607.46,171.48z M600.44,171.5c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.88-2.81,1.88-4.93
				c0-2.06-0.35-4.87-1.94-4.87C600.76,166.63,600.44,169.45,600.44,171.5z"
              />
              <path
                d="M610.5,168.43c0-1.6-0.06-2.73-0.12-3.97h2.7l0.14,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.14,0.75-0.14,1.28v8.73h-3.1V168.43z"
              />
              <path
                d="M637.73,175.36c0,1.1,0.03,2.29,0.17,3.22h-2.79l-0.17-1.45h-0.06c-0.72,1.1-1.77,1.68-2.99,1.68
				c-2.03,0-3.57-1.71-3.57-4.18c0-3.63,3.07-5.16,6.26-5.19v-0.38c0-1.45-0.58-2.49-2.12-2.49c-1.02,0-1.86,0.29-2.64,0.78
				l-0.58-2.12c0.7-0.44,2.18-1.02,3.89-1.02c3.66,0,4.58,2.49,4.58,5.51V175.36z M634.69,171.5c-1.22,0.03-3.39,0.35-3.39,2.81
				c0,1.6,0.84,2.18,1.54,2.18c0.75,0,1.51-0.52,1.77-1.62c0.06-0.2,0.09-0.46,0.09-0.73V171.5z"
              />
              <path
                d="M641.33,168.43c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.14,0.75-0.14,1.28v8.73h-3.1V168.43z"
              />
              <path
                d="M664.12,158.51v16.24c0,1.16,0.06,2.76,0.12,3.83h-2.67l-0.17-1.68h-0.09c-0.49,1.02-1.57,1.91-2.93,1.91
				c-2.76,0-4.29-2.99-4.29-7.25c0-4.96,2.15-7.34,4.44-7.34c1.16,0,2,0.55,2.47,1.54h0.06v-7.25H664.12z M661.05,169.85
				c0-0.26,0-0.58-0.06-0.87c-0.12-1.13-0.75-2.12-1.68-2.12c-1.57,0-2.12,2.2-2.12,4.73c0,2.84,0.75,4.67,2.06,4.67
				c0.58,0,1.31-0.32,1.68-1.74c0.09-0.26,0.12-0.58,0.12-0.93V169.85z"
              />
              <path
                d="M682.51,174.49c0,1.51,0.06,2.9,0.12,4.09h-2.67l-0.2-1.48h-0.06c-0.55,0.84-1.65,1.71-3.16,1.71
				c-2.58,0-3.6-1.94-3.6-5.63v-8.73h3.08v8.35c0,2,0.38,3.42,1.65,3.42c0.93,0,1.42-0.84,1.57-1.39c0.12-0.32,0.17-0.75,0.17-1.22
				v-9.17h3.1V174.49z"
              />
              <path
                d="M685.9,175.65c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L685.9,175.65z"
              />
              <path
                d="M698.25,172.35c0.03,3.16,1.51,4.03,3.16,4.03c1.01,0,1.88-0.23,2.52-0.52l0.43,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H698.25z
				 M702.11,170.14c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H702.11z"
              />
              <path
                d="M716.96,160.95v3.51h2.73v2.26h-2.73v7.31c0,1.74,0.67,2.18,1.45,2.18c0.35,0,0.64-0.03,0.96-0.09l0.06,2.41
				c-0.46,0.15-1.16,0.23-2.03,0.23c-0.99,0-1.88-0.26-2.49-0.87c-0.7-0.7-1.04-1.74-1.04-3.83v-7.34h-1.68v-2.26h1.68v-2.44
				L716.96,160.95z"
              />
              <path
                d="M722.26,158.51h3.1v7.46h0.06c0.32-0.49,0.75-0.93,1.22-1.22c0.52-0.32,1.13-0.52,1.89-0.52c2.23,0,3.48,1.86,3.48,5.42
				v8.93h-3.1v-8.82c0-1.54-0.32-2.93-1.62-2.93c-0.81,0-1.48,0.55-1.8,1.74c-0.06,0.26-0.12,0.73-0.12,1.07v8.93h-3.1V158.51z"
              />
              <path
                d="M738.77,160.77c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.59-1.77c-0.03-1.1,0.72-1.8,1.65-1.8
				C738.07,158.97,738.77,159.67,738.77,160.77z M735.57,178.58v-14.13h3.1v14.13H735.57z"
              />
              <path
                d="M742.01,175.65c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L742.01,175.65z"
              />
              <path
                d="M764.63,178.29c-0.44,0.23-1.33,0.46-2.38,0.46c-3.36,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L764.63,178.29z"
              />
              <path
                d="M776.47,171.48c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.1,2.52-7.34,5.16-7.34
				C774.46,164.22,776.47,166.83,776.47,171.48z M769.45,171.5c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.88-2.81,1.88-4.93
				c0-2.06-0.35-4.87-1.94-4.87C769.77,166.63,769.45,169.45,769.45,171.5z"
              />
              <path
                d="M788.99,158.51v16.24c0,1.16,0.06,2.76,0.12,3.83h-2.67l-0.17-1.68h-0.09c-0.49,1.02-1.57,1.91-2.93,1.91
				c-2.76,0-4.29-2.99-4.29-7.25c0-4.96,2.15-7.34,4.44-7.34c1.16,0,2,0.55,2.46,1.54h0.06v-7.25H788.99z M785.92,169.85
				c0-0.26,0-0.58-0.06-0.87c-0.12-1.13-0.75-2.12-1.68-2.12c-1.57,0-2.12,2.2-2.12,4.73c0,2.84,0.75,4.67,2.06,4.67
				c0.58,0,1.31-0.32,1.68-1.74c0.09-0.26,0.12-0.58,0.12-0.93V169.85z"
              />
              <path
                d="M795.02,172.35c0.03,3.16,1.51,4.03,3.16,4.03c1.01,0,1.88-0.23,2.52-0.52l0.43,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H795.02z
				 M798.88,170.14c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H798.88z"
              />
              <path
                d="M812.13,164.46l1.02,6.47c0.23,1.51,0.46,2.99,0.61,4.38h0.12c0.2-1.39,0.52-2.99,0.81-4.32l1.42-6.53h2.38l1.36,6.35
				c0.32,1.6,0.61,3.02,0.81,4.5h0.09c0.12-1.42,0.35-2.87,0.61-4.44l1.07-6.41h3.07l-3.6,14.13h-2.61l-1.31-5.77
				c-0.32-1.36-0.55-2.73-0.75-4.35h-0.06c-0.2,1.65-0.49,3.05-0.81,4.38l-1.42,5.74h-2.64l-3.48-14.13H812.13z"
              />
              <path
                d="M831.16,160.77c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.59-1.77c-0.03-1.1,0.72-1.8,1.65-1.8
				C830.46,158.97,831.16,159.67,831.16,160.77z M827.97,178.58v-14.13h3.1v14.13H827.97z"
              />
              <path
                d="M838.35,160.95v3.51h2.73v2.26h-2.73v7.31c0,1.74,0.67,2.18,1.45,2.18c0.35,0,0.64-0.03,0.96-0.09l0.06,2.41
				c-0.46,0.15-1.16,0.23-2.03,0.23c-0.99,0-1.89-0.26-2.5-0.87c-0.7-0.7-1.04-1.74-1.04-3.83v-7.34h-1.68v-2.26h1.68v-2.44
				L838.35,160.95z"
              />
              <path
                d="M843.65,158.51h3.1v7.46h0.06c0.32-0.49,0.75-0.93,1.22-1.22c0.52-0.32,1.13-0.52,1.88-0.52c2.23,0,3.48,1.86,3.48,5.42
				v8.93h-3.1v-8.82c0-1.54-0.32-2.93-1.62-2.93c-0.81,0-1.48,0.55-1.8,1.74c-0.06,0.26-0.12,0.73-0.12,1.07v8.93h-3.1V158.51z"
              />
              <path
                d="M860.16,160.77c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.72-1.8,1.65-1.8
				C859.46,158.97,860.16,159.67,860.16,160.77z M856.97,178.58v-14.13h3.1v14.13H856.97z"
              />
              <path
                d="M863.63,168.43c0-1.6-0.06-2.73-0.12-3.97h2.7l0.14,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.62,1.83,3.62,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V168.43z"
              />
              <path
                d="M274.9,194.12c1.02-0.23,2.26-0.38,3.65-0.38c2.06,0,3.71,0.38,4.87,1.48c1.04,0.99,1.57,2.49,1.57,4.18
				c0,1.97-0.55,3.34-1.45,4.38c-1.16,1.36-3.05,2-4.7,2c-0.32,0-0.58,0-0.87,0v7.6h-3.07V194.12z M277.98,203.23
				c0.23,0.06,0.46,0.06,0.75,0.06c2.03,0,3.16-1.39,3.16-3.66c0-1.83-0.75-3.36-2.84-3.36c-0.41,0-0.81,0.06-1.07,0.15V203.23z"
              />
              <path
                d="M296.74,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C294.74,199.02,296.74,201.63,296.74,206.28z M289.72,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C290.04,201.43,289.72,204.25,289.72,206.31z"
              />
              <path
                d="M302.86,205.61h0.06c0.26-0.7,0.49-1.25,0.73-1.8l2.26-4.55h3.36l-3.57,5.92l3.8,8.21h-3.45l-2.47-6.18l-0.73,1.25v4.93
				h-3.07v-20.07h3.07V205.61z"
              />
              <path
                d="M313.79,207.15c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H313.79z
				 M317.65,204.94c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H317.65z M319.77,193.48l-2.99,4h-1.97l2.09-4H319.77z"
              />
              <path
                d="M323.48,203.23c0-1.6-0.06-2.73-0.12-3.97h2.7l0.17,1.68h0.06c0.67-1.07,1.8-1.91,3.28-1.91c1.45,0,2.52,0.7,3.02,2.03
				h0.06c0.38-0.61,0.87-1.16,1.42-1.51c0.58-0.35,1.25-0.52,1.97-0.52c2.23,0,3.54,1.74,3.54,5.22v9.14h-3.07v-8.56
				c0-1.71-0.23-3.22-1.62-3.22c-0.78,0-1.42,0.61-1.71,1.65c-0.06,0.29-0.09,0.7-0.09,1.13v8.99h-3.1v-9.02
				c0-1.28-0.17-2.76-1.57-2.76c-0.73,0-1.45,0.58-1.71,1.74c-0.09,0.29-0.15,0.7-0.15,1.1v8.93h-3.07V203.23z"
              />
              <path
                d="M352.71,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C350.71,199.02,352.71,201.63,352.71,206.28z M345.69,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C346.01,201.43,345.69,204.25,345.69,206.31z"
              />
              <path
                d="M355.76,203.23c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.1
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V203.23z"
              />
              <path d="M376.2,196.67h-3.51v-2.84h10.15v2.84h-3.54v16.71h-3.1V196.67z" />
              <path
                d="M394.04,213.06c-0.58,0.29-1.6,0.49-2.87,0.49c-4.47,0-7.02-3.65-7.02-9.69c0-7.51,3.97-10.21,7.46-10.21
				c1.28,0,2.15,0.26,2.55,0.49l-0.58,2.61c-0.46-0.2-0.93-0.38-1.86-0.38c-2.12,0-4.29,1.94-4.29,7.28c0,5.25,1.97,7.14,4.32,7.14
				c0.73,0,1.45-0.15,1.91-0.35L394.04,213.06z"
              />
              <path
                d="M406.83,212.86c-0.84,0.35-2.47,0.7-3.8,0.7c-2,0-3.65-0.55-4.96-1.91c-1.54-1.6-2.47-4.38-2.44-7.86
				c0.06-7.34,4.06-10.12,7.89-10.12c1.36,0,2.41,0.26,3.02,0.58l-0.61,2.67c-0.52-0.29-1.25-0.46-2.23-0.46
				c-2.52,0-4.84,1.86-4.84,7.37c0,5.31,2.03,7.11,4.06,7.11c0.52,0,0.87-0.06,1.04-0.15v-5.48h-2.09v-2.52h4.96V212.86z"
              />
              <path d="M415.78,193.83h3.1v16.94h5.22v2.61h-8.32V193.83z" />
              <path
                d="M429.97,195.57c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C429.27,193.77,429.97,194.47,429.97,195.57z M426.78,213.38v-14.13h3.1v14.13H426.78z"
              />
              <path
                d="M435.53,199.26l1.39,7.05c0.2,1.1,0.41,2.38,0.61,3.71h0.06c0.17-1.31,0.38-2.49,0.58-3.71l1.36-7.05h3.07l-3.74,14.13
				h-2.81l-3.8-14.13H435.53z"
              />
              <path
                d="M447.08,207.15c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H447.08z
				 M450.93,204.94c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H450.93z"
              />
              <path
                d="M465.78,195.75v3.51h2.73v2.26h-2.73v7.31c0,1.74,0.67,2.18,1.45,2.18c0.35,0,0.64-0.03,0.96-0.09l0.06,2.41
				c-0.46,0.15-1.16,0.23-2.03,0.23c-0.99,0-1.89-0.26-2.49-0.87c-0.7-0.7-1.04-1.74-1.04-3.83v-7.34h-1.68v-2.26h1.68v-2.44
				L465.78,195.75z"
              />
              <path
                d="M480.46,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C478.45,199.02,480.46,201.63,480.46,206.28z M473.44,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C473.76,201.43,473.44,204.25,473.44,206.31z"
              />
              <path
                d="M491.27,207.15c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H491.27z
				 M495.13,204.94c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H495.13z"
              />
              <path
                d="M502.96,199.26l1.04,2.76c0.32,0.84,0.58,1.65,0.81,2.41h0.06c0.26-0.78,0.52-1.62,0.78-2.41l1.04-2.76h3.13l-3.28,6.96
				l3.28,7.16h-3.36l-1.13-2.9c-0.29-0.73-0.52-1.45-0.78-2.26h-0.06c-0.23,0.75-0.52,1.51-0.78,2.26l-1.04,2.9h-3.13l3.25-7.05
				l-3.19-7.08H502.96z"
              />
              <path
                d="M512.18,203.69c0-1.89-0.09-3.25-0.12-4.44h2.7l0.17,1.71h0.06c0.67-1.31,1.74-1.94,2.96-1.94
				c2.81,0,4.24,3.05,4.24,7.28c0,5.1-2.18,7.31-4.47,7.31c-1.25,0-2-0.75-2.41-1.48h-0.06v6.82h-3.07V203.69z M515.25,208.22
				c0,0.44,0.06,0.87,0.15,1.19c0.38,1.36,1.1,1.65,1.6,1.65c1.57,0,2.12-2.23,2.12-4.73c0-2.78-0.67-4.67-2.09-4.67
				c-0.78,0-1.48,0.87-1.65,1.8c-0.09,0.32-0.12,0.67-0.12,1.04V208.22z"
              />
              <path
                d="M533.84,210.16c0,1.1,0.03,2.29,0.17,3.22h-2.78l-0.17-1.45H531c-0.73,1.1-1.77,1.68-2.99,1.68
				c-2.03,0-3.57-1.71-3.57-4.18c0-3.63,3.07-5.16,6.27-5.19v-0.38c0-1.45-0.58-2.49-2.12-2.49c-1.02,0-1.86,0.29-2.64,0.78
				l-0.58-2.12c0.7-0.44,2.18-1.02,3.89-1.02c3.65,0,4.58,2.49,4.58,5.51V210.16z M530.8,206.31c-1.22,0.03-3.39,0.35-3.39,2.81
				c0,1.6,0.84,2.18,1.54,2.18c0.75,0,1.51-0.52,1.77-1.62c0.06-0.2,0.09-0.46,0.09-0.73V206.31z"
              />
              <path
                d="M537.44,203.23c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.1
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V203.23z"
              />
              <path
                d="M560.23,193.31v16.24c0,1.16,0.06,2.76,0.12,3.83h-2.67l-0.17-1.68h-0.09c-0.49,1.02-1.57,1.91-2.93,1.91
				c-2.76,0-4.29-2.99-4.29-7.25c0-4.96,2.15-7.34,4.44-7.34c1.16,0,2,0.55,2.47,1.54h0.06v-7.25H560.23z M557.16,204.65
				c0-0.26,0-0.58-0.06-0.87c-0.12-1.13-0.75-2.12-1.68-2.12c-1.57,0-2.12,2.2-2.12,4.73c0,2.84,0.75,4.67,2.06,4.67
				c0.58,0,1.31-0.32,1.68-1.74c0.09-0.26,0.12-0.58,0.12-0.93V204.65z"
              />
              <path
                d="M571.22,199.26l1.62,7.69c0.2,0.96,0.32,1.8,0.46,2.61h0.06c0.12-0.75,0.29-1.68,0.46-2.55l1.39-7.75h3.05l-2.64,10.09
				c-0.75,3.05-1.71,6.09-3.31,8.04c-1.1,1.36-2.35,2-2.9,2.2l-0.99-2.67c0.64-0.29,1.31-0.78,1.91-1.45
				c0.55-0.58,0.96-1.33,1.22-1.97c0.12-0.29,0.17-0.46,0.17-0.67c0-0.17-0.03-0.35-0.09-0.52l-3.74-13.05H571.22z"
              />
              <path
                d="M589.73,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C587.72,199.02,589.73,201.63,589.73,206.28z M582.71,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C583.03,201.43,582.71,204.25,582.71,206.31z"
              />
              <path
                d="M602.28,209.29c0,1.51,0.06,2.9,0.12,4.09h-2.67l-0.2-1.48h-0.06c-0.55,0.84-1.65,1.71-3.16,1.71
				c-2.58,0-3.6-1.94-3.6-5.63v-8.73h3.08v8.35c0,2,0.38,3.42,1.65,3.42c0.93,0,1.42-0.84,1.57-1.39c0.12-0.32,0.17-0.75,0.17-1.22
				v-9.17h3.1V209.29z"
              />
              <path
                d="M605.91,203.23c0-1.31-0.09-2.76-0.12-3.97h2.7l0.17,2.15h0.09c0.55-1.45,1.71-2.38,2.87-2.38
				c0.29,0,0.46,0.03,0.64,0.06v3.1c-0.17-0.06-0.38-0.06-0.64-0.06c-1.07,0-2.17,0.9-2.49,2.44c-0.09,0.35-0.12,0.81-0.12,1.36
				v7.46h-3.1V203.23z"
              />
              <path
                d="M629.43,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C627.42,199.02,629.43,201.63,629.43,206.28z M622.41,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C622.73,201.43,622.41,204.25,622.41,206.31z"
              />
              <path
                d="M632.47,203.23c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.1
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.14,0.75-0.14,1.28v8.73h-3.1V203.23z"
              />
              <path d="M645.78,193.31h3.1v20.07h-3.1V193.31z" />
              <path
                d="M655.79,195.57c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C655.09,193.77,655.79,194.47,655.79,195.57z M652.59,213.38v-14.13h3.1v14.13H652.59z"
              />
              <path
                d="M659.26,203.23c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.1
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V203.23z"
              />
              <path
                d="M675.04,207.15c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.43,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H675.04z
				 M678.9,204.94c0.03-2.26-0.72-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H678.9z"
              />
              <path
                d="M697.46,213.09c-0.44,0.23-1.33,0.46-2.38,0.46c-3.37,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L697.46,213.09z"
              />
              <path
                d="M708.71,210.16c0,1.1,0.03,2.29,0.17,3.22h-2.78l-0.17-1.45h-0.06c-0.72,1.1-1.77,1.68-2.99,1.68
				c-2.03,0-3.57-1.71-3.57-4.18c0-3.63,3.08-5.16,6.27-5.19v-0.38c0-1.45-0.58-2.49-2.12-2.49c-1.02,0-1.86,0.29-2.64,0.78
				l-0.58-2.12c0.7-0.44,2.18-1.02,3.89-1.02c3.66,0,4.58,2.49,4.58,5.51V210.16z M705.66,206.31c-1.22,0.03-3.39,0.35-3.39,2.81
				c0,1.6,0.84,2.18,1.54,2.18c0.75,0,1.51-0.52,1.77-1.62c0.06-0.2,0.09-0.46,0.09-0.73V206.31z"
              />
              <path
                d="M712.3,203.23c0-1.31-0.09-2.76-0.12-3.97h2.7l0.17,2.15h0.09c0.55-1.45,1.71-2.38,2.87-2.38c0.29,0,0.46,0.03,0.64,0.06
				v3.1c-0.17-0.06-0.38-0.06-0.64-0.06c-1.07,0-2.18,0.9-2.5,2.44c-0.09,0.35-0.12,0.81-0.12,1.36v7.46h-3.1V203.23z"
              />
              <path
                d="M730.17,193.31v16.24c0,1.16,0.06,2.76,0.12,3.83h-2.67l-0.17-1.68h-0.09c-0.49,1.02-1.57,1.91-2.93,1.91
				c-2.75,0-4.29-2.99-4.29-7.25c0-4.96,2.15-7.34,4.44-7.34c1.16,0,2,0.55,2.47,1.54h0.06v-7.25H730.17z M727.09,204.65
				c0-0.26,0-0.58-0.06-0.87c-0.12-1.13-0.75-2.12-1.68-2.12c-1.57,0-2.12,2.2-2.12,4.73c0,2.84,0.75,4.67,2.06,4.67
				c0.58,0,1.31-0.32,1.68-1.74c0.09-0.26,0.12-0.58,0.12-0.93V204.65z"
              />
              <path
                d="M746.47,213.09c-0.44,0.23-1.33,0.46-2.38,0.46c-3.37,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L746.47,213.09z"
              />
              <path
                d="M758.3,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C756.3,199.02,758.3,201.63,758.3,206.28z M751.28,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C751.6,201.43,751.28,204.25,751.28,206.31z"
              />
              <path d="M761.34,193.31h3.1v20.07h-3.1V193.31z" />
              <path d="M768.16,193.31h3.1v20.07h-3.1V193.31z" />
              <path
                d="M777.44,207.15c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H777.44z
				 M781.29,204.94c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H781.29z"
              />
              <path
                d="M794.55,213.09c-0.43,0.23-1.33,0.46-2.38,0.46c-3.37,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L794.55,213.09z"
              />
              <path
                d="M801.13,195.75v3.51h2.73v2.26h-2.73v7.31c0,1.74,0.67,2.18,1.45,2.18c0.35,0,0.64-0.03,0.96-0.09l0.06,2.41
				c-0.46,0.15-1.16,0.23-2.03,0.23c-0.99,0-1.89-0.26-2.5-0.87c-0.7-0.7-1.04-1.74-1.04-3.83v-7.34h-1.68v-2.26h1.68v-2.44
				L801.13,195.75z"
              />
              <path
                d="M809.63,195.57c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C808.93,193.77,809.63,194.47,809.63,195.57z M806.43,213.38v-14.13h3.1v14.13H806.43z"
              />
              <path
                d="M822.68,206.28c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C820.68,199.02,822.68,201.63,822.68,206.28z M815.66,206.31c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.88-2.81,1.88-4.93
				c0-2.06-0.35-4.87-1.94-4.87C815.98,201.43,815.66,204.25,815.66,206.31z"
              />
              <path
                d="M825.72,203.23c0-1.6-0.06-2.73-0.12-3.97h2.7l0.14,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.1
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.14,0.75-0.14,1.28v8.73h-3.1V203.23z"
              />
              <path
                d="M840.86,213.61c-0.99,0-1.83-0.84-1.83-2.15c0-1.25,0.87-2.15,1.88-2.15c1.07,0,1.89,0.81,1.89,2.15
				c0,1.31-0.75,2.15-1.91,2.15H840.86z"
              />
              <path d="M398.88,231.47h-3.51v-2.84h10.15v2.84h-3.54v16.71h-3.1V231.47z" />
              <path
                d="M407.81,228.11h3.1v7.46h0.06c0.32-0.49,0.75-0.93,1.22-1.22c0.52-0.32,1.13-0.52,1.89-0.52c2.23,0,3.48,1.86,3.48,5.42
				v8.93h-3.1v-8.82c0-1.54-0.32-2.93-1.62-2.93c-0.81,0-1.48,0.55-1.8,1.74c-0.06,0.26-0.12,0.73-0.12,1.07v8.93h-3.1V228.11z"
              />
              <path
                d="M424.31,230.37c0.03,1.04-0.7,1.77-1.71,1.77c-0.9,0-1.62-0.73-1.6-1.77c-0.03-1.1,0.73-1.8,1.65-1.8
				C423.61,228.57,424.31,229.27,424.31,230.37z M421.12,248.18v-14.13h3.1v14.13H421.12z"
              />
              <path
                d="M427.56,245.25c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L427.56,245.25z"
              />
              <path
                d="M450.18,247.89c-0.44,0.23-1.33,0.46-2.38,0.46c-3.36,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L450.18,247.89z"
              />
              <path
                d="M462.01,241.08c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C460.01,233.82,462.01,236.44,462.01,241.08z M454.99,241.1c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C455.31,236.23,454.99,239.05,454.99,241.1z"
              />
              <path
                d="M474.54,228.11v16.24c0,1.16,0.06,2.76,0.12,3.83h-2.67l-0.17-1.68h-0.09c-0.49,1.02-1.57,1.91-2.93,1.91
				c-2.76,0-4.29-2.99-4.29-7.25c0-4.96,2.15-7.34,4.44-7.34c1.16,0,2,0.55,2.47,1.54h0.06v-7.25H474.54z M471.46,239.45
				c0-0.26,0-0.58-0.06-0.87c-0.12-1.13-0.75-2.12-1.68-2.12c-1.57,0-2.12,2.2-2.12,4.73c0,2.84,0.75,4.67,2.06,4.67
				c0.58,0,1.31-0.32,1.68-1.74c0.09-0.26,0.12-0.58,0.12-0.93V239.45z"
              />
              <path
                d="M480.57,241.95c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H480.57z
				 M484.43,239.74c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H484.43z"
              />
              <path
                d="M502.99,247.89c-0.44,0.23-1.33,0.46-2.38,0.46c-3.36,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L502.99,247.89z"
              />
              <path
                d="M514.24,244.96c0,1.1,0.03,2.29,0.17,3.22h-2.78l-0.17-1.45h-0.06c-0.73,1.1-1.77,1.68-2.99,1.68
				c-2.03,0-3.57-1.71-3.57-4.18c0-3.63,3.07-5.16,6.27-5.19v-0.38c0-1.45-0.58-2.49-2.12-2.49c-1.02,0-1.86,0.29-2.64,0.78
				l-0.58-2.12c0.7-0.44,2.18-1.02,3.89-1.02c3.65,0,4.58,2.49,4.58,5.51V244.96z M511.19,241.1c-1.22,0.03-3.39,0.35-3.39,2.81
				c0,1.6,0.84,2.18,1.54,2.18c0.75,0,1.51-0.52,1.77-1.62c0.06-0.2,0.09-0.46,0.09-0.73V241.1z"
              />
              <path
                d="M517.83,238.03c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V238.03z"
              />
              <path
                d="M536.45,228.11h3.07v7.48h0.06c0.7-1.19,1.68-1.77,2.96-1.77c2.49,0,3.94,2.84,3.94,7.02c0,5.02-2.03,7.57-4.47,7.57
				c-1.31,0-2.12-0.67-2.78-1.97h-0.09l-0.17,1.74h-2.64c0.03-1.04,0.12-2.67,0.12-3.83V228.11z M539.52,243.16
				c0,0.35,0.06,0.67,0.12,0.9c0.41,1.48,1.13,1.8,1.65,1.8c1.51,0,2.09-1.97,2.09-4.82c0-2.61-0.61-4.58-2.09-4.58
				c-0.81,0-1.48,0.9-1.65,1.83c-0.06,0.26-0.12,0.64-0.12,0.93V243.16z"
              />
              <path
                d="M551.96,241.95c0.03,3.16,1.51,4.03,3.16,4.03c1.02,0,1.89-0.23,2.52-0.52l0.44,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H551.96z
				 M555.82,239.74c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H555.82z"
              />
              <path
                d="M576.47,244.09c0,1.51,0.06,2.9,0.12,4.09h-2.67l-0.2-1.48h-0.06c-0.55,0.84-1.65,1.71-3.16,1.71
				c-2.58,0-3.6-1.94-3.6-5.63v-8.73h3.08v8.35c0,2,0.38,3.42,1.65,3.42c0.93,0,1.42-0.84,1.57-1.39c0.12-0.32,0.17-0.75,0.17-1.22
				v-9.17h3.1V244.09z"
              />
              <path
                d="M579.86,245.25c0.52,0.35,1.51,0.75,2.44,0.75c1.1,0,1.77-0.61,1.77-1.62c0-0.87-0.41-1.45-1.71-2.29
				c-2.09-1.25-2.87-2.61-2.87-4.03c0-2.44,1.8-4.24,4.32-4.24c1.16,0,2.03,0.32,2.73,0.7l-0.64,2.29
				c-0.55-0.35-1.19-0.61-1.94-0.61c-1.07,0-1.6,0.7-1.6,1.48c0,0.75,0.35,1.19,1.71,2.06c1.89,1.13,2.87,2.52,2.87,4.26
				c0,2.87-2,4.35-4.67,4.35c-1.1,0-2.38-0.32-3.02-0.81L579.86,245.25z"
              />
              <path
                d="M592.22,241.95c0.03,3.16,1.51,4.03,3.16,4.03c1.01,0,1.88-0.23,2.52-0.52l0.43,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H592.22z
				 M596.07,239.74c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H596.07z"
              />
              <path
                d="M611.39,228.11v16.24c0,1.16,0.06,2.76,0.12,3.83h-2.67l-0.17-1.68h-0.09c-0.49,1.02-1.57,1.91-2.93,1.91
				c-2.76,0-4.29-2.99-4.29-7.25c0-4.96,2.15-7.34,4.44-7.34c1.16,0,2,0.55,2.47,1.54h0.06v-7.25H611.39z M608.31,239.45
				c0-0.26,0-0.58-0.06-0.87c-0.12-1.13-0.75-2.12-1.68-2.12c-1.57,0-2.12,2.2-2.12,4.73c0,2.84,0.75,4.67,2.06,4.67
				c0.58,0,1.3-0.32,1.68-1.74c0.09-0.26,0.12-0.58,0.12-0.93V239.45z"
              />
              <path
                d="M629.83,241.08c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C627.83,233.82,629.83,236.44,629.83,241.08z M622.81,241.1c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C623.13,236.23,622.81,239.05,622.81,241.1z"
              />
              <path
                d="M632.87,238.03c0-1.6-0.06-2.73-0.12-3.97h2.7l0.14,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.62,1.83,3.62,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V238.03z"
              />
              <path d="M646.18,228.11h3.1v20.07h-3.1V228.11z" />
              <path
                d="M655.12,234.06l1.62,7.69c0.2,0.96,0.32,1.8,0.46,2.61h0.06c0.12-0.75,0.29-1.68,0.46-2.55l1.39-7.75h3.05l-2.64,10.09
				c-0.75,3.05-1.71,6.09-3.31,8.03c-1.1,1.36-2.35,2-2.9,2.2l-0.99-2.67c0.64-0.29,1.31-0.78,1.92-1.45
				c0.55-0.58,0.96-1.33,1.22-1.97c0.12-0.29,0.17-0.46,0.17-0.67c0-0.17-0.03-0.35-0.09-0.52l-3.74-13.05H655.12z"
              />
              <path
                d="M679.33,241.08c0,5.71-2.93,7.34-5.08,7.34c-2.99,0-5.05-2.49-5.05-7.25c0-5.11,2.52-7.34,5.16-7.34
				C677.33,233.82,679.33,236.44,679.33,241.08z M672.31,241.1c0,2.38,0.41,4.93,2.06,4.93c1.54,0,1.89-2.81,1.89-4.93
				c0-2.06-0.35-4.87-1.94-4.87C672.63,236.23,672.31,239.05,672.31,241.1z"
              />
              <path
                d="M682.37,238.03c0-1.6-0.06-2.73-0.12-3.97h2.7l0.15,1.65h0.06c0.67-1.13,1.86-1.89,3.34-1.89c2.38,0,3.63,1.83,3.63,5.11
				v9.25h-3.1v-8.96c0-1.39-0.29-2.78-1.65-2.78c-0.75,0-1.45,0.58-1.74,1.74c-0.09,0.29-0.15,0.75-0.15,1.28v8.73h-3.1V238.03z"
              />
              <path
                d="M703.11,247.89c-0.43,0.23-1.33,0.46-2.38,0.46c-3.36,0-5.6-2.49-5.6-7.14c0-4.21,2.35-7.37,6.09-7.37
				c0.84,0,1.65,0.2,2.03,0.41l-0.46,2.47c-0.32-0.12-0.81-0.32-1.51-0.32c-2,0-3.05,2.18-3.05,4.76c0,2.9,1.16,4.64,3.05,4.64
				c0.58,0,1.07-0.09,1.51-0.32L703.11,247.89z"
              />
              <path
                d="M707.84,241.95c0.03,3.16,1.51,4.03,3.16,4.03c1.01,0,1.88-0.23,2.52-0.52l0.43,2.23c-0.87,0.41-2.23,0.67-3.54,0.67
				c-3.63,0-5.6-2.64-5.6-7.02c0-4.67,2.26-7.51,5.28-7.51c2.96,0,4.47,2.7,4.47,6.47c0,0.81-0.06,1.28-0.09,1.65H707.84z
				 M711.69,239.74c0.03-2.26-0.73-3.6-1.83-3.6c-1.33,0-1.94,1.94-2,3.6H711.69z"
              />
              <path
                d="M719.06,248.42c-0.99,0-1.83-0.84-1.83-2.15c0-1.25,0.87-2.15,1.89-2.15c1.07,0,1.88,0.81,1.88,2.15
				c0,1.31-0.75,2.15-1.91,2.15H719.06z"
              />
            </g>
            <g>
              <path
                d="M234.91,279.94c3.74,0,6.69,3.04,6.69,6.78c0,3.78-2.95,6.82-6.71,6.82c-3.78,0-6.78-3.04-6.78-6.82
				c0-3.74,2.99-6.78,6.78-6.78H234.91z M234.85,281.02c-3.06,0-5.46,2.55-5.46,5.74c0,3.17,2.4,5.68,5.48,5.7
				c3.06,0.02,5.46-2.53,5.46-5.72c0-3.17-2.4-5.72-5.46-5.72H234.85z M237.55,284.36c-0.31-0.13-1.08-0.46-2.09-0.46
				c-1.85,0-2.93,1.17-2.93,2.9c0,1.67,1.08,2.88,2.88,2.88c0.86,0,1.63-0.22,2.13-0.48l0.29,1.01c-0.77,0.44-1.63,0.62-2.66,0.62
				c-2.53,0-4-1.76-4-3.98c0-2.53,1.89-4.09,4.31-4.09c1.06,0,2.07,0.33,2.35,0.55L237.55,284.36z"
              />
              <path
                d="M242.81,294.2v-1.56l1.32-1.74c1.78-2.35,3.52-4.64,3.52-6.98c0-1.34-0.66-2.22-1.91-2.22c-0.97,0-1.67,0.46-2.11,0.75
				l-0.46-1.8c0.57-0.42,1.69-0.92,2.99-0.92c2.79,0,3.83,1.94,3.83,4.05c0,2.68-1.72,5.02-3.43,7.17l-0.9,1.14v0.04h4.55v2.07
				H242.81z"
              />
              <path
                d="M259.48,287c0,5.17-1.78,7.37-3.98,7.37c-2.71,0-3.89-3.17-3.89-7.28c0-4.42,1.36-7.37,4.05-7.37
				C258.52,279.72,259.48,283.09,259.48,287z M253.96,287.05c-0.02,3.39,0.46,5.32,1.65,5.32c1.08,0,1.52-1.98,1.52-5.32
				c0-3.19-0.42-5.32-1.54-5.32C254.49,281.72,253.96,283.68,253.96,287.05z"
              />
              <path
                d="M260.54,294.2v-1.56l1.32-1.74c1.78-2.35,3.52-4.64,3.52-6.98c0-1.34-0.66-2.22-1.91-2.22c-0.97,0-1.67,0.46-2.11,0.75
				l-0.46-1.8c0.57-0.42,1.69-0.92,2.99-0.92c2.79,0,3.83,1.94,3.83,4.05c0,2.68-1.72,5.02-3.43,7.17l-0.9,1.14v0.04h4.55v2.07
				H260.54z"
              />
              <path
                d="M269.4,294.2v-1.56l1.32-1.74c1.78-2.35,3.52-4.64,3.52-6.98c0-1.34-0.66-2.22-1.91-2.22c-0.97,0-1.67,0.46-2.11,0.75
				l-0.46-1.8c0.57-0.42,1.69-0.92,2.99-0.92c2.79,0,3.83,1.94,3.83,4.05c0,2.68-1.72,5.02-3.43,7.17l-0.9,1.14v0.04h4.55v2.07
				H269.4z"
              />
              <path
                d="M282.32,279.59c0.77-0.18,1.72-0.29,2.77-0.29c1.56,0,2.82,0.29,3.7,1.12c0.79,0.75,1.19,1.89,1.19,3.17
				c0,1.5-0.42,2.53-1.1,3.32c-0.88,1.03-2.31,1.52-3.56,1.52c-0.24,0-0.44,0-0.66,0v5.76h-2.33V279.59z M284.65,286.5
				c0.18,0.04,0.35,0.04,0.57,0.04c1.54,0,2.4-1.06,2.4-2.77c0-1.39-0.57-2.55-2.16-2.55c-0.31,0-0.62,0.04-0.81,0.11V286.5z"
              />
              <path
                d="M298.23,288.81c0,4.33-2.22,5.57-3.85,5.57c-2.27,0-3.83-1.89-3.83-5.5c0-3.87,1.91-5.57,3.92-5.57
				C296.71,283.31,298.23,285.29,298.23,288.81z M292.9,288.83c0,1.8,0.31,3.74,1.56,3.74c1.17,0,1.43-2.13,1.43-3.74
				c0-1.56-0.26-3.7-1.47-3.7C293.14,285.13,292.9,287.27,292.9,288.83z"
              />
              <path
                d="M302.21,288.3h0.04c0.2-0.53,0.37-0.95,0.55-1.36l1.72-3.45h2.55l-2.71,4.49l2.88,6.23h-2.62l-1.87-4.69l-0.55,0.95v3.74
				h-2.33v-15.23h2.33V288.3z"
              />
              <path
                d="M309.84,289.47c0.02,2.4,1.14,3.06,2.4,3.06c0.77,0,1.43-0.18,1.91-0.4l0.33,1.69c-0.66,0.31-1.69,0.51-2.68,0.51
				c-2.75,0-4.25-2-4.25-5.32c0-3.54,1.72-5.7,4-5.7c2.24,0,3.39,2.05,3.39,4.91c0,0.62-0.04,0.97-0.07,1.25H309.84z M312.77,287.79
				c0.02-1.72-0.55-2.73-1.39-2.73c-1.01,0-1.47,1.47-1.52,2.73H312.77z M314.37,279.1l-2.27,3.04h-1.5l1.58-3.04H314.37z"
              />
              <path
                d="M316.53,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.13,1.28h0.04c0.51-0.81,1.36-1.45,2.49-1.45c1.1,0,1.91,0.53,2.29,1.54
				h0.04c0.29-0.46,0.66-0.88,1.08-1.14c0.44-0.26,0.95-0.4,1.5-0.4c1.69,0,2.68,1.32,2.68,3.96v6.93h-2.33v-6.49
				c0-1.3-0.18-2.44-1.23-2.44c-0.59,0-1.08,0.46-1.3,1.25c-0.04,0.22-0.07,0.53-0.07,0.86v6.82h-2.35v-6.84
				c0-0.97-0.13-2.09-1.19-2.09c-0.55,0-1.1,0.44-1.3,1.32c-0.07,0.22-0.11,0.53-0.11,0.84v6.78h-2.33V286.5z"
              />
              <path
                d="M338.05,288.81c0,4.33-2.22,5.57-3.85,5.57c-2.27,0-3.83-1.89-3.83-5.5c0-3.87,1.91-5.57,3.92-5.57
				C336.53,283.31,338.05,285.29,338.05,288.81z M332.72,288.83c0,1.8,0.31,3.74,1.56,3.74c1.17,0,1.43-2.13,1.43-3.74
				c0-1.56-0.26-3.7-1.47-3.7C332.96,285.13,332.72,287.27,332.72,288.83z"
              />
              <path
                d="M339.69,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M350.52,294.37c-0.75,0-1.39-0.64-1.39-1.63c0-0.95,0.66-1.63,1.43-1.63c0.81,0,1.43,0.62,1.43,1.63
				c0,0.99-0.57,1.63-1.45,1.63H350.52z"
              />
              <path
                d="M363.56,279.94c3.74,0,6.69,3.04,6.69,6.78c0,3.78-2.95,6.82-6.71,6.82c-3.78,0-6.78-3.04-6.78-6.82
				c0-3.74,2.99-6.78,6.78-6.78H363.56z M363.5,281.02c-3.06,0-5.46,2.55-5.46,5.74c0,3.17,2.4,5.68,5.48,5.7
				c3.06,0.02,5.46-2.53,5.46-5.72c0-3.17-2.4-5.72-5.46-5.72H363.5z M366.2,284.36c-0.31-0.13-1.08-0.46-2.09-0.46
				c-1.85,0-2.93,1.17-2.93,2.9c0,1.67,1.08,2.88,2.88,2.88c0.86,0,1.63-0.22,2.13-0.48l0.29,1.01c-0.77,0.44-1.63,0.62-2.66,0.62
				c-2.53,0-4-1.76-4-3.98c0-2.53,1.89-4.09,4.31-4.09c1.06,0,2.07,0.33,2.35,0.55L366.2,284.36z"
              />
              <path d="M374.87,282.16h-0.04l-2.4,1.3l-0.42-1.83l3.17-1.74h2v14.3h-2.31V282.16z" />
              <path
                d="M381.27,292.39c0.46,0,0.77-0.02,1.21-0.11c0.62-0.15,1.28-0.48,1.78-0.99c0.7-0.7,1.28-1.87,1.52-3.41h-0.07
				c-0.48,0.59-1.19,1.03-2.16,1.03c-1.83,0-3.26-1.65-3.26-4.27c0-2.51,1.45-4.93,3.96-4.93c2.57,0,3.85,2.49,3.85,5.81
				c0,3.06-0.9,5.3-2.16,6.78c-0.86,1.06-2.09,1.74-3.41,1.98c-0.46,0.09-0.88,0.09-1.28,0.09V292.39z M384.11,281.46
				c-0.77,0-1.52,0.95-1.5,3.01c0.02,1.69,0.62,2.77,1.61,2.77c0.59,0,1.14-0.42,1.41-0.97c0.09-0.26,0.13-0.53,0.13-1.25
				c0-2.4-0.68-3.56-1.63-3.56H384.11z"
              />
              <path
                d="M390.14,292.39c0.46,0,0.77-0.02,1.21-0.11c0.62-0.15,1.28-0.48,1.78-0.99c0.7-0.7,1.28-1.87,1.52-3.41h-0.07
				c-0.48,0.59-1.19,1.03-2.16,1.03c-1.83,0-3.26-1.65-3.26-4.27c0-2.51,1.45-4.93,3.96-4.93c2.57,0,3.85,2.49,3.85,5.81
				c0,3.06-0.9,5.3-2.16,6.78c-0.86,1.06-2.09,1.74-3.41,1.98c-0.46,0.09-0.88,0.09-1.28,0.09V292.39z M392.98,281.46
				c-0.77,0-1.52,0.95-1.5,3.01c0.02,1.69,0.62,2.77,1.61,2.77c0.59,0,1.14-0.42,1.41-0.97c0.09-0.26,0.13-0.53,0.13-1.25
				c0-2.4-0.68-3.56-1.63-3.56H392.98z"
              />
              <path
                d="M405.17,281.94h-3.83l-0.44,3.12c0.15-0.02,0.29-0.02,0.48-0.02c0.68,0,1.89,0.31,2.68,1.08
				c0.79,0.75,1.23,1.83,1.23,3.28c0,2.79-1.83,4.97-4.69,4.97c-0.95,0-2-0.24-2.57-0.59l0.42-1.87c0.48,0.26,1.23,0.53,2.02,0.53
				c1.43,0,2.51-0.99,2.51-2.82c0-2.07-1.43-2.86-3.17-2.86c-0.46,0-0.79,0.02-1.08,0.07l0.86-6.93h5.57V281.94z"
              />
              <path d="M406.27,289.28v-1.56h12.24v1.56H406.27z" />
              <path
                d="M419.05,294.2v-1.56l1.32-1.74c1.78-2.35,3.52-4.64,3.52-6.98c0-1.34-0.66-2.22-1.91-2.22c-0.97,0-1.67,0.46-2.11,0.75
				l-0.46-1.8c0.57-0.42,1.69-0.92,2.99-0.92c2.79,0,3.83,1.94,3.83,4.05c0,2.68-1.72,5.02-3.43,7.17l-0.9,1.14v0.04h4.55v2.07
				H419.05z"
              />
              <path
                d="M435.73,287c0,5.17-1.78,7.37-3.98,7.37c-2.71,0-3.89-3.17-3.89-7.28c0-4.42,1.36-7.37,4.05-7.37
				C434.76,279.72,435.73,283.09,435.73,287z M430.2,287.05c-0.02,3.39,0.46,5.32,1.65,5.32c1.08,0,1.52-1.98,1.52-5.32
				c0-3.19-0.42-5.32-1.54-5.32C430.73,281.72,430.2,283.68,430.2,287.05z"
              />
              <path
                d="M436.78,294.2v-1.56l1.32-1.74c1.78-2.35,3.52-4.64,3.52-6.98c0-1.34-0.66-2.22-1.91-2.22c-0.97,0-1.67,0.46-2.11,0.75
				l-0.46-1.8c0.57-0.42,1.69-0.92,2.99-0.92c2.79,0,3.83,1.94,3.83,4.05c0,2.68-1.72,5.02-3.43,7.17l-0.9,1.14v0.04h4.55v2.07
				H436.78z"
              />
              <path
                d="M445.65,294.2v-1.56l1.32-1.74c1.78-2.35,3.52-4.64,3.52-6.98c0-1.34-0.66-2.22-1.91-2.22c-0.97,0-1.67,0.46-2.11,0.75
				l-0.46-1.8c0.57-0.42,1.69-0.92,2.99-0.92c2.79,0,3.83,1.94,3.83,4.05c0,2.68-1.72,5.02-3.43,7.17l-0.9,1.14v0.04h4.55v2.07
				H445.65z"
              />
              <path
                d="M458.56,294.2v-14.83h2.18l2.49,6.34c0.51,1.34,1.25,3.26,1.72,4.69h0.04c-0.11-1.89-0.24-4.18-0.24-6.84v-4.18h2.07
				v14.83h-2.18l-2.51-6.31c-0.53-1.36-1.19-3.15-1.61-4.71h-0.04c0.09,1.72,0.18,3.81,0.18,6.89v4.14H458.56z"
              />
              <path
                d="M471.5,280.69c0.02,0.79-0.53,1.34-1.3,1.34c-0.68,0-1.23-0.55-1.21-1.34c-0.02-0.84,0.55-1.36,1.25-1.36
				C470.97,279.32,471.5,279.85,471.5,280.69z M469.08,294.2v-10.72h2.35v10.72H469.08z"
              />
              <path
                d="M473.48,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M485.64,280.82v2.66h2.07v1.72h-2.07v5.54c0,1.32,0.51,1.65,1.1,1.65c0.26,0,0.48-0.02,0.73-0.07l0.04,1.83
				c-0.35,0.11-0.88,0.18-1.54,0.18c-0.75,0-1.43-0.2-1.89-0.66c-0.53-0.53-0.79-1.32-0.79-2.9v-5.57h-1.28v-1.72h1.28v-1.85
				L485.64,280.82z"
              />
              <path
                d="M490.72,289.47c0.02,2.4,1.14,3.06,2.4,3.06c0.77,0,1.43-0.18,1.91-0.4l0.33,1.69c-0.66,0.31-1.69,0.51-2.68,0.51
				c-2.75,0-4.25-2-4.25-5.32c0-3.54,1.72-5.7,4-5.7c2.24,0,3.39,2.05,3.39,4.91c0,0.62-0.04,0.97-0.07,1.25H490.72z M493.65,287.79
				c0.02-1.72-0.55-2.73-1.39-2.73c-1.01,0-1.47,1.47-1.52,2.73H493.65z"
              />
              <path
                d="M497.41,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M514.04,278.97v12.32c0,0.88,0.04,2.09,0.09,2.9h-2.02l-0.13-1.28h-0.07c-0.37,0.77-1.19,1.45-2.22,1.45
				c-2.09,0-3.26-2.27-3.26-5.5c0-3.76,1.63-5.57,3.37-5.57c0.88,0,1.52,0.42,1.87,1.17h0.04v-5.5H514.04z M511.71,287.57
				c0-0.2,0-0.44-0.04-0.66c-0.09-0.86-0.57-1.61-1.28-1.61c-1.19,0-1.61,1.67-1.61,3.59c0,2.16,0.57,3.54,1.56,3.54
				c0.44,0,0.99-0.24,1.28-1.32c0.07-0.2,0.09-0.44,0.09-0.7V287.57z"
              />
              <path
                d="M523.35,288.81c0,4.33-2.22,5.57-3.85,5.57c-2.27,0-3.83-1.89-3.83-5.5c0-3.87,1.91-5.57,3.92-5.57
				C521.83,283.31,523.35,285.29,523.35,288.81z M518.03,288.83c0,1.8,0.31,3.74,1.56,3.74c1.17,0,1.43-2.13,1.43-3.74
				c0-1.56-0.26-3.7-1.47-3.7C518.27,285.13,518.03,287.27,518.03,288.83z"
              />
              <path d="M527.09,295.08l4.97-15.89h1.61l-4.97,15.89H527.09z" />
              <path
                d="M544.82,293.96c-0.44,0.22-1.21,0.37-2.18,0.37c-3.39,0-5.32-2.77-5.32-7.35c0-5.7,3.01-7.75,5.65-7.75
				c0.97,0,1.63,0.2,1.94,0.37l-0.44,1.98c-0.35-0.15-0.7-0.29-1.41-0.29c-1.61,0-3.26,1.47-3.26,5.52c0,3.98,1.5,5.41,3.28,5.41
				c0.55,0,1.1-0.11,1.45-0.26L544.82,293.96z"
              />
              <path
                d="M546.14,286.5c0-0.99-0.07-2.09-0.09-3.01h2.05l0.13,1.63h0.07c0.42-1.1,1.3-1.8,2.18-1.8c0.22,0,0.35,0.02,0.48,0.04
				v2.35c-0.13-0.04-0.29-0.04-0.48-0.04c-0.81,0-1.65,0.68-1.89,1.85c-0.07,0.26-0.09,0.62-0.09,1.03v5.65h-2.35V286.5z"
              />
              <path
                d="M553.71,289.47c0.02,2.4,1.14,3.06,2.4,3.06c0.77,0,1.43-0.18,1.91-0.4l0.33,1.69c-0.66,0.31-1.69,0.51-2.68,0.51
				c-2.75,0-4.25-2-4.25-5.32c0-3.54,1.72-5.7,4-5.7c2.24,0,3.39,2.05,3.39,4.91c0,0.62-0.04,0.97-0.07,1.25H553.71z M556.64,287.79
				c0.02-1.72-0.55-2.73-1.39-2.73c-1.01,0-1.47,1.47-1.52,2.73H556.64z"
              />
              <path
                d="M566.93,291.75c0,0.84,0.02,1.74,0.13,2.44h-2.11l-0.13-1.1h-0.04c-0.55,0.84-1.34,1.28-2.27,1.28
				c-1.54,0-2.71-1.3-2.71-3.17c0-2.75,2.33-3.92,4.75-3.94v-0.29c0-1.1-0.44-1.89-1.61-1.89c-0.77,0-1.41,0.22-2,0.59l-0.44-1.61
				c0.53-0.33,1.65-0.77,2.95-0.77c2.77,0,3.48,1.89,3.48,4.18V291.75z M564.62,288.83c-0.92,0.02-2.57,0.26-2.57,2.13
				c0,1.21,0.64,1.65,1.17,1.65c0.57,0,1.14-0.4,1.34-1.23c0.04-0.15,0.07-0.35,0.07-0.55V288.83z"
              />
              <path
                d="M571.6,280.82v2.66h2.07v1.72h-2.07v5.54c0,1.32,0.51,1.65,1.1,1.65c0.26,0,0.48-0.02,0.73-0.07l0.04,1.83
				c-0.35,0.11-0.88,0.18-1.54,0.18c-0.75,0-1.43-0.2-1.89-0.66c-0.53-0.53-0.79-1.32-0.79-2.9v-5.57h-1.28v-1.72h1.28v-1.85
				L571.6,280.82z"
              />
              <path
                d="M582.18,291.09c0,1.14,0.04,2.2,0.09,3.1h-2.02l-0.15-1.12h-0.04c-0.42,0.64-1.25,1.3-2.4,1.3
				c-1.96,0-2.73-1.47-2.73-4.27v-6.62h2.33v6.34c0,1.52,0.29,2.6,1.25,2.6c0.7,0,1.08-0.64,1.19-1.06
				c0.09-0.24,0.13-0.57,0.13-0.92v-6.95h2.35V291.09z"
              />
              <path
                d="M584.27,286.5c0-0.99-0.07-2.09-0.09-3.01h2.05l0.13,1.63h0.07c0.42-1.1,1.3-1.8,2.18-1.8c0.22,0,0.35,0.02,0.48,0.04
				v2.35c-0.13-0.04-0.29-0.04-0.48-0.04c-0.81,0-1.65,0.68-1.89,1.85c-0.07,0.26-0.09,0.62-0.09,1.03v5.65h-2.35V286.5z"
              />
              <path
                d="M591.83,289.47c0.02,2.4,1.14,3.06,2.4,3.06c0.77,0,1.43-0.18,1.91-0.4l0.33,1.69c-0.66,0.31-1.69,0.51-2.68,0.51
				c-2.75,0-4.25-2-4.25-5.32c0-3.54,1.72-5.7,4-5.7c2.25,0,3.39,2.05,3.39,4.91c0,0.62-0.04,0.97-0.07,1.25H591.83z M594.76,287.79
				c0.02-1.72-0.55-2.73-1.39-2.73c-1.01,0-1.47,1.47-1.52,2.73H594.76z"
              />
              <path
                d="M598.35,291.98c0.4,0.26,1.14,0.57,1.85,0.57c0.84,0,1.34-0.46,1.34-1.23c0-0.66-0.31-1.1-1.3-1.74
				c-1.58-0.95-2.18-1.98-2.18-3.06c0-1.85,1.36-3.21,3.28-3.21c0.88,0,1.54,0.24,2.07,0.53l-0.48,1.74
				c-0.42-0.26-0.9-0.46-1.47-0.46c-0.81,0-1.21,0.53-1.21,1.12c0,0.57,0.26,0.9,1.3,1.56c1.43,0.86,2.18,1.91,2.18,3.23
				c0,2.18-1.52,3.3-3.54,3.3c-0.84,0-1.8-0.24-2.29-0.62L598.35,291.98z"
              />
              <path d="M611.13,279.37v14.83h-2.35v-14.83H611.13z" />
              <path
                d="M613.42,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M628.49,293.98c-0.33,0.18-1.01,0.35-1.8,0.35c-2.55,0-4.25-1.89-4.25-5.41c0-3.19,1.78-5.59,4.62-5.59
				c0.64,0,1.25,0.15,1.54,0.31l-0.35,1.87c-0.24-0.09-0.62-0.24-1.14-0.24c-1.52,0-2.31,1.65-2.31,3.61c0,2.2,0.88,3.52,2.31,3.52
				c0.44,0,0.81-0.07,1.14-0.24L628.49,293.98z"
              />
              <path
                d="M631.02,294.37c-0.75,0-1.39-0.64-1.39-1.63c0-0.95,0.66-1.63,1.43-1.63c0.81,0,1.43,0.62,1.43,1.63
				c0,0.99-0.57,1.63-1.45,1.63H631.02z"
              />
              <path d="M636.36,295.08l4.97-15.89h1.61l-4.97,15.89H636.36z" />
              <path
                d="M655.08,293.8c-0.64,0.26-1.87,0.53-2.88,0.53c-1.52,0-2.77-0.42-3.76-1.45c-1.17-1.21-1.87-3.32-1.85-5.96
				c0.04-5.57,3.08-7.68,5.99-7.68c1.03,0,1.83,0.2,2.29,0.44l-0.46,2.02c-0.4-0.22-0.95-0.35-1.69-0.35
				c-1.91,0-3.67,1.41-3.67,5.59c0,4.03,1.54,5.39,3.08,5.39c0.4,0,0.66-0.04,0.79-0.11v-4.16h-1.58v-1.91h3.76V293.8z"
              />
              <path
                d="M659.29,290.32l-0.79,3.87h-2.29l3.3-14.83h2.75l3.06,14.83h-2.29l-0.79-3.87H659.29z M661.99,288.5l-0.62-3.41
				c-0.18-0.97-0.37-2.33-0.55-3.37h-0.07c-0.15,1.03-0.37,2.44-0.55,3.37l-0.66,3.41H661.99z"
              />
              <path
                d="M675.87,288.48c-0.09-1.67-0.18-3.7-0.15-5.32h-0.07c-0.29,1.63-0.68,3.41-1.03,4.84l-1.54,6.03h-1.61l-1.45-5.88
				c-0.4-1.5-0.79-3.34-1.06-4.99h-0.07c-0.02,1.67-0.11,3.63-0.2,5.39l-0.26,5.65h-2.05l0.99-14.83h2.49l1.43,6.01
				c0.46,1.91,0.84,3.48,1.12,5.15h0.04c0.26-1.67,0.59-3.21,1.03-5.15l1.39-6.01h2.53l0.88,14.83h-2.13L675.87,288.48z"
              />
              <path d="M686.26,287.44h-3.52v4.71h4v2.05h-6.36v-14.83h6.09v2.05h-3.74v4.07h3.52V287.44z" />
              <path d="M691.91,279.37h6.1v2.05h-3.74v4.42h3.5v1.96h-3.5v6.4h-2.35V279.37z" />
              <path
                d="M699.96,279.59c0.81-0.2,1.85-0.29,2.84-0.29c1.52,0,2.79,0.26,3.67,1.08c0.81,0.79,1.12,1.78,1.12,3.01
				c0,1.72-0.84,3.06-2.33,3.74v0.04c1.03,0.37,1.58,1.36,1.87,3.12c0.33,1.8,0.68,3.39,0.92,3.89h-2.44
				c-0.2-0.42-0.51-1.87-0.77-3.61c-0.29-1.91-0.84-2.55-2-2.55h-0.55v6.16h-2.33V279.59z M702.29,286.23h0.62
				c1.41,0,2.29-1.1,2.29-2.62c0-1.45-0.57-2.44-2.11-2.46c-0.29,0-0.59,0.02-0.79,0.09V286.23z"
              />
              <path d="M715.47,287.44h-3.52v4.71h4v2.05h-6.36v-14.83h6.1v2.05h-3.74v4.07h3.52V287.44z" />
              <path
                d="M719.85,290.32l-0.79,3.87h-2.29l3.3-14.83h2.75l3.06,14.83h-2.29l-0.79-3.87H719.85z M722.56,288.5l-0.62-3.41
				c-0.18-0.97-0.37-2.33-0.55-3.37h-0.07c-0.15,1.03-0.37,2.44-0.55,3.37l-0.66,3.41H722.56z"
              />
              <path
                d="M727.51,279.37h2.33v6.84h0.07c0.29-0.62,0.53-1.25,0.81-1.87l2.33-4.97h2.57l-3.45,6.4l3.54,8.43h-2.57l-2.55-6.6
				l-0.75,1.32v5.28h-2.33V279.37z"
              />
              <path
                d="M742.51,280.69c0.02,0.79-0.53,1.34-1.3,1.34c-0.68,0-1.23-0.55-1.21-1.34c-0.02-0.84,0.55-1.36,1.25-1.36
				C741.98,279.32,742.51,279.85,742.51,280.69z M740.09,294.2v-10.72h2.35v10.72H740.09z"
              />
              <path
                d="M744.49,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M759.56,293.98c-0.33,0.18-1.01,0.35-1.8,0.35c-2.55,0-4.25-1.89-4.25-5.41c0-3.19,1.78-5.59,4.62-5.59
				c0.64,0,1.25,0.15,1.54,0.31l-0.35,1.87c-0.24-0.09-0.62-0.24-1.14-0.24c-1.52,0-2.31,1.65-2.31,3.61c0,2.2,0.88,3.52,2.31,3.52
				c0.44,0,0.81-0.07,1.14-0.24L759.56,293.98z"
              />
              <path
                d="M762.09,294.37c-0.75,0-1.39-0.64-1.39-1.63c0-0.95,0.66-1.63,1.43-1.63c0.81,0,1.43,0.62,1.43,1.63
				c0,0.99-0.57,1.63-1.45,1.63H762.09z"
              />
              <path d="M770.1,281.52h-2.66v-2.16h7.7v2.16h-2.68v12.67h-2.35V281.52z" />
              <path
                d="M785.56,288.48c-0.09-1.67-0.18-3.7-0.15-5.32h-0.07c-0.29,1.63-0.68,3.41-1.03,4.84l-1.54,6.03h-1.61l-1.45-5.88
				c-0.4-1.5-0.79-3.34-1.06-4.99h-0.07c-0.02,1.67-0.11,3.63-0.2,5.39l-0.26,5.65h-2.05l0.99-14.83h2.49l1.43,6.01
				c0.46,1.91,0.84,3.48,1.12,5.15h0.04c0.26-1.67,0.59-3.21,1.03-5.15l1.39-6.01h2.53l0.88,14.83h-2.13L785.56,288.48z"
              />
              <path d="M789.06,296.95c0.57-1.56,1.17-3.87,1.39-5.52l2.49-0.22c-0.59,1.89-1.52,4.27-2.33,5.59L789.06,296.95z" />
              <path
                d="M804.92,278.18c1.36,0,2.69,0.35,3.99,1.05c1.3,0.7,2.31,1.7,3.03,3c0.72,1.3,1.08,2.66,1.08,4.07
				c0,1.4-0.36,2.74-1.07,4.03s-1.71,2.29-3,3c-1.29,0.71-2.63,1.07-4.03,1.07c-1.4,0-2.75-0.36-4.03-1.07c-1.29-0.71-2.29-1.71-3-3
				c-0.72-1.29-1.07-2.63-1.07-4.03c0-1.41,0.36-2.77,1.09-4.07s1.74-2.3,3.03-3C802.24,278.53,803.56,278.18,804.92,278.18z
				 M804.92,279.52c-1.14,0-2.25,0.29-3.32,0.88c-1.08,0.58-1.92,1.42-2.53,2.5c-0.61,1.08-0.91,2.21-0.91,3.39
				c0,1.17,0.3,2.29,0.9,3.36c0.6,1.07,1.43,1.91,2.51,2.5c1.07,0.6,2.2,0.9,3.36,0.9c1.17,0,2.29-0.3,3.36-0.9
				c1.07-0.6,1.91-1.43,2.5-2.5c0.59-1.07,0.89-2.19,0.89-3.36c0-1.17-0.3-2.3-0.91-3.39s-1.45-1.92-2.53-2.5
				C807.16,279.82,806.06,279.52,804.92,279.52z M801.37,290.77v-8.72h3c1.02,0,1.77,0.08,2.22,0.24c0.46,0.16,0.82,0.44,1.1,0.84
				c0.27,0.4,0.41,0.83,0.41,1.28c0,0.64-0.23,1.19-0.68,1.67c-0.46,0.47-1.06,0.74-1.81,0.79c0.31,0.13,0.56,0.28,0.74,0.46
				c0.35,0.34,0.78,0.92,1.29,1.73l1.06,1.71h-1.72l-0.77-1.38c-0.61-1.08-1.1-1.76-1.47-2.03c-0.26-0.2-0.63-0.3-1.13-0.3h-0.83
				v3.71H801.37z M802.78,285.86h1.71c0.82,0,1.37-0.12,1.67-0.37c0.3-0.24,0.45-0.57,0.45-0.97c0-0.26-0.07-0.49-0.21-0.69
				c-0.14-0.2-0.34-0.36-0.6-0.46c-0.25-0.1-0.73-0.15-1.41-0.15h-1.6V285.86z"
              />
              <path
                d="M820.18,294.2v-14.83h2.18l2.49,6.34c0.51,1.34,1.25,3.26,1.72,4.69h0.04c-0.11-1.89-0.24-4.18-0.24-6.84v-4.18h2.07
				v14.83h-2.18l-2.51-6.31c-0.53-1.36-1.19-3.15-1.61-4.71h-0.04c0.09,1.72,0.18,3.81,0.18,6.89v4.14H820.18z"
              />
              <path
                d="M833.12,280.69c0.02,0.79-0.53,1.34-1.3,1.34c-0.68,0-1.23-0.55-1.21-1.34c-0.02-0.84,0.55-1.36,1.25-1.36
				C832.59,279.32,833.12,279.85,833.12,280.69z M830.7,294.2v-10.72h2.35v10.72H830.7z"
              />
              <path
                d="M835.1,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M847.26,280.82v2.66h2.07v1.72h-2.07v5.54c0,1.32,0.51,1.65,1.1,1.65c0.26,0,0.48-0.02,0.73-0.07l0.04,1.83
				c-0.35,0.11-0.88,0.18-1.54,0.18c-0.75,0-1.43-0.2-1.89-0.66c-0.53-0.53-0.79-1.32-0.79-2.9v-5.57h-1.28v-1.72h1.28v-1.85
				L847.26,280.82z"
              />
              <path
                d="M852.35,289.47c0.02,2.4,1.14,3.06,2.4,3.06c0.77,0,1.43-0.18,1.91-0.4l0.33,1.69c-0.66,0.31-1.69,0.51-2.68,0.51
				c-2.75,0-4.25-2-4.25-5.32c0-3.54,1.72-5.7,4-5.7c2.24,0,3.39,2.05,3.39,4.91c0,0.62-0.04,0.97-0.07,1.25H852.35z M855.27,287.79
				c0.02-1.72-0.55-2.73-1.39-2.73c-1.01,0-1.47,1.47-1.52,2.73H855.27z"
              />
              <path
                d="M859.03,286.5c0-1.21-0.04-2.07-0.09-3.01h2.05l0.11,1.25h0.04c0.51-0.86,1.41-1.43,2.53-1.43c1.8,0,2.75,1.39,2.75,3.87
				v7.02h-2.35v-6.8c0-1.06-0.22-2.11-1.25-2.11c-0.57,0-1.1,0.44-1.32,1.32c-0.07,0.22-0.11,0.57-0.11,0.97v6.62h-2.35V286.5z"
              />
              <path
                d="M875.67,278.97v12.32c0,0.88,0.04,2.09,0.09,2.9h-2.02l-0.13-1.28h-0.07c-0.37,0.77-1.19,1.45-2.22,1.45
				c-2.09,0-3.26-2.27-3.26-5.5c0-3.76,1.63-5.57,3.37-5.57c0.88,0,1.52,0.42,1.87,1.17h0.04v-5.5H875.67z M873.33,287.57
				c0-0.2,0-0.44-0.04-0.66c-0.09-0.86-0.57-1.61-1.28-1.61c-1.19,0-1.61,1.67-1.61,3.59c0,2.16,0.57,3.54,1.56,3.54
				c0.44,0,0.99-0.24,1.28-1.32c0.07-0.2,0.09-0.44,0.09-0.7V287.57z"
              />
              <path
                d="M884.97,288.81c0,4.33-2.22,5.57-3.85,5.57c-2.27,0-3.83-1.89-3.83-5.5c0-3.87,1.91-5.57,3.92-5.57
				C883.45,283.31,884.97,285.29,884.97,288.81z M879.65,288.83c0,1.8,0.31,3.74,1.56,3.74c1.17,0,1.43-2.13,1.43-3.74
				c0-1.56-0.26-3.7-1.47-3.7C879.89,285.13,879.65,287.27,879.65,288.83z"
              />
              <path
                d="M887.37,294.37c-0.75,0-1.39-0.64-1.39-1.63c0-0.95,0.66-1.63,1.43-1.63c0.81,0,1.43,0.62,1.43,1.63
				c0,0.99-0.57,1.63-1.45,1.63H887.37z"
              />
            </g>
          </g>
        </g>
      </svg>
      {children}
    </div>
  );
}
