import { useEffect } from 'react';

import { APIPagination, CodeObject } from '../../typings';
import { accountActions } from '../../store/actions';
import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { Card, Table, Row, Col, Alert } from 'react-bootstrap';
import { CodeRow } from './components/CodeRow';
import { Paginate } from '../../components';
import { useParams, Link } from 'react-router-dom';
import { changePageDetails } from '../../helpers';

interface CodesDonatedProps {
  loading: boolean;
  loaded: boolean;
  codes: CodeObject[];
  error: Error | null;
  pagination?: APIPagination;
  getCodesDonated: (page?: string, sort?: string) => void;
}

function CodesDonated({ codes, pagination, getCodesDonated }: CodesDonatedProps): JSX.Element {
  const { page = 1 } = useParams();

  useEffect(() => {
    changePageDetails('My codes', 'my-codes');
  }, []);

  useEffect(() => {
    getCodesDonated(page as string);
  }, [page, getCodesDonated]);

  if (!codes || codes.length <= 0) {
    return (
      <Row>
        <Col md={12}>
          <Alert>
            This service only exists due to community contributions, <Link to="/share">want to share?</Link>
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="my-codes-container mb-4">
            <Card.Body className="p-0">
              <Table striped borderless hover responsive className="my-codes">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Expansion</th>
                    <th>Type</th>
                    <th>Donated</th>
                    <th>Recipient</th>
                  </tr>
                </thead>
                <tbody>
                  {codes.map((code, idx: number) => (
                    <CodeRow key={`code-row-${idx}`} code={code} num={idx} accountKey="recipient" />
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Paginate className="my-codes-pagination" active={page} url="/codes/donated/" pagination={pagination} />
        </Col>
      </Row>
    </>
  );
}

function mapState({ account }: rootState) {
  return {
    loading: account.loading,
    loaded: account.loaded,
    codes: account.codes,
    pagination: account.pagination,
    error: account.error
  };
}

const actionCreators = {
  getCodesDonated: accountActions.codesDonated
};

const connectedCodesDonated = connect(mapState, actionCreators)(CodesDonated);
export { connectedCodesDonated as CodesDonated };
