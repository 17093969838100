import { DispatchTokenLogout } from '../../typings';

import { TokenActions } from '../../typings';
import { accountService } from '../services/account.services';

function logout(cb?: () => void): (dispatch: DispatchTokenLogout) => void {
  return (dispatch) => {
    dispatch(request());
    accountService.doLogout().then(() => dispatch(success()));
  };

  function request() {
    return { type: TokenActions.LOGOUT_REQUEST };
  }

  function success() {
    if (cb) cb();

    return { type: TokenActions.LOGOUT_SUCCESS };
  }
}

export const tokenActions = {
  logout
};
