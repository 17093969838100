import { CodeObject, AccountData, ExpansionObject, CodeType } from '../../../typings';
import { UserProfile } from '../../../components';
import { formatCode } from '../../../helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';

interface CodeRowProps {
  children?: ReactNode;
  code: CodeObject;
  num: number;
  accountKey?: 'donor' | 'recipient';
}

export function CodeRow({ children, code, num, accountKey = 'donor' }: CodeRowProps): JSX.Element {
  const [copied, setCopied] = useState<boolean>(false);
  const account = code?.[accountKey] as unknown as AccountData;
  const expansion = (code?.expansion || { id: 'basep', name: 'Promo' }) as unknown as ExpansionObject;
  const formattedCode = formatCode(code.code);
  const isUsed = accountKey === 'recipient' && account;
  const isInvalid = !code.wasValid && code.processed;

  return (
    <tr className={isInvalid ? 'my-codes-invalid' : ''}>
      <td>{num + 1}</td>
      <td>
        <span className={`font-monospace${isUsed || isInvalid ? ' text-muted' : ''}`}>{formattedCode}</span>
        {isUsed || isInvalid ? (
          <></>
        ) : (
          <CopyToClipboard className="my-codes-copy" text={formattedCode} onCopy={() => setCopied(true)}>
            <FontAwesomeIcon icon={['fas', copied ? 'check' : 'clipboard']} />
          </CopyToClipboard>
        )}
      </td>
      {!code.processed ? (
        <td colSpan={2}>
          <Spinner animation="border" variant="info" /> <span className="text-muted">Processing...</span>
        </td>
      ) : isInvalid ? (
        <td colSpan={2} className="text-muted">
          <FontAwesomeIcon icon={['fas', 'triangle-exclamation']} className="my-codes-icon" />
          {code?.coupon || 'Invalid'}
        </td>
      ) : (
        <>
          <td>
            <img src={`/assets/expansions/${expansion.id}.symbol.png`} alt={`${expansion.name} symbol`} />
            {expansion.name}
          </td>
          <td>{CodeType[code.type || 99]}</td>
        </>
      )}
      <td>{moment(code[accountKey === 'donor' ? 'requestedAt' : 'createdAt']).fromNow()}</td>
      <td>{!account ? '' : <UserProfile user={account} showAvatar={false} />}</td>
      {children ? <td>{children}</td> : null}
    </tr>
  );
}
