import {
  APIResponse,
  CodeActions,
  CodeObject,
  CodeLatestDispatch,
  CodeRequestDispatch,
  CodeReturnDispatch,
  CodeClearDispatch
} from '../../typings';

import { Dispatch } from 'redux';
import { ExpansionObject } from '../../typings/code.typings';
import { codeService } from '../services/code.services';

export const codeActions = {
  requestCode,
  returnCode,
  latestCodes,
  clearCode
};

function clearCode(): (dispatch: Dispatch<CodeClearDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    function request(): CodeClearDispatch {
      return {
        type: CodeActions.CLEAR_CODE_REQUEST
      };
    }
  };
}

function requestCode(
  token: string,
  fingerprint: string,
  cb?: (data: CodeObject) => void
): (dispatch: Dispatch<CodeRequestDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    codeService.requestCode(token, fingerprint).then(
      (res: APIResponse<CodeObject>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): CodeRequestDispatch {
    return {
      type: CodeActions.REQUEST_REQUEST
    };
  }

  function success({ data }: APIResponse<CodeObject>): CodeRequestDispatch {
    if (cb) cb(data);

    return {
      type: CodeActions.REQUEST_SUCCESS,
      payload: data
    };
  }

  function failure(error: Error | string | null): CodeRequestDispatch {
    return { type: CodeActions.REQUEST_FAILURE, error: (error as any)?.message || error };
  }
}

function returnCode(id: string, cb?: () => void): (dispatch: Dispatch<CodeReturnDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    codeService.returnCode(id).then(
      (res: APIResponse<{}>) => dispatch(res.status ? success() : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): CodeReturnDispatch {
    return {
      type: CodeActions.RETURN_REQUEST
    };
  }

  function success(): CodeReturnDispatch {
    if (cb) cb();

    return {
      type: CodeActions.RETURN_SUCCESS
    };
  }

  function failure(error: Error | string | null): CodeReturnDispatch {
    return { type: CodeActions.RETURN_FAILURE, error: (error as any)?.message || error };
  }
}

function latestCodes(): (dispatch: Dispatch<CodeLatestDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    codeService.latestCodes().then(
      (res: APIResponse<ExpansionObject[]>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): CodeLatestDispatch {
    return {
      type: CodeActions.LATEST_REQUEST,
      payload: []
    };
  }

  function success({ data }: APIResponse<ExpansionObject[]>): CodeLatestDispatch {
    return {
      type: CodeActions.LATEST_SUCCESS,
      payload: data
    };
  }

  function failure(error: Error | string | null): CodeLatestDispatch {
    return { type: CodeActions.LATEST_FAILURE, error: (error as any)?.message || error };
  }
}
