import { StockActions, StockReducerAction, StockReducerResponse } from '../../typings';

const initialState: StockReducerResponse = {
  loading: false,
  loaded: false,
  error: null,
  available: []
};

export default function code(
  state: StockReducerResponse = initialState,
  action: StockReducerAction
): StockReducerResponse {
  switch (action.type) {
    case StockActions.AVAILABLE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error || new Error('Unknwon Error')
      };

    case StockActions.AVAILABLE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case StockActions.AVAILABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        available: action.payload
      };

    default:
      return state;
  }
}
