import { Card, Tab, Tabs, Row, Col } from 'react-bootstrap';

import { SubmissionObject } from '../../../../typings';
import { ResultBlock } from './ResultBlock';
import { connect } from 'react-redux';
import { rootState } from '../../../../store/reducers';
import { useEffect, useState } from 'react';

interface OwnProps {}

interface StateProps {
  loading: boolean;
  loaded: boolean;
  codes: SubmissionObject;
  error: Error | null;
}

type CodeResultsProps = StateProps & OwnProps;
type CodeResultsTabs = 'all' | 'accepted' | 'rejected';

const filterCodes = (codes: SubmissionObject, type: CodeResultsTabs = 'all') => {
  return Object.keys(codes).filter((code: string) => {
    if (type === 'all') return true;
    if (type === 'accepted' && codes[code] === true) return true;
    if (type === 'rejected' && (codes[code] === false || typeof codes[code] === 'string')) return true;
    return false;
  });
};

function CodeResults({ codes }: CodeResultsProps): JSX.Element {
  const [tab, setTab] = useState<CodeResultsTabs>('all');
  const [filteredCodes, setFilteredCodes] = useState<string[]>(Object.keys(codes));

  useEffect(() => {
    setFilteredCodes(filterCodes(codes, tab));
  }, [codes, tab]);

  return (
    <div className="code-results">
      <Card.Header>
        <Tabs activeKey={tab} onSelect={(t) => setTab((t || 'all') as CodeResultsTabs)}>
          <Tab eventKey="all" title={`All (${filterCodes(codes, 'all').length})`} />
          <Tab eventKey="accepted" title={`Accepted (${filterCodes(codes, 'accepted').length})`} />
          <Tab eventKey="rejected" title={`Rejected (${filterCodes(codes, 'rejected').length})`} />
        </Tabs>
      </Card.Header>
      <Card.Body>
        <Row>
          {filteredCodes.map((code: string, idx: number) => {
            return (
              <Col md={4} key={`code-${idx}`} className="code-results-item">
                <ResultBlock code={code} result={codes[code]} />
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </div>
  );
}

function mapState({ submit }: rootState): StateProps {
  return {
    loading: submit.loading,
    loaded: submit.loaded,
    codes: submit.codes,
    error: submit.error || null
  };
}

const connectedCodeResults = connect(mapState)(CodeResults);
export { connectedCodeResults as CodeResults };
