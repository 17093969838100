import React, { createContext, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

interface CookieContextType {
  consent: boolean;
  setConsent: (value: boolean) => void;
}
export const CookieContext = createContext<CookieContextType>({
  consent: false,
  setConsent: () => {}
});

export function CookieProvider({ children }: { children: React.ReactElement }): JSX.Element {
  const [consent, setConsent] = useState<boolean>(getCookieConsentValue() === 'true');
  return <CookieContext.Provider value={{ consent, setConsent }}>{children}</CookieContext.Provider>;
}
