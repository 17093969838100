import {
  faBars,
  faCheck,
  faQuestion,
  faX,
  faTimes,
  faClipboard,
  faCalendar,
  faTags,
  faCircleInfo,
  faTriangleExclamation,
  faGifts,
  faStar,
  faQrcode,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faRedditAlien } from '@fortawesome/free-brands-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faBars,
  faCheck,
  faQuestion,
  faX,
  faTimes,
  faClipboard,
  faCalendar,
  faTags,
  faCircleInfo,
  faTriangleExclamation,
  faGifts,
  faStar,
  faQrcode,
  faShare,
  faFacebookF as any,
  faInstagram as any,
  faLinkedin as any,
  faTwitter as any,
  faRedditAlien as any
);
