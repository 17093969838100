import { combineReducers } from 'redux';

import account from './account.reducers';
import code from './code.reducers';
import stats from './stats.reducers';
import stock from './stock.reducers';
import submit from './submit.reducers';
import token from './token.reducers';
import top from './top.reducers';
import notifications from './notification.reducers';

import {
  CodeReducerResponse,
  TokenReducerResponse,
  StockReducerResponse,
  SubmitReducerResponse,
  StatsReducerResponse,
  AccountReducerResponse,
  TopReducerResponse,
  NotificationReducerResponse
} from '../../typings';

export type rootState = {
  account: AccountReducerResponse;
  code: CodeReducerResponse;
  token: TokenReducerResponse;
  stock: StockReducerResponse;
  submit: SubmitReducerResponse;
  stats: StatsReducerResponse;
  top: TopReducerResponse;
  notifications: NotificationReducerResponse;
};

export default combineReducers({
  account,
  code,
  token,
  stock,
  submit,
  stats,
  top,
  notifications
});
