import { FC, useCallback, useEffect } from 'react';
import { codeActions, statsActions } from '../../store/actions';

import { ActionButton } from './components/ActionButton';
import { ActionEmpty } from './components/ActionEmpty';
import { CodeObject } from '../../typings';
import { CodeResponse } from './components/CodeResponse';
import CountUp from 'react-countup';
import { Footer } from '../../components/Layout/components/Footer';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Packs } from './components/Packs';
import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { roundToPrecision } from '../../helpers';

interface OwnProps {}

interface StateProps {
  code: CodeObject | null;
  codesAvailable?: number;
  codesAvailableLoading: boolean;
  codesRequestToday?: number;
  codesRequestTodayLoading: boolean;
  codesRequestTotal?: number;
  codesRequestTotalLoading: boolean;
}
interface ActionProps {
  clear: () => void;
  getCodesRequestToday: () => void;
  getCodesRequestTotal: () => void;
  getCodesAvailable: () => void;
}

type HomeProps = ActionProps & StateProps & OwnProps;

function Home({
  code,
  codesRequestTodayLoading,
  codesRequestToday,
  getCodesRequestToday,
  codesRequestTotalLoading,
  codesRequestTotal,
  getCodesRequestTotal,
  codesAvailableLoading,
  codesAvailable,
  getCodesAvailable,
  clear
}: HomeProps): JSX.Element {
  const formatNumber = useCallback((value: number) => {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  }, []);

  useEffect(() => {
    document?.querySelector('#layout')?.scrollTo(0, 0);
    if (codesRequestToday === undefined && !codesRequestTodayLoading) getCodesRequestToday();
    if (codesRequestTotal === undefined && !codesRequestTotalLoading) getCodesRequestTotal();
    if (codesAvailable === undefined && !codesAvailableLoading) getCodesAvailable();
  }, []);

  return (
    <div className="page-home">
      <div className="overlay-container">
        <div className="overlay overlay-right rotate-part">
          <Packs />
        </div>
      </div>
      <section id="left-side">
        <div className="content">
          <h1 className="text-intro">
            <span className="fw-lighter">Free codes,</span> every day.
          </h1>

          <h2 className="text-intro mb-5">
            Helping the Pokémon community{' '}
            <strong>
              share, validate, and
              <span className="line-break"></span>
              distribute
            </strong>{' '}
            TCG Live codes.{' '}
            {codesRequestToday && codesRequestToday >= 100 ? (
              <>
                Over{' '}
                <CountUp
                  start={0}
                  end={roundToPrecision(codesRequestToday || 0)}
                  duration={1}
                  useEasing={true}
                  redraw={false}
                  preserveValue={true}
                  formattingFn={formatNumber}
                />{' '}
                given away today.
              </>
            ) : (
              <>
                Over{' '}
                <CountUp
                  start={0}
                  end={roundToPrecision(codesRequestTotal || 0)}
                  duration={1}
                  useEasing={true}
                  redraw={false}
                  preserveValue={true}
                  formattingFn={formatNumber}
                />{' '}
                given away so far.
              </>
            )}
          </h2>

          <div className="call-to-action">
            <ul>
              {codesAvailable !== undefined && codesAvailable <= 0 ? (
                <li>
                  <ActionEmpty />
                </li>
              ) : (
                <li>
                  <ActionButton />
                </li>
              )}
              <li>
                <Link to="/share" className="light-btn trigger text-intro animated-middle fadeInUp">
                  Share codes
                </Link>
              </li>
            </ul>
          </div>

          {code ? <CodeResponse code={code} onClose={clear} /> : null}
        </div>

        <Footer isHome={true} />
        <Outlet />
      </section>
    </div>
  );
}

function mapState({ code, stats: { stats, loading } }: rootState): StateProps {
  return {
    code: code.code,
    codesAvailable: stats?.codesAvailable as unknown as number,
    codesAvailableLoading: loading?.codesAvailable,
    codesRequestToday: stats?.codesRequestToday as unknown as number,
    codesRequestTodayLoading: loading?.codesRequestToday,
    codesRequestTotal: stats?.codesRequestTotal as unknown as number,
    codesRequestTotalLoading: loading?.codesRequestTotal
  };
}

const actionCreators: ActionProps = {
  clear: codeActions.clearCode,
  getCodesAvailable: statsActions.codesAvailable,
  getCodesRequestToday: statsActions.codesRequestToday,
  getCodesRequestTotal: statsActions.codesRequestTotal
};

const connectedHome = connect(mapState, actionCreators)(Home) as unknown as FC<OwnProps>;
export { connectedHome as Home };
