import { APIResponse, StockActions, StockAvailabilityDispatch, StockLevelObject } from '../../typings';

import { Dispatch } from 'redux';
import { stockService } from '../services/stock.services';

export const stockActions = {
  availableStock
};

function availableStock(): (dispatch: Dispatch<StockAvailabilityDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    stockService.availableStock().then(
      (res: APIResponse<any[]>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): StockAvailabilityDispatch {
    return {
      type: StockActions.AVAILABLE_REQUEST,
      payload: []
    };
  }

  function success({ data }: APIResponse<StockLevelObject[]>): StockAvailabilityDispatch {
    return {
      type: StockActions.AVAILABLE_SUCCESS,
      payload: data
    };
  }

  function failure(error: Error | string | null): StockAvailabilityDispatch {
    return { type: StockActions.AVAILABLE_FAILURE, error: (error as any)?.message || error };
  }
}
