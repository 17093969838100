export enum StatsActions {
  CODES_AVAILABLE_REQUEST = 'STATS_CODES_AVAILABLE_REQUEST',
  CODES_AVAILABLE_SUCCESS = 'STATS_CODES_AVAILABLE_SUCCESS',
  CODES_AVAILABLE_FAILURE = 'STATS_CODES_AVAILABLE_FAILURE',

  CODES_REQUEST_TODAY_REQUEST = 'STATS_CODES_REQUEST_TODAY_REQUEST',
  CODES_REQUEST_TODAY_SUCCESS = 'STATS_CODES_REQUEST_TODAY_SUCCESS',
  CODES_REQUEST_TODAY_FAILURE = 'STATS_CODES_REQUEST_TODAY_FAILURE',

  CODES_REQUEST_TOTAL_REQUEST = 'STATS_CODES_REQUEST_TOTAL_REQUEST',
  CODES_REQUEST_TOTAL_SUCCESS = 'STATS_CODES_REQUEST_TOTAL_SUCCESS',
  CODES_REQUEST_TOTAL_FAILURE = 'STATS_CODES_REQUEST_TOTAL_FAILURE',

  CODES_REQUEST_BY_DAY_REQUEST = 'STATS_CODES_REQUEST_BY_DAY_REQUEST',
  CODES_REQUEST_BY_DAY_SUCCESS = 'STATS_CODES_REQUEST_BY_DAY_SUCCESS',
  CODES_REQUEST_BY_DAY_FAILURE = 'STATS_CODES_REQUEST_BY_DAY_FAILURE',

  CODES_DONATE_TODAY_REQUEST = 'STATS_CODES_DONATE_TODAY_REQUEST',
  CODES_DONATE_TODAY_SUCCESS = 'STATS_CODES_DONATE_TODAY_SUCCESS',
  CODES_DONATE_TODAY_FAILURE = 'STATS_CODES_DONATE_TODAY_FAILURE',

  CODES_DONATE_TOTAL_REQUEST = 'STATS_CODES_DONATE_TOTAL_REQUEST',
  CODES_DONATE_TOTAL_SUCCESS = 'STATS_CODES_DONATE_TOTAL_SUCCESS',
  CODES_DONATE_TOTAL_FAILURE = 'STATS_CODES_DONATE_TOTAL_FAILURE',

  CODES_DONATE_BY_DAY_REQUEST = 'STATS_CODES_DONATE_BY_DAY_REQUEST',
  CODES_DONATE_BY_DAY_SUCCESS = 'STATS_CODES_DONATE_BY_DAY_SUCCESS',
  CODES_DONATE_BY_DAY_FAILURE = 'STATS_CODES_DONATE_BY_DAY_FAILURE',

  MSG_CODES_AVAILABLE = 'STATS_MSG_CODES_AVAILABLE',
  MSG_CODES_REQUEST_TODAY = 'STATS_MSG_CODES_REQUEST_TODAY',
  MSG_CODES_REQUEST_TOTAL = 'STATS_MSG_CODES_REQUEST_TOTAL'
}

export type StatsType = number | undefined | { [key: string]: number };

export interface StatsReducerAction {
  type: StatsActions;
  payload?: number;
  error?: Error | null;
}

export interface StatsReducerResponse {
  error: Error | null;
  loading: { [key: string]: boolean };
  loaded: { [key: string]: boolean };
  stats: { [key: string]: StatsType };
}

export interface StatsNumberDispatch {
  type: StatsActions;
  payload?: number;
  error?: string | null;
}

export interface StatsDateRangeDispatch {
  type: StatsActions;
  payload?: { [key: string]: number };
  error?: string | null;
}

export interface StatsNumberMessage {
  type: StatsActions;
  payload?: number;
  error?: string | null;
}

export interface StatsDateRangeMessage {
  type: StatsActions;
  payload?: { [key: string]: number };
  error?: string | null;
}
