import { Outlet, useLocation } from 'react-router-dom';
import { Container, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const areas = {
  received: 'Received',
  donated: 'Donated'
};

export function Codes(): JSX.Element {
  const location = useLocation();
  const area = location.pathname.split('/')[2] || Object.keys(areas)[0];

  return (
    <div className="page-base">
      <Container>
        <Row>
          <Col md={6}>
            <h1 className="text-intro mb-5">My Codes</h1>
          </Col>
          <Col md={6}>
            <ButtonGroup className="my-codes-selector">
              {Object.keys(areas).map((a: string, idx: number) => (
                <Link
                  key={`page-link-${idx}`}
                  to={`/codes/${a}`}
                  className={`btn btn-${area === a ? 'primary' : 'secondary'}`}
                >
                  {areas[a]}
                </Link>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
