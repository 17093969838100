import { codeActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { rootState } from '../../../store/reducers';
import ReactGA from 'react-ga';
import { codeInterval, codeWaitTime, maxCodesInPeriod } from '../../../config';
import moment, { Duration, Moment } from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountContext, CookieContext } from '../../../components';
import { ActionRequest } from './ActionRequest';
import { ActionWait } from './ActionWait';
import { filterCodeDates } from '../../../helpers';
import { CodeObject } from '../../../typings';

const calculateDuration = (eventTime: Moment): Duration =>
  moment.duration(Math.floor(eventTime.diff(moment()) / 1000), 'seconds');

const calculateNextAvailable = (lastCode?: Date, role?: number): Moment => {
  const msToNextCode = !lastCode
    ? 0
    : moment(lastCode).diff(moment().subtract(...(role !== 1 ? ['10', 'seconds'] : codeWaitTime)));

  return moment().add(msToNextCode, 'milliseconds');
};

interface ActionButtonProps {
  loading: boolean;
  loaded: boolean;
  error?: Error | null;
  code?: CodeObject | null;
  request: (token: string, fingerprint: string, cb?: () => void) => void;
}

function ActionButton({ loading, code, error, request }: ActionButtonProps): JSX.Element {
  const { account, fingerprint, updateAccount } = useContext(AccountContext);
  const { consent } = useContext(CookieContext);
  const [nextAvailable, setNextAvailable] = useState<Moment | undefined>();
  const [usedCodes, setUsedCodes] = useState<string[]>(account?.codes || []);
  const [duration, setDuration] = useState<Duration | undefined>();
  const nextAvailableTimerRef = useRef<ReturnType<typeof setInterval>>();
  const usedCodesTimerRef = useRef<ReturnType<typeof setInterval>>();
  const navigate = useNavigate();

  // Handle making a request or redirecting to login
  const makeRequest = useCallback(
    (token: string) => {
      if (!account) return navigate('/login');
      if (consent) ReactGA.event({ category: 'Code', action: 'Requested' });
      return request(token, `${fingerprint?.visitorId}_${fingerprint?.confidence.score}`, () => updateAccount());
    },
    [account, fingerprint, updateAccount, navigate, request, consent]
  );

  // If nextAvailable expires, update account and stop timer
  // Otherwise, update the duration calculation
  const nextAvailableCallback = useCallback(() => {
    if (!nextAvailable || Date.now() >= nextAvailable.valueOf()) {
      updateAccount();
      return clearInterval(nextAvailableTimerRef.current);
    }
    setDuration(calculateDuration(nextAvailable));
  }, [nextAvailable, updateAccount]);

  // When the timer ticks, filter the dates and set to state
  const usedCodesCallback = useCallback(() => {
    const used = filterCodeDates(account?.codes);
    if (JSON.stringify(usedCodes.sort()) !== JSON.stringify(used.sort())) setUsedCodes(used);
    if (used.length <= 0) return clearInterval(usedCodesTimerRef.current);
  }, [account, usedCodes, setUsedCodes]);

  // If account changes, start a timer and run used codes
  useEffect(() => {
    usedCodesTimerRef.current = setInterval(usedCodesCallback, codeInterval);
    usedCodesCallback();
    return () => clearInterval(usedCodesTimerRef.current);
  }, [account, usedCodesCallback]);

  // If used codes changes, and its above the max in period
  // calculate next available time and set to state
  useEffect(() => {
    if (maxCodesInPeriod > usedCodes.length) return;
    setNextAvailable(calculateNextAvailable(new Date(usedCodes.sort()?.[0]), 1 /*, account?.role*/));
  }, [usedCodes]);

  // If next available changes, start a timer to run the next
  // available callback
  useEffect(() => {
    nextAvailableTimerRef.current = setInterval(nextAvailableCallback, codeInterval);
    return () => clearInterval(nextAvailableTimerRef.current);
  }, [nextAvailable, nextAvailableCallback]);

  // If a code is available, the date is past next next available, or duration expires
  // show the button to make a code request
  if (
    maxCodesInPeriod > usedCodes.length &&
    (!nextAvailable || Date.now() >= nextAvailable.valueOf() || !duration || duration.valueOf() <= 0)
  ) {
    return (
      <ActionRequest
        max={maxCodesInPeriod}
        used={usedCodes.length}
        onRequest={makeRequest}
        loading={loading}
        error={error}
        disabled={!!code}
      />
    );
  }

  // Otherwise show the countdown timer until next code is available
  return <ActionWait duration={duration} />;
}

function mapState({ code }: rootState) {
  return {
    loading: code.loading,
    loaded: code.loaded,
    error: code.error,
    code: code.code
  };
}

const actionCreators = {
  request: codeActions.requestCode
};

const connectedActionButton = connect(mapState, actionCreators)(ActionButton);
export { connectedActionButton as ActionButton };
