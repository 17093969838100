import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { changePageDetails } from '../../helpers';
import { statsActions } from '../../store/actions';
import { StatsType } from '../../typings';
import { DateRangeGraph } from './components/DateRangeGraph';
import { StatsCard } from './components/StatsCard';

interface StatsProps {
  loading: { [key: string]: boolean };
  loaded: { [key: string]: boolean };
  error?: Error | null;
  stats: { [key: string]: StatsType };
  codesAvailable: () => void;
  codesRequestToday: () => void;
  codesRequestTotal: () => void;
  codesDonateToday: () => void;
  codesDonateTotal: () => void;
  codesDonateByDay: () => void;
  codesRequestByDay: () => void;
}

function Stats({ loading, loaded, error, stats, ...rest }: StatsProps): JSX.Element {
  useEffect(() => {
    changePageDetails('Stats', 'stats');
    Object.keys(rest).forEach((method: string) => {
      if (!stats[method]) rest[method]();
    });
  }, []);

  return (
    <div className="page-stats">
      <Container>
        <Row>
          <Col lg={3}>
            <StatsCard
              title="Total Code Requests"
              variant="zest"
              icon="gifts"
              value={stats?.codesRequestTotal as number}
              loading={loading?.codesRequestTotal}
            />
          </Col>
          <Col lg={3}>
            <StatsCard
              title="Requests Today"
              variant="buttercup"
              icon="share"
              value={stats?.codesRequestToday as number}
              loading={loading?.codesRequestToday}
            />
          </Col>
          <Col lg={3}>
            <StatsCard
              title="Donated Today"
              variant="curious-blue"
              icon="qrcode"
              value={stats?.codesDonateToday as number}
              loading={loading?.codesDonateToday}
            />
          </Col>
          <Col lg={3}>
            <StatsCard
              title="Currently Available"
              value={stats?.codesAvailable as number}
              variant="meadow"
              icon="star"
              loading={loading?.codesAvailable}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card className="stats-date-range">
              <Card.Body>
                <Card.Title as={'h5'} className="text-uppercase">
                  Codes Requested Per Day (Last 4 weeks)
                </Card.Title>
                <DateRangeGraph
                  data={stats.codesRequestByDay}
                  loading={loading.codesRequestByDay}
                  loaded={loaded.codesRequestByDay}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="stats-date-range">
              <Card.Body>
                <Card.Title as={'h5'} className="text-uppercase">
                  Codes Donated Per Day (Last 4 weeks)
                </Card.Title>
                <DateRangeGraph
                  data={stats.codesDonateByDay}
                  loading={loading.codesDonateByDay}
                  loaded={loaded.codesDonateByDay}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function mapState({ stats }: rootState) {
  return {
    loading: stats.loading,
    loaded: stats.loaded,
    stats: stats.stats,
    error: stats?.error || null
  };
}

const actionCreators = {
  codesAvailable: statsActions.codesAvailable,
  codesRequestToday: statsActions.codesRequestToday,
  codesRequestTotal: statsActions.codesRequestTotal,
  codesDonateToday: statsActions.codesDonateToday,
  codesDonateTotal: statsActions.codesDonateTotal,
  codesDonateByDay: statsActions.codesDonateByDay,
  codesRequestByDay: statsActions.codesRequestByDay
};

const connectedStats = connect(mapState, actionCreators)(Stats);
export { connectedStats as Stats };
