export enum SubmitActions {
  SINGLE_REQUEST = 'SUBMIT_SINGLE_REQUEST',
  SINGLE_SUCCESS = 'SUBMIT_SINGLE_SUCCESS',
  SINGLE_FAILURE = 'SUBMIT_SINGLE_FAILURE',

  BULK_REQUEST = 'SUBMIT_BULK_REQUEST',
  BULK_SUCCESS = 'SUBMIT_BULK_SUCCESS',
  BULK_FAILURE = 'SUBMIT_BULK_FAILURE',

  CLEAR_CODES = 'SUBMIT_CLEAR_CODES'
}

export interface SubmissionObject {
  [key: string]: true | false | string | null;
}

export interface SubmitReducerResponse {
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  codes: SubmissionObject;
}

export interface SubmitReducerAction {
  type: SubmitActions;
  payload: SubmissionObject;
  error?: Error | null;
}

export interface SubmitClearDispatch {
  type: SubmitActions;
}

export interface SubmitSingleDispatch {
  type: SubmitActions;
  payload?: SubmissionObject;
  error?: string | null;
}

export interface SubmitBulkDispatch {
  type: SubmitActions;
  payload?: SubmissionObject;
  error?: string | null;
}
