import { randomNumber } from './randomNumber.helpers';

const expansionConfig = {
  swsh3: 4,
  swsh7: 4,
  swsh8: 4,
  swsh9: 4,
  swsh10: 4,
  swsh11: 4
};

export function missingImage(index: number): string {
  const keys = Object.keys(expansionConfig);
  const expansion = randomNumber(1, keys.length, `missing-${index}`) - 1;

  return `/assets/expansions/${keys[expansion]}.pack.${randomNumber(
    1,
    expansionConfig[keys[expansion]],
    `missing-${index}`
  )}.png`;
}
