import { StatsType } from '../../../typings';
import { ResponsiveContainer, AreaChart, Area, YAxis } from 'recharts';
import { dateRange } from '../../../helpers';

interface DateRangeGraphProps {
  data?: StatsType;
  loading?: boolean;
  loaded?: boolean;
}

interface DateFormatted {
  name: string;
  uv?: number;
  pv?: number;
  amt: number;
}

export function DateRangeGraph({ data, loaded }: DateRangeGraphProps): JSX.Element {
  if (!loaded) return <p>Loading...</p>;

  const dataFormatted: DateFormatted[] = dateRange([28, 'days']).map((date: string) => ({
    name: date,
    amt: data?.[date] || 0
  }));

  return (
    <ResponsiveContainer width="99%" height={300}>
      <AreaChart width={500} height={300} data={dataFormatted}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="35%" stopColor="#1abc9c" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#ffffff" stopOpacity={0.25} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="amt" stroke="#1abc9c" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
        <YAxis axisLine={false} mirror={true} padding={{ top: 30 }} tickMargin={-6} tickLine={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
}
