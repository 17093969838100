import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';

interface ModalDialogProps {
  icon: IconName;
  title: string;
  message: string;
  variant?: Variant;
  children?: ReactNode;
}

export function DialogModal({ icon, title, message, children, variant = 'success' }: ModalDialogProps): JSX.Element {
  return (
    <Modal dialogClassName={`dialog-modal dialog-modal-${variant}`} size="lg" show>
      <Modal.Header>
        <div className="icon-box">
          <FontAwesomeIcon icon={['fas', icon]} size="4x" />
        </div>
        <Modal.Title className="w-100">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">{message}</p>
      </Modal.Body>
      {children ? <Modal.Footer>{children}</Modal.Footer> : null}
    </Modal>
  );
}
