import { AccountData } from './account.typings';
import { APIResponse } from './service.typings';
import { Dispatch } from 'react';

export enum TokenActions {
  STORAGE_NAME = 'AccessToken',

  LOGIN_REQUEST = 'TOKEN_LOGIN_REQUEST',
  LOGIN_SUCCESS = 'TOKEN_LOGIN_SUCCESS',
  LOGIN_FAILURE = 'TOKEN_LOGIN_FAILURE',

  LOGOUT_REQUEST = 'TOKEN_LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'TOKEN_LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'TOKEN_LOGOUT_FAILURE'
}

export interface JWTPayload {
  jti: string;
  sub: string | null;
  exp: number;
  iat: number;
}

export interface TokenReducerResponse {
  loading?: boolean;
  loaded?: boolean;
  account?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  error: Error | null;
}

export interface TokenReducerAction {
  type: string;
  account?: string;
  accessToken?: string;
  refreshToken?: string;
  error?: Error;
}

export type APIResponseAccount = APIResponse<{
  account: AccountData;
  accessToken: string;
  refreshToken: string;
}>;

export type DispatchTokenAccount = Dispatch<{
  type: string;
  account?: AccountData;
  accessToken?: string;
  refreshToken?: string;
}>;

export type DispatchTokenLogout = Dispatch<{
  type: string;
}>;
