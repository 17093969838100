import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from './components/Footer';
import { useContext, useEffect } from 'react';
import { Header } from './components/Header';
import { changePageDetails } from '../../helpers';
import { Notifications } from '../Notifications';
import { DialogModal, AccountContext } from '..';

export function Layout(): JSX.Element {
  const location = useLocation();
  const { error } = useContext(AccountContext);
  const isHome = ['/', '/login'].includes(location.pathname);

  useEffect(() => {
    if (!isHome) return;
    changePageDetails(false, 'home');
  }, [isHome]);

  return (
    <>
      <div id="layout">
        <Header isHome={isHome} />
        <Outlet />
        {isHome ? <></> : <Footer />}
      </div>
      <Notifications />
      {error ? <DialogModal variant="danger" icon="times" title="Error!" message={error} /> : null}
    </>
  );
}
