import { Card, Col, Container, Nav, Row, Alert } from 'react-bootstrap';
import { CodeResults } from './components';
import { useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { submitActions } from '../../store/actions';
import { connect } from 'react-redux';
import { changePageDetails } from '../../helpers';

const actions = {
  text: 'Text',
  qr: 'QR Code'
};

interface OwnProps {}

interface StateProps {}

interface DispatchActions {
  clearCodes: () => void;
}

type ShareProps = DispatchActions & StateProps & OwnProps;

function Share({ clearCodes }: ShareProps): JSX.Element {
  const location = useLocation();
  const action = location.pathname.split('/')[2] || Object.keys(actions)[0];

  useEffect(() => {
    changePageDetails('Share', 'share');
    return () => clearCodes();
  }, [clearCodes]);

  return (
    <div className="page-base">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="text-intro mb-2">Share</h1>
            <Alert variant="light" className="mb-4">
              <strong>Note:</strong> This service is entirely powered by contributions from community members like
              yourself. Each and every code goes a long way to ensuring this project continues.
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="d-flex align-items-stretch">
            <Card className="w-100 card-submit-container">
              <Card.Header>
                <Nav variant="tabs">
                  {Object.keys(actions).map((key: string, idx: number) => (
                    <Nav.Item key={`submit-tab-${idx}`}>
                      <Nav.Link as={Link} to={`/share/${key}`} active={action === key}>
                        {actions[key]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Card.Header>
              <Outlet />
            </Card>
          </Col>
          <Col md={8} className="d-flex align-items-stretch">
            <Card className="w-100">
              <CodeResults />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function mapState(): StateProps {
  return {};
}

const actionCreators: DispatchActions = {
  clearCodes: submitActions.submitCodeClear
};

const connectedShare = connect(mapState, actionCreators)(Share);
export { connectedShare as Share };
