import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { useEffect } from 'react';
import { topActions } from '../../store/actions';
import { TopData } from '../../typings';
import { Container, Row, Col } from 'react-bootstrap';
import { DonorRow } from './components/DonorRow';
import { changePageDetails } from '../../helpers';

interface TopProps {
  loading: boolean;
  loaded: boolean;
  error?: Error | null;
  data?: TopData[];
  getTopDonorsTotal: () => void;
}

function Top({ data, loading, getTopDonorsTotal }: TopProps): JSX.Element {
  useEffect(() => {
    changePageDetails('Leaderboard', 'leaderboard');
    if (!data && !loading) getTopDonorsTotal();
  }, [data, loading, getTopDonorsTotal]);

  return (
    <div className="page-base">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="text-intro mb-5">Leaderboard</h1>
          </Col>
        </Row>
        <Row>
          {data?.map((d, idx: number) => (
            <DonorRow key={`donor-${idx}`} data={d} rank={idx + 1} size={6} />
          ))}
        </Row>
      </Container>
    </div>
  );
}

function mapState({ top }: rootState) {
  return {
    loading: top.loading,
    loaded: top.loaded,
    data: top.data,
    error: top?.error || null
  };
}

const actionCreators = {
  getTopDonorsTotal: topActions.donorsTotal
};

const connectedTop = connect(mapState, actionCreators)(Top);
export { connectedTop as Top };
