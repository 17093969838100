import { CodeObject } from './code.typings';
import { APIPagination } from './service.typings';

export enum AccountActions {
  CODES_RECEIVED_REQUEST = 'ACCOUNT_CODES_RECEIVED_REQUEST',
  CODES_RECEIVED_SUCCESS = 'ACCOUNT_CODES_RECEIVED_SUCCESS',
  CODES_RECEIVED_FAILURE = 'ACCOUNT_CODES_RECEIVED_FAILURE',

  CODES_DONATED_REQUEST = 'ACCOUNT_CODES_DONATED_REQUEST',
  CODES_DONATED_SUCCESS = 'ACCOUNT_CODES_DONATED_SUCCESS',
  CODES_DONATED_FAILURE = 'ACCOUNT_CODES_DONATED_FAILURE',

  MSG_NEW_CODE_RECEIVED = 'ACCOUNT_MSG_NEW_CODE_RECEIVED',
  MSG_NEW_CODE_DONATED = 'ACCOUNT_MSG_NEW_CODE_RECEIVED'
}

export interface AccountData {
  _id: string;
  _jti: string;
  id: string;
  name: string;
  displayName?: string;
  role: number;
  avatar: string;
  verified: boolean;
  suspended: boolean;
  online: boolean;
  codes: string[];
  provider: string;
  stats: {
    donated: number;
    received: number;
  };
  reddit?: {
    karma?: number;
    created?: string;
    url?: string;
  };
  timestamps: {
    login: Date;
    code: Date;
    update: Date;
    action: Date;
  };
}

export interface AccountReducerResponse {
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  codes: CodeObject[];
  pagination?: APIPagination;
}

export interface AccountReducerAction {
  type: AccountActions;
  payload?: CodeObject[];
  error?: Error | null;
  pagination?: APIPagination;
}

export interface AccountCodesReceivedDispatch {
  type: AccountActions;
  payload?: CodeObject[];
  pagination?: APIPagination;
  error?: string | null;
}

export interface AccountCodesDonatedDispatch {
  type: AccountActions;
  payload?: CodeObject[];
  pagination?: APIPagination;
  error?: string | null;
}
