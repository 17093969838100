import { useContext } from 'react';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';

import { history } from '../helpers';
import { AccountContext } from './AccountContext';

interface PrivateRouteProps extends RouteProps {
  returnTo?: string;
}

export function PrivateRoute({ returnTo }: PrivateRouteProps): JSX.Element {
  // Get the current users account
  const { account } = useContext(AccountContext);

  // Handle any redirections
  if (returnTo || history.location.pathname !== '/') {
    returnTo = `?returnTo=${returnTo || history.location.pathname}`;
  }

  // Not logged in
  if (account === null) {
    return <Navigate to={`/login${returnTo || ''}`} />;
  }

  // All good...
  return <Outlet />;
}
