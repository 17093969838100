import { APIResponse, CodeObject } from '../../typings';

import { ExpansionObject } from '../../typings/code.typings';
import { apiGateway } from '../../config';
import { http } from '../../helpers';

export const codeService = {
  requestCode,
  returnCode,
  latestCodes
};

function requestCode(token: string, fingerprint: string): Promise<APIResponse<CodeObject>> {
  return http.post(`${apiGateway}/code/request`, { t: token, f: fingerprint }).then(handleResponse);
}

function returnCode(id: string): Promise<APIResponse<{}>> {
  return http.post(`${apiGateway}/code/return`, { id }).then(handleResponse);
}

function latestCodes(): Promise<APIResponse<ExpansionObject[]>> {
  return http.get(`${apiGateway}/code/latest`).then(handleResponse);
}

async function handleResponse(response) {
  if (response.status !== 200) {
    const body = await response.json();
    return new Error(body.message);
  }

  return response.json();
}
