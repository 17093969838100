import { AccountData, APIResponse, CodeObject } from '../../typings';
import { http } from '../../helpers';
import { apiGateway } from '../../config';

export const accountService = {
  doLogout,
  getAccountData,
  getCodesReceived,
  getCodesDonated
};

function getAccountData(): Promise<APIResponse<AccountData>> {
  return http.get(`${apiGateway}/account/me`).then(handleResponse);
}

function doLogout(): Promise<any> {
  return http.get(`${apiGateway}/account/logout`);
}

function getCodesReceived(page: string = '1', sort: string = 'requestedAt'): Promise<APIResponse<CodeObject[]>> {
  return http.get(`${apiGateway}/account/codes/received`, { page, sort }).then(handleResponse);
}

function getCodesDonated(page: string = '1', sort: string = 'createdAt'): Promise<APIResponse<CodeObject[]>> {
  return http.get(`${apiGateway}/account/codes/donated`, { page, sort }).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then((res) => {
    if (response.status !== 200) throw new Error(res.message);
    return res;
  });
}
