import { AccountData } from './account.typings';
import { CodeType } from './code.typings';

export enum TopActions {
  DONOR_TOTAL_REQUEST = 'TOP_DONOR_TOTAL_REQUEST',
  DONOR_TOTAL_SUCCESS = 'TOP_DONOR_TOTAL_SUCCESS',
  DONOR_TOTAL_FAILURE = 'TOP_DONOR_TOTAL_FAILURE'
}

export interface TopData extends AccountData {
  count: number;
  types?: [{ type: CodeType; count: number }];
}

export interface TopReducerResponse {
  loading: boolean;
  loaded: boolean;
  error?: Error | null;
  data?: TopData[];
}

export interface TopReducerAction {
  type: TopActions;
  payload?: TopData[];
  error?: Error | null;
}

export interface TopDonorTotalDispatch {
  type: TopActions;
  payload?: TopData[];
  error?: string | null;
}
