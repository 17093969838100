import { socket } from '../../helpers';
import { StatsActions, StatsNumberMessage } from '../../typings';
import { store } from '../';
import { Dispatch } from 'react';

socket.on(StatsActions.MSG_CODES_REQUEST_TODAY, (data: number): void => {
  const { dispatch } = store as { dispatch: Dispatch<StatsNumberMessage> };
  dispatch(success(data));

  function success(data: number): StatsNumberMessage {
    return {
      type: StatsActions.MSG_CODES_REQUEST_TODAY,
      payload: data
    };
  }
});

socket.on(StatsActions.MSG_CODES_REQUEST_TOTAL, (data: number): void => {
  const { dispatch } = store as { dispatch: Dispatch<StatsNumberMessage> };
  dispatch(success(data));

  function success(data: number): StatsNumberMessage {
    return {
      type: StatsActions.MSG_CODES_REQUEST_TOTAL,
      payload: data
    };
  }
});
