import { APIResponse, TopData } from '../../typings';

import { apiGateway } from '../../config';
import { http } from '../../helpers';

export const topService = {
  donorsTotal
};

function donorsTotal(): Promise<APIResponse<TopData[]>> {
  return http.get(`${apiGateway}/top/donors/total`).then(handleResponse);
}

async function handleResponse(response) {
  if (response.status !== 200) {
    const body = await response.json();
    return new Error(body.message);
  }

  return response.json();
}
