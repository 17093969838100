import { SubmitActions, SubmitReducerAction, SubmitReducerResponse } from '../../typings';

const initialState: SubmitReducerResponse = {
  loading: false,
  loaded: false,
  error: null,
  codes: {}
};

export default function submit(
  state: SubmitReducerResponse = initialState,
  action: SubmitReducerAction
): SubmitReducerResponse {
  let code;

  if (action.payload) [code] = Object.keys(action.payload);

  switch (action.type) {
    case SubmitActions.SINGLE_FAILURE:
      return {
        loading: false,
        loaded: false,
        error: action.error || new Error('Unknwon Error'),
        codes: {
          ...state.codes,
          ...(!code || !state.codes[code] ? { [code]: action.error } : {})
        }
      };

    case SubmitActions.BULK_FAILURE:
      return {
        loading: false,
        loaded: false,
        error: action.error || new Error('Unknwon Error'),
        codes: {
          ...state.codes,
          ...(!code || !state.codes[code] ? action.payload : {})
        }
      };

    case SubmitActions.BULK_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case SubmitActions.CLEAR_CODES:
      return {
        loading: false,
        loaded: true,
        error: null,
        codes: {}
      };

    case SubmitActions.SINGLE_REQUEST:
    case SubmitActions.SINGLE_SUCCESS:
      return {
        loading: true,
        loaded: false,
        error: null,
        codes: {
          ...state.codes,
          ...(!code || !state.codes[code] ? action.payload : {})
        }
      };

    case SubmitActions.BULK_SUCCESS:
      return {
        loading: true,
        loaded: false,
        error: null,
        codes: {
          ...state.codes,
          ...action.payload
        }
      };

    default:
      return state;
  }
}
