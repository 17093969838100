import { ReactNode } from 'react';
import { Variant } from 'react-bootstrap/esm/types';

export enum NotificationActions {
  SHOW_REQUEST = 'NOTIFICATION_SHOW_REQUEST',
  HIDE_REQUEST = 'NOTIFICATION_HIDE_REQUEST',

  MSG_NEW_RECEIVED = 'NOTIFICATION_MSG_NEW_RECEIVED'
}

export interface NotificationObject {
  id: string;
  date?: Date;
  title?: string;
  description?: string | ReactNode;
  link?: string;
  variant?: Variant;
  duration?: number;
}

export interface NotificationReducerAction {
  type: NotificationActions;
  payload: NotificationObject;
}

export interface NotificationReducerResponse {
  items: NotificationObject[];
}

export interface NotificationShowDispatch {
  type: NotificationActions;
  payload: NotificationObject;
}

export interface NotificationHideDispatch {
  type: NotificationActions;
  payload: NotificationObject;
}
