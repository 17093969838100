import { Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { tokenActions } from '../../store/actions';

interface LogoutProps {
  isLoggedIn?: boolean;
  logout: (cb?: () => void) => void;
}

function Logout({ isLoggedIn, logout }: LogoutProps): JSX.Element {
  if (isLoggedIn) logout();
  return <Navigate to="/" />;
}

function mapState({ token }: rootState) {
  return {
    isLoggedIn: token.account ? true : false
  };
}

const actionCreators = {
  logout: tokenActions.logout
};

const connectedLogout = connect(mapState, actionCreators)(Logout);
export { connectedLogout as Logout };
