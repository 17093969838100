import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Navigation } from './Navigation';

interface HeaderProps {
  isHome: boolean;
}

export function Header({ isHome }: HeaderProps): JSX.Element {
  return (
    <header className={`header${isHome ? ' header-home' : ''}`}>
      <Container fluid={isHome}>
        <Row>
          <Col md={6}>
            <Link to="/" className="brand-logo">
              <img src="/assets/logo.svg" alt="tcg.codes" />
              {process.env.REACT_APP_SITE_FLAG ? (
                <span className="brand-logo-flag">{process.env.REACT_APP_SITE_FLAG}</span>
              ) : null}
            </Link>
          </Col>
          <Col md={6}>
            <Navigation isHome={isHome} />
          </Col>
        </Row>
      </Container>
    </header>
  );
}
