import { CodeType, ExpansionObject } from '../typings/code.typings';
import { randomNumber } from './randomNumber.helpers';

export function expansionImage(type: CodeType, expansion?: ExpansionObject, seed?: string) {
  if (type === CodeType.Promo || type === CodeType.Unknown) return '/assets/promo.pack.png';

  if (!expansion || expansion.packImageCount <= 0)
    return `/assets/missing.${type === CodeType.ETB ? 'etb' : 'pack'}.png`;

  return `/assets/expansions/${expansion.id}.${type === CodeType.ETB ? 'etb' : 'pack'}.${randomNumber(
    1,
    expansion.packImageCount,
    seed
  )}.png`;
}
