import { pageTitle } from '../config';

export function changePageDetails(title: string | false, className: string) {
  // Set the page title
  if (!title) {
    document.title = pageTitle.replace(' • %s', '');
  } else {
    document.title = pageTitle.replace('%s', title);
  }

  // Get the body element to work with
  const body = document.querySelector('body');
  if (!body) return;

  // Remove the existing page-* class from body
  body.className = body.className.replace(/(?:\s+)?body-[a-z-]+(?:\s+)?/i, ' ').trim();

  // Add our new class to the list
  body.classList.add(`body-${className}`);
}
