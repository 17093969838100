import { useCallback, useEffect } from 'react';
import { APIPagination, CodeObject } from '../../typings';
import { accountActions, codeActions } from '../../store/actions';
import { connect } from 'react-redux';
import { rootState } from '../../store/reducers';
import { Card, Table, Row, Col, Alert } from 'react-bootstrap';
import { CodeRow } from './components/CodeRow';
import { Paginate } from '../../components';
import { useParams, Link } from 'react-router-dom';
import { changePageDetails } from '../../helpers';
import { codeWaitTime } from '../../config';
import { Button } from 'react-bootstrap';
import moment from 'moment';

interface CodesReceivedProps {
  loading: boolean;
  loaded: boolean;
  codes: CodeObject[];
  error: Error | null;
  pagination?: APIPagination;
  getCodesReceived: (page?: string, sort?: string) => void;
  returnCode: (code: string, cb?: () => void) => void;
}

function CodesReceived({ codes, pagination, getCodesReceived, returnCode }: CodesReceivedProps): JSX.Element {
  const { page = 1 } = useParams();
  const earliestReturn = moment().subtract(...codeWaitTime);

  useEffect(() => {
    changePageDetails('My codes', 'my-codes');
  }, []);

  useEffect(() => {
    getCodesReceived(page as string);
  }, [page, getCodesReceived]);

  const returnCodeCallback = useCallback(() => {
    getCodesReceived(page as string);
  }, [page, getCodesReceived]);

  if (!codes || codes.length <= 0) {
    return (
      <Row>
        <Col md={12}>
          <Alert>
            Nothing found, <Link to="/">go claim one?</Link>
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="my-codes-container mb-4">
            <Card.Body className="p-0">
              <Table striped borderless hover responsive className="my-codes">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Expansion</th>
                    <th>Type</th>
                    <th>Received</th>
                    <th>Donor</th>
                    <th style={{ width: '50px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {codes.map((code, idx: number) => (
                    <CodeRow key={`code-row-${idx}`} code={code} num={idx}>
                      <Button
                        variant="primary"
                        className="btn-sm btn-return-code"
                        disabled={earliestReturn.isBefore(code.requestedAt)}
                        onClick={() => returnCode(code._id, returnCodeCallback)}
                      >
                        Return
                      </Button>
                    </CodeRow>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Paginate className="my-codes-pagination" active={page} url="/codes/received/" pagination={pagination} />
        </Col>
      </Row>
    </>
  );
}

function mapState({ account }: rootState) {
  return {
    loading: account.loading,
    loaded: account.loaded,
    codes: account.codes,
    pagination: account.pagination,
    error: account.error
  };
}

const actionCreators = {
  getCodesReceived: accountActions.codesReceived,
  returnCode: codeActions.returnCode
};

const connectedCodesReceived = connect(mapState, actionCreators)(CodesReceived);
export { connectedCodesReceived as CodesReceived };
