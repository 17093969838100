import { StockLevelObject } from '../../../typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Badge, Col, Row, Card } from 'react-bootstrap';
import { useCallback } from 'react';

interface StockListProps {
  available: StockLevelObject[];
}

export function StockList({ available }: StockListProps): JSX.Element {
  const formatNumber = useCallback((value: number) => {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  }, []);

  return (
    <Row className="stock-list">
      {available.map((expansion: StockLevelObject, idx: number) => (
        <Col md={6} lg={4} key={`stock-${idx}`} className="d-flex align-items-stretch">
          <Card className="p-0 stock-item w-100">
            {/*<Card.Body style={{ backgroundImage: `url("/assets/expansions/${expansion.id}.logo.png")` }}>*/}
            <Card.Body>
              <div className="stock-symbol">
                <img src={`/assets/expansions/${expansion.id}.symbol.png`} alt={`${expansion.name} symbol`} />
              </div>
              <div className="stock-expansion">
                <strong>{expansion.name}</strong>
                <ul className="stock-tags-list">
                  <li>
                    <Badge>
                      <FontAwesomeIcon icon={['fas', 'tags']} /> {expansion.series}
                    </Badge>
                  </li>
                  <li>
                    <Badge>
                      <FontAwesomeIcon icon={['fas', 'calendar']} />{' '}
                      {moment(expansion.releaseDate).format('Do MMMM YYYY')}
                    </Badge>
                  </li>
                </ul>
              </div>
              <div className="stock-total">{formatNumber(expansion.count)}</div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
