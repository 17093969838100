import { CookieContext, Layout, PrivateRoute } from './components';
import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  Available,
  TermsOfService,
  Login,
  Share,
  Home,
  Codes,
  CodesReceived,
  CodesDonated,
  NotFound,
  Logout,
  Top,
  InputQR,
  InputText,
  Stats
} from './pages';

export function App(): JSX.Element {
  const location = useLocation();
  const { consent } = useContext(CookieContext);

  useEffect(() => {
    if (consent) ReactGA.pageview(location.pathname);
  }, [consent, location]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/available" element={<Available />} />
        <Route path="/leaderboard" element={<Top />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/logout" element={<Logout />} />
        <Route element={<PrivateRoute />}>
          <Route path="/share" element={<Share />}>
            <Route index element={<InputText />} />
            <Route path="text" element={<InputText />} />
            <Route path="qr" element={<InputQR />} />
          </Route>
          <Route path="/codes" element={<Codes />}>
            <Route index element={<CodesReceived />} />
            <Route path="received/:page" element={<CodesReceived />} />
            <Route path="received" element={<CodesReceived />} />
            <Route path="donated/:page" element={<CodesDonated />} />
            <Route path="donated" element={<CodesDonated />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}
