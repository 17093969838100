import moment, { Duration } from 'moment';
import { maxCodesInPeriod } from '../../../config';

interface ActionWaitProps {
  duration: Duration | undefined;
}

export function ActionWait({ duration }: ActionWaitProps): JSX.Element {
  return (
    <button className="action-btn text-intro me-3" disabled={true}>
      <div className="button-inner">
        <span className="t">
          Next code{maxCodesInPeriod !== 1 ? 's' : ''} in{' '}
          {!duration ? '00:00:00' : moment.utc(duration.as('milliseconds')).format('HH:mm:ss')}
        </span>
      </div>
    </button>
  );
}
