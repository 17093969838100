import {
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  FacebookShareButton,
  FacebookIcon
} from 'react-share';
import { twitterAccount } from '../../../config';

export function CodeSocialShare(): JSX.Element {
  const url = process.env.REACT_APP_WEB_GATEWAY || window.location.href;
  const hashtags = ['PokemonTCG'];

  return (
    <div className="code-card-social">
      <RedditShareButton url={url} title="Free Pokémon TCG Live Codes" className="me-3">
        <RedditIcon size={32} round />
      </RedditShareButton>
      <TwitterShareButton
        url={url}
        title="Just got free Pokemon TCG Live codes from"
        hashtags={hashtags}
        via={twitterAccount}
        className="me-3"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} hashtag={`#${hashtags[0]}`} className="me-3">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
    </div>
  );
}
