import { NotificationActions, NotificationReducerAction, NotificationReducerResponse } from '../../typings';

const initialState: NotificationReducerResponse = {
  items: []
};

export default function notifcations(
  state: NotificationReducerResponse = initialState,
  action: NotificationReducerAction
): NotificationReducerResponse {
  switch (action.type) {
    case NotificationActions.SHOW_REQUEST:
      return {
        ...state,
        items: [...state.items, { ...action.payload, date: new Date() }]
      };

    case NotificationActions.HIDE_REQUEST:
      return {
        ...state,
        items: state.items.filter((i) => i.id !== action.payload.id)
      };

    default:
      return state;
  }
}
