import { useCallback } from 'react';
import { Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { pageList } from '../helpers';
import { APIPagination } from '../typings';

interface PaginateProps {
  active: number | string;
  url: string;
  limit?: number;
  pagination?: APIPagination;
  className?: string;
}

export function Paginate({ active, url, pagination, className, limit = 7 }: PaginateProps): JSX.Element {
  const navigate = useNavigate();

  const changePage = useCallback(
    (page?: number | null) => {
      if (!page) return;
      navigate(`${url}${page}`);
    },
    [navigate, url]
  );

  active = typeof active === 'string' ? Number.parseInt(active, 10) : active;

  return (
    <Pagination className={className}>
      <Pagination.Prev disabled={!pagination?.hasPrev} onClick={() => changePage(pagination?.prev)} />
      {pageList(pagination?.pages || 1, active, limit).map((page: number, idx: number) =>
        page !== 0 ? (
          <Pagination.Item
            key={`pagination-${idx}`}
            disabled={active === page}
            active={active === page}
            onClick={() => changePage(page)}
          >
            {page}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis key={`pagination-${idx}`} />
        )
      )}
      <Pagination.Next disabled={!pagination?.hasNext} onClick={() => changePage(pagination?.next)} />
    </Pagination>
  );
}
