import { useMemo, useState, useEffect } from 'react';
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, DecodeContinuouslyCallback } from '@zxing/library';
import { Form } from 'react-bootstrap';

interface QrReaderProps {
  onResult: DecodeContinuouslyCallback;
  scanDelay?: number;
}

export function QrReader({ onResult, scanDelay = 250 }: QrReaderProps) {
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoDevice, selectVideoDevice] = useState('');

  const reader = useMemo(() => {
    const hints = new Map();
    const formats = [BarcodeFormat.QR_CODE];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    const reader = new BrowserMultiFormatReader(hints, scanDelay);

    (async () => {
      const videoInputDeviceList = await reader.listVideoInputDevices();
      setVideoInputDevices(videoInputDeviceList);
      if (videoInputDeviceList.length > 0 && selectedVideoDevice == null) {
        selectVideoDevice(videoInputDeviceList[0].deviceId);
      }
    })();

    try {
      reader.decodeFromVideoDevice(selectedVideoDevice, 'videoElement', onResult);
    } catch (_e) {}

    return reader;
  }, [setVideoInputDevices, selectVideoDevice, selectedVideoDevice, onResult, scanDelay]);

  useEffect(() => {
    return () => (reader as any).stopStreams();
  }, [reader]);

  return (
    <div className="qr-reader">
      <div className="qr-reader-container">
        {videoInputDevices.length <= 1 ? (
          <></>
        ) : (
          <Form.Select
            className="qr-reader-inputDevice"
            onChange={(event) => {
              const deviceId = event.target.value;
              selectVideoDevice(deviceId);
            }}
          >
            {videoInputDevices.map((inputDevice, index) => {
              return (
                <option value={inputDevice.deviceId} key={index}>
                  {inputDevice.label || inputDevice.deviceId}
                </option>
              );
            })}
          </Form.Select>
        )}
        <video id="videoElement" width="1920" height="1080" />
      </div>
    </div>
  );
}
