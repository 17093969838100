import { JWTPayload, TokenReducerResponse } from '../typings';
import { getToken } from './getToken.helpers';
import jwtDecode from 'jwt-decode';

export function reloadToken(state: TokenReducerResponse): TokenReducerResponse {
  state.accessToken = getToken();

  if (!state.accessToken) return state;

  const decoded = jwtDecode(state.accessToken) as JWTPayload;

  return Object.assign(state, {
    account: decoded.sub || null
  });
}
