import { APIResponse, SubmissionObject } from '../../typings';

import { apiGateway } from '../../config';
import { http } from '../../helpers';

export const submitService = {
  submitCodeSingle,
  submitCodeBulk
};

function submitCodeSingle(code: string, token: string): Promise<APIResponse<SubmissionObject>> {
  return http.post(`${apiGateway}/code/submit/${code}`, { token }).then(handleResponse);
}

function submitCodeBulk(codes: string, token: string): Promise<APIResponse<SubmissionObject>> {
  return http.post(`${apiGateway}/code/submit`, { codes, token }).then(handleResponse);
}

async function handleResponse(response) {
  return response.json();
}
