import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountData } from '../typings';

interface UserProfileProps {
  user: AccountData;
  size?: 'md' | 'lg';
  position?: 'left' | 'right';
  showAvatar?: boolean;
  showUrl?: boolean;
}

export function UserProfile({
  user,
  position = 'left',
  size = 'md',
  showUrl = false,
  showAvatar = true
}: UserProfileProps): JSX.Element {
  const icons = { reddit: ['fab', 'reddit-alien'], twitter: ['fab', 'twitter'], unknown: ['fas', 'question'] };

  if (!user) return <></>;

  let profileUrl = '#';
  let displayName = user?.name || 'Unknown';
  let userName = user?.name || 'Unknown';

  if (user.provider === 'reddit') {
    displayName = user?.displayName || user.name;
    userName = user?.reddit?.url?.replace(/^\/|\/$/g, '') || `u/${user.name}`;
    profileUrl = `https://reddit.com/${userName}`;
  } else if (user.provider === 'twitter') {
    profileUrl = `https://twitter.com/${user.name}`;
    displayName = user?.displayName || `@${user.name}`;
  }

  return (
    <div className={`user-profile user-profile-${position} user-profile-${size}`}>
      {!showAvatar ? (
        <></>
      ) : (
        <span className={`user-profile-icon user-profile-icon-${user?.provider || 'reddit'}`}>
          {user.avatar ? (
            <img src={user.avatar} alt={`${user?.name || 'Unknown'}'s avatar`} />
          ) : (
            <FontAwesomeIcon icon={icons[user?.provider || 'unknown']} size="2x" />
          )}
        </span>
      )}
      <div className="user-profile-details">
        <a
          href={profileUrl}
          target="_blank"
          rel="noreferrer"
          className={`user-profile-name user-profile-${user?.provider || 'reddit'}`}
        >
          {displayName}
        </a>
        {showUrl ? <span className="user-profile-url">{userName}</span> : null}
      </div>
    </div>
  );
}
