import { AccountActions, AccountReducerResponse, AccountReducerAction } from '../../typings';

const initialState: AccountReducerResponse = {
  loading: false,
  loaded: false,
  error: null,
  codes: [],
  pagination: undefined
};

export default function account(
  state: AccountReducerResponse = initialState,
  action: AccountReducerAction
): AccountReducerResponse {
  switch (action.type) {
    case AccountActions.CODES_RECEIVED_FAILURE:
    case AccountActions.CODES_DONATED_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error || new Error('Unknwon Error')
      };

    case AccountActions.CODES_RECEIVED_REQUEST:
    case AccountActions.CODES_DONATED_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case AccountActions.CODES_RECEIVED_SUCCESS:
    case AccountActions.CODES_DONATED_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        codes: action.payload || [],
        pagination: action.pagination
      };

    default:
      return state;
  }
}
