import { StatsActions, StatsReducerResponse, StatsReducerAction } from '../../typings';

const initialState: StatsReducerResponse = {
  loaded: {},
  error: null,
  loading: {},
  stats: {}
};

const statsKeys = {
  [StatsActions.CODES_REQUEST_TOTAL_SUCCESS]: 'codesRequestTotal',
  [StatsActions.MSG_CODES_REQUEST_TOTAL]: 'codesRequestTotal',
  [StatsActions.CODES_REQUEST_TODAY_SUCCESS]: 'codesRequestToday',
  [StatsActions.MSG_CODES_REQUEST_TODAY]: 'codesRequestToday',
  [StatsActions.CODES_AVAILABLE_SUCCESS]: 'codesAvailable',
  [StatsActions.MSG_CODES_AVAILABLE]: 'codesAvailable',
  [StatsActions.CODES_DONATE_TOTAL_SUCCESS]: 'codesDonateTotal',
  [StatsActions.CODES_DONATE_TODAY_SUCCESS]: 'codesDonateToday',
  [StatsActions.CODES_DONATE_BY_DAY_SUCCESS]: 'codesDonateByDay',
  [StatsActions.CODES_REQUEST_BY_DAY_SUCCESS]: 'codesRequestByDay'
};

export default function code(
  state: StatsReducerResponse = initialState,
  action: StatsReducerAction
): StatsReducerResponse {
  switch (action.type) {
    case StatsActions.CODES_REQUEST_TOTAL_FAILURE:
    case StatsActions.CODES_REQUEST_TODAY_FAILURE:
    case StatsActions.CODES_DONATE_TOTAL_FAILURE:
    case StatsActions.CODES_DONATE_TODAY_FAILURE:
    case StatsActions.CODES_AVAILABLE_FAILURE:
    case StatsActions.CODES_DONATE_BY_DAY_FAILURE:
    case StatsActions.CODES_REQUEST_BY_DAY_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [statsKeys[action.type]]: false
        },
        loaded: {
          ...state.loaded,
          [statsKeys[action.type]]: true
        },
        error: action.error || new Error('Unknwon Error')
      };

    case StatsActions.CODES_REQUEST_TOTAL_REQUEST:
    case StatsActions.CODES_REQUEST_TODAY_REQUEST:
    case StatsActions.CODES_DONATE_TOTAL_REQUEST:
    case StatsActions.CODES_DONATE_TODAY_REQUEST:
    case StatsActions.CODES_AVAILABLE_REQUEST:
    case StatsActions.CODES_DONATE_BY_DAY_REQUEST:
    case StatsActions.CODES_REQUEST_BY_DAY_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [statsKeys[action.type]]: true
        },
        loaded: {
          ...state.loaded,
          [statsKeys[action.type]]: false
        }
      };

    case StatsActions.CODES_REQUEST_TOTAL_SUCCESS:
    case StatsActions.MSG_CODES_REQUEST_TOTAL:
    case StatsActions.CODES_REQUEST_TODAY_SUCCESS:
    case StatsActions.MSG_CODES_REQUEST_TODAY:
    case StatsActions.CODES_AVAILABLE_SUCCESS:
    case StatsActions.MSG_CODES_AVAILABLE:
    case StatsActions.CODES_DONATE_TOTAL_SUCCESS:
    case StatsActions.CODES_DONATE_TODAY_SUCCESS:
    case StatsActions.CODES_DONATE_BY_DAY_SUCCESS:
    case StatsActions.CODES_REQUEST_BY_DAY_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [statsKeys[action.type]]: false
        },
        loaded: {
          ...state.loaded,
          [statsKeys[action.type]]: true
        },
        stats: {
          ...state.stats,
          [statsKeys[action.type]]: action.payload
        }
      };

    default:
      return state;
  }
}
