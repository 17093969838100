import {
  APIResponse,
  AccountActions,
  AccountCodesReceivedDispatch,
  AccountCodesDonatedDispatch,
  CodeObject
} from '../../typings';

import { Dispatch } from 'redux';
import { accountService } from '../services/account.services';

export const accountActions = {
  codesReceived,
  codesDonated
};

function codesReceived(
  page: string = '1',
  sort: string = 'requestedAt'
): (dispatch: Dispatch<AccountCodesReceivedDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    accountService.getCodesReceived(page, sort).then(
      (res: APIResponse<CodeObject[]>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): AccountCodesReceivedDispatch {
    return {
      type: AccountActions.CODES_RECEIVED_REQUEST
    };
  }

  function success({ data, pagination }: APIResponse<CodeObject[]>): AccountCodesReceivedDispatch {
    return {
      type: AccountActions.CODES_RECEIVED_SUCCESS,
      payload: data,
      pagination
    };
  }

  function failure(error: Error | string | null): AccountCodesReceivedDispatch {
    return { type: AccountActions.CODES_RECEIVED_FAILURE, error: (error as any)?.message || error };
  }
}

function codesDonated(
  page: string = '1',
  sort: string = 'createdAt'
): (dispatch: Dispatch<AccountCodesDonatedDispatch>) => void {
  return (dispatch) => {
    dispatch(request());

    accountService.getCodesDonated(page, sort).then(
      (res: APIResponse<CodeObject[]>) => dispatch(res.status ? success(res) : failure(res.message)),
      (error: Error) => dispatch(failure(error))
    );
  };

  function request(): AccountCodesDonatedDispatch {
    return {
      type: AccountActions.CODES_DONATED_REQUEST
    };
  }

  function success({ data, pagination }: APIResponse<CodeObject[]>): AccountCodesDonatedDispatch {
    return {
      type: AccountActions.CODES_DONATED_SUCCESS,
      payload: data,
      pagination
    };
  }

  function failure(error: Error | string | null): AccountCodesDonatedDispatch {
    return { type: AccountActions.CODES_DONATED_FAILURE, error: (error as any)?.message || error };
  }
}
