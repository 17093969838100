import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { accountService } from '../store/services/account.services';
import { AccountData } from '../typings';
import ReactGA from 'react-ga';
import { CookieContext } from './CookieContext';
import { getToken } from '../helpers';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

export const AccountContext = createContext<{
  account?: AccountData | null;
  fingerprint?: GetResult | null;
  error?: string;
  updateAccount: () => void;
}>({ account: undefined, fingerprint: undefined, updateAccount: () => {} });

export function AccountProvider({ children }: { children: React.ReactElement }): JSX.Element {
  const { consent } = useContext(CookieContext);
  const [error, setError] = useState<string | undefined>(undefined);
  const [account, setAccount] = useState<AccountData | undefined | null>(undefined);
  const [fingerprint, setFingerprint] = useState<GetResult | undefined | null>(undefined);

  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((res) => setFingerprint(res));
  }, [setFingerprint]);

  const updateAccount = useCallback(() => {
    if (!getToken()) return setAccount(null);
    accountService
      .getAccountData()
      .then(({ data }) => {
        if (!data) return setAccount(null);
        if (consent) ReactGA.set({ userId: data._id });
        setAccount(data);
      })
      .catch((err) => setError(err.message));
  }, [consent, setAccount]);

  useEffect(() => {
    if (!account) updateAccount();
  }, [account, updateAccount]);

  return (
    <AccountContext.Provider value={{ error, account, fingerprint, updateAccount }}>{children}</AccountContext.Provider>
  );
}
