import { useContext, useEffect, useState } from 'react';

import { AccountContext } from '../../AccountContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

interface NavigationProps {
  isHome: boolean;
}

export function Navigation({ isHome }: NavigationProps) {
  const { account } = useContext(AccountContext);
  const [show, setShow] = useState<boolean>(false);

  const close = () => setShow(false);

  useEffect(() => {
    if (show) document?.querySelector('#layout')?.scrollTo(0, 0);
  }, [show]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (!show) body?.classList.remove('primary-nav-isOpen');
    else body?.classList.add('primary-nav-isOpen');
  }, [show]);

  return (
    <div className={`primary-nav${show ? ' primary-nav-show' : ''}${isHome ? ' shadow' : ''}`}>
      <div onClick={() => setShow(!show)} className="primary-nav-toggle">
        <FontAwesomeIcon icon={['fas', 'bars']} size="3x" />
        <FontAwesomeIcon icon={['fas', 'times']} size="3x" />
      </div>
      <nav className="primary-nav-container">
        <div className="primary-nav-overlay"></div>

        <ul>
          <li>
            <NavLink to="/" onClick={close}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/share" onClick={close}>
              Share
            </NavLink>
          </li>
          <li>
            <NavLink to="/available" onClick={close}>
              Available
            </NavLink>
          </li>
          <li>
            <NavLink to="/leaderboard" onClick={close}>
              Leaderboard
            </NavLink>
          </li>
          {!account ? (
            <li>
              <NavLink to="/login" onClick={close}>
                Login
              </NavLink>
            </li>
          ) : (
            <>
              <li>
                <NavLink to="/codes/received" onClick={close}>
                  My Codes
                </NavLink>
              </li>
              <li>
                <NavLink to="/logout" onClick={close}>
                  Logout
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}
