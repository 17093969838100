export function roundToPrecision(value: number): number {
  let precision = 5;

  switch (value.toString().length) {
    case 2:
      precision = 10;
      break;
    case 3:
      precision = 50;
      break;
    case 4:
      precision = 100;
      break;
    case 5:
      precision = 500;
      break;
    case 6:
      precision = 1000;
      break;
  }

  return Math.floor((value + 1) / precision) * precision;
}
