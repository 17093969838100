import { CodeType, ExpansionObject } from '../../../typings/code.typings';
import { codeActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { rootState } from '../../../store/reducers';
import { useEffect } from 'react';
import { expansionImage, missingImage } from '../../../helpers';
import { codeLatestCount } from '../../../config';

interface PacksProps {
  loading: boolean;
  loaded: boolean;
  latest: ExpansionObject[];
  latestCodes: () => void;
}

function Packs({ latest, loading, loaded, latestCodes }: PacksProps): JSX.Element {
  useEffect(() => {
    if (!latest || !loaded) latestCodes();
  }, [latest, loaded, latestCodes]);

  if (latest.length <= 0 && (loading || !loaded)) return <></>;

  return (
    <div className="packs">
      {[...Array(codeLatestCount)].map((_v, idx: number) => (
        <div
          key={`pack-${idx}`}
          className="item"
          style={{
            backgroundImage: `url('${
              latest[idx] ? expansionImage(CodeType.Booster, latest[idx], latest[idx]?.seed) : missingImage(idx)
            }')`
          }}
        ></div>
      ))}
    </div>
  );
}

function mapState({ code }: rootState) {
  return {
    loading: code.loading,
    loaded: code.loaded,
    latest: code.latest
  };
}

const actionCreators = {
  latestCodes: codeActions.latestCodes
};

const connectedPacks = connect(mapState, actionCreators)(Packs);
export { connectedPacks as Packs };
