import { socket } from '../../helpers';
import { CodeActions, ExpansionObject, CodeNewLatestMessage } from '../../typings';
import { store } from '../';
import { Dispatch } from 'react';

socket.on(CodeActions.MSG_NEW_LATEST, (data: ExpansionObject[]): void => {
  const { dispatch } = store as { dispatch: Dispatch<CodeNewLatestMessage> };
  dispatch(success(data));

  function success(data: ExpansionObject[]): CodeNewLatestMessage {
    return {
      type: CodeActions.MSG_NEW_LATEST,
      payload: data
    };
  }
});
