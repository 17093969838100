import { Link } from 'react-router-dom';

export function NotFound(): JSX.Element {
  return (
    <div className="page-not-found">
      <div className="text-center">
        <h1 className="display-1 fw-bold mb-5">404</h1>
        <p className="fs-3 mb-3">
          {' '}
          <span className="text-danger">Opps!</span> Page not found.
        </p>
        <p className="lead mb-5">The page you're looking for doesn't exist.</p>
        <Link to="/" className="light-btn text-intro">
          Go Home
        </Link>
      </div>
    </div>
  );
}
