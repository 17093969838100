import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { formatCode } from '../../../../helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type ResultBlockProps = { code: string; result: true | false | string | null } & { error?: Error | null };

export function ResultBlock({ code, result }: ResultBlockProps): JSX.Element {
  const [color, icon] =
    result === true ? ['success', 'check'] : result === null ? ['warning', 'question'] : ['danger', 'x'];

  return (
    <>
      <FontAwesomeIcon icon={['fas', icon as IconName]} className={`text-${color} me-2`} />
      <strong>{formatCode(code)}</strong>
      {result === true || result === false || result === null || result === '' ? (
        <></>
      ) : (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>{result.replace(/\(.+?\)\s{1,}/gi, '')}</Tooltip>}
        >
          <FontAwesomeIcon icon={['fas', 'circle-info']} className={`text-danger ms-2`} />
        </OverlayTrigger>
      )}
    </>
  );
}
